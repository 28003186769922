import React, { useState } from "react";
import ReactDropzone from "react-dropzone";
import axios from "axios";
import { noop } from "lodash";

import HelpDialog from "components/HelpDialog";
import { API_URL } from "config";

const Dropzone = ({
  path,
  onChange = noop,
  children,
  multiple = false,
  maxSize,
  sizeHelper,
  setuploadPercentage = null,
  ...props
}) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState("");
  const [sizeHelperOpen, setSizeHelperOpen] = useState(false);
  const multiplefiles = [];
  const onDrop = (files) => {
    if (files?.length >= 2) {
      files.forEach((Currentfile, index) => {
        const contentType = Currentfile.type; // eg. image/jpeg or image/svg+xml
        if (maxSize && maxSize < Currentfile.size) {
          setSizeHelperOpen(true);
          return;
        }

        const generatePutUrl = `${API_URL}/generate-put-url`;
        const generateGetUrl = `${API_URL}/generate-get-url`;
        const options = {
          onUploadProgress: (progressEvent) => {
            let percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            if (setuploadPercentage) {
              setuploadPercentage({
                percent
              })
            }
          },
          params: {
            Key: path.substring(0, path.lastIndexOf("/") + 1) + Currentfile.name.replace(/[^a-zA-Z0-9_]/g, "").replaceAll(".", "_").replaceAll(" ", "_") + "_" +
              path.substring(path.lastIndexOf("/") + 1, path.length) + index,
            ContentType: contentType
          },
          headers: {
            "Content-Type": contentType
          }
        };

        setUrl(URL.createObjectURL(Currentfile));
        setLoading(true);

        axios.get(generatePutUrl, options).then((res) => {
          const {
            data: { putURL }
          } = res;
          axios
            .put(putURL, Currentfile, options)
            .then(() => {
              axios.get(generateGetUrl, options).then((res) => {
                const { data: getURL } = res;
                multiplefiles.push(getURL)
                setLoading(false);
              });
            })
            .catch(() => {
              setLoading(false);
            });
        });
      });
      onChange(multiplefiles, "photo_gallery_url");
    } else {
      let file = files[0];
      if (file) {
        const contentType = file?.type; // eg. image/jpeg or image/svg+xml
        if (maxSize && maxSize < file.size) {
          setSizeHelperOpen(true);
          return;
        }

        const generatePutUrl = `${API_URL}/generate-put-url`;
        const generateGetUrl = `${API_URL}/generate-get-url`;
        const options = {
          onUploadProgress: (progressEvent) => {
            let percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            if (setuploadPercentage) {
              setuploadPercentage({
                percent
              })
            }
          },
          params: {
            Key: path.substring(0, path.lastIndexOf("/") + 1) + file.name.replace(/[^a-zA-Z0-9_]/g, "").replaceAll(".", "_").replaceAll(" ", "_") + "_" +
              path.substring(path.lastIndexOf("/") + 1, path.length) + file.name.replace(/[^a-zA-Z0-9_]/g, "").substring(file.name.indexOf(".")),
            ContentType: contentType
          },
          headers: {
            "Content-Type": contentType
          }
        };

        console.log(path)
        console.log(options)

        setUrl(URL.createObjectURL(file));
        setLoading(true);

        axios.get(generatePutUrl, options).then(res => {
          const {
            data: { putURL }
          } = res;
          axios
            .put(putURL, file, options)
            .then(() => {
              axios.get(generateGetUrl, options).then(res => {
                const { data: getURL } = res;
                onChange(getURL);
                setLoading(false);
              });
            })
            .catch(() => {
              setLoading(false);
            });
        });
      }
    }
  };
  return (
    <ReactDropzone onDrop={onDrop} multiple={multiple} {...props}>
      {({ getRootProps, getInputProps }) => (
        <>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children({ url, loading })}
          </div>
          <HelpDialog open={sizeHelperOpen} onClose={() => setSizeHelperOpen(false)}>
            {sizeHelper}
          </HelpDialog>
        </>
      )}
    </ReactDropzone>
  );
};

export default Dropzone;
