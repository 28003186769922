import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
const moment = require("moment");

const JobList = (row, index, CRMJob, object_key, CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id) => {
  return CRMJob?.filter((CurrentJob) => CurrentJob[object_key] === row?.id).map(CurrentJob => {
    return (
      <>
        {isJobOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isJobOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><a
                    href={"/admin/crmjobdetail?customer=" + customer_id + "&id=" + CurrentJob?.crm_customers_id + "&peopleid=" + CurrentJob?.people_id + "&jobid=" + CurrentJob?.id}
                  >
                    {CurrentJob?.job_name}
                  </a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Service</b><br /> {CurrentJob?.services?.split(",")?.map((CurrentService) => { return CRMServices?.filter((CurrentSavedService) => CurrentSavedService?.id === parseInt(CurrentService)).map(CurrentSavedService => { return <div>{CurrentSavedService?.service_name}<br /></div> }) })}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Scheduled Date</b><br /> {CurrentJob?.job_scheduled_date !== null ? moment(new Date(parseInt(CurrentJob?.job_scheduled_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : ""}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Status</b><br /> {CurrentJob?.job_status}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === CurrentJob?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
                </div>
              </Collapse>
            </TableCell >
          </TableRow >
        )}
      </>
    )
  })
};

export default JobList;
