import React, { useContext, useState, ScrollView } from "react";
import PropTypes from 'prop-types';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomInput from "components/CustomInput/CustomInput";
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from "components/CustomButtons/Button.js";

import CreateTicket from "views/Dashboard/CreateTicket";

import {
  LIST_CREATIVE_FORM,
  COMPLETE_CREATIVE_FORM,
  COMPLETE_CREATIVE_TASK,
  UPDATE_TICKET_STATUS,
  GET_TICKET_COUNT
} from "queries/formSubmission";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const columns = [
  {
    Header: "Customer",
    accessor: "customer_name"
  },
  {
    Header: "Description",
    accessor: "issue_description"
  },
  {
    Header: "Priority Level",
    accessor: "priority_level"
  },
  {
    Header: "Requested Completion",
    accessor: "requested_completion_date"
  },
  {
    Header: "Submitted By",
    accessor: "submitted_by"
  },
  {
    Header: "Assigned to",
    accessor: "campaign_review_assignment"
  },
  {
    Header: "Ready for Review"
  },
  {
    Header: "Completed",
    accessor: "completed"
  },
  {
    Header: "Completed By",
    accessor: "completed_by",
  },
  {
    Header: "",
    accessor: "update"
  }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.length}
            sortDirection={orderBy === column.accessor ? order : false}
            align={'right'}
          >
            <TableSortLabel
              active={orderBy === column.accessor}
              direction={orderBy === column.accessor ? order : 'asc'}
              onClick={createSortHandler(column.accessor)}
            >
              {column.Header}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Tickets(props) {
  const [state, dispatch] = useContext(Context);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [seeTickets, setseeTickets] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('asc');
  const [seetickets, setSeetickets] = React.useState(0);
  const [customer_search, setCustomerSearch] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('id');
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  let ismaster = 0;
  let ticketid = "0";

  const siteUrl = window.location.search;
  const urlParams = new URLSearchParams(siteUrl);
  
  if (urlParams.get("ticket_id")) {
    ticketid = urlParams.get("ticket_id");
  }

  let { data: all_data } = useQuery(GET_TICKET_COUNT, {
    variables: {
      customerId: 0,
      completed: seetickets,
      customer_search: customer_search
    }
  });

  all_data = all_data?.getTicketCount || [];

  let { loading, data, refetch } = useQuery(LIST_CREATIVE_FORM, {
    skip: !customer_id,
    variables: {
      customerId: -1,
      ticketid: ticketid,
      completed: seetickets,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      customer_search: customer_search
    }
  });

  data = data?.listCreativeForm || [];

  //  check if ticket is ready for review

  data = data.map((row) => {
    let TicketReadyForReview = true
    if (row.paidsearch_text_ads && row.paidsearch_text_ads_completed_status !== 4 && row.paidsearch_text_ads_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.paidsearch_ad_extensions && row.paidsearch_ad_extensions_completed_status !== 4 && row.paidsearch_ad_extensions_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.website_coupons && row.website_coupons_completed_status !== 4 && row.website_coupons_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.promotion_bannerads && row.promotion_bannerads_completed_status !== 4 && row.promotion_bannerads_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.promotion_onlinevideo && row.promotion_onlinevideo_completed_status !== 4 && row.promotion_onlinevideo_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.promotion_hydrate && row.promotion_hydrate_completed_status !== 4 && row.promotion_hydrate_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.promotion_out_of_home && row.promotion_out_of_home_completed_status !== 4 && row.promotion_out_of_home_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.campaign_review_assignment && row.campaign_review_assignment_completed_status !== 4 && row.campaign_review_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.reporting_request_assignment && row.reporting_request_assignment_completed_status !== 4 && row.reporting_request_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.call_tracking_issue_assignment && row.call_tracking_issue_assignment_completed_status !== 4 && row.call_tracking_issue_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.campaign_performance_issue_assignment && row.campaign_performance_issue_assignment_completed_status !== 4 && row.campaign_performance_issue_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.update_creative_assignment && row.update_creative_assignment_completed_status !== 4 && row.update_creative_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.budget_review_assignment && row.budget_review_assignment_completed_status !== 4 && row.budget_review_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.website_edit_assignment && row.website_edit_assignment_completed_status !== 4 && row.website_edit_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.other_assignment && row.other_assignment_completed_status !== 4 && row.other_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.social_media_post && row.social_media_post_assignment_completed_status !== 4 && row.social_media_post_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.new_website_content_pages && row.new_website_content_pages_assignment_completed_status !== 4 && row.new_website_content_pages_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.blog_content && row.blog_content_assignment_completed_status !== 4 && row.blog_content_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;
    if (row.cancellation_assignment && row.cancellation_assignment_completed_status !== 4 && row.cancellation_assignment_completed_status !== 5 && row.completed === 0)
      TicketReadyForReview = false;

    if (TicketReadyForReview)
      row = Object.assign({}, row, { ready_for_review: 1 })
    else
      row = Object.assign({}, row, { ready_for_review: 0 })

    return row;
  })

  const [completeCreativeForm, { loading: completing }] = useMutation(COMPLETE_CREATIVE_FORM, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
    }
  });

  const [completeCreativeTask, { loading: completing_task }] = useMutation(COMPLETE_CREATIVE_TASK, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
    }
  });

  const [submitTicketReview, { loading: updateing_ticket_status }] = useMutation(UPDATE_TICKET_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
    }
  });

  const handleSeeTickets = (event) => {
    let new_setting = event === "opened" ? 0 : event === "closed" ? 1 : -1;
    setSeetickets(new_setting);
  };

  const handleCustomerSearch = (event) => {
    setCustomerSearch(event);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const editable = me?.readMe?.user_level === "master" ||
    me?.readMe?.user_level === "content_manager" ||
    me?.readMe?.user_level === "paid_ad_manager" ||
    me?.readMe?.user_level === "ad_consultant" ||
    me?.readMe?.user_level === "campaign_consultant";

  Tickets.propTypes = {
    offline: PropTypes.bool
  };

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props, handleChange) {
    const { row } = props;
    const [open, setOpen, setValue] = React.useState(false);
    const classes = useRowStyles();
    const [submissionData, setSubmissionData] = useState(null);

    const updateFieldHandler = (key, convert) => e => {
      setSubmissionData({
        ...submissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    };

    const updateStatus = (key, data, convert) => e => {
      submitTicketReview({
        variables: {
          id: row?.id,
          ticket_status: e.target.value,
          data: data
        }
      });
    };

    return (
      <React.Fragment>
        <TableRow key={row?.length}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="right" maxWidth="80px"><div>{row?.customer_name}</div>
          </TableCell>
          <TableCell align="right"><div key={row?.id}>{row?.issue_description?.split(",").map((description) => { return <div>{description}</div> })}
          </div>
          </TableCell>
          <TableCell align="right">{row.priority_level === 1 ? <div style={{ color: "blue" }}>Low</div> : "" ||
            row.priority_level === 2 ? <div style={{ color: "purple" }}>Medium</div> : "" ||
              row.priority_level === 3 ? <div style={{ color: "orange" }}>High</div> : "" ||
                row.priority_level === 4 ? <div style={{ color: "red" }}>Emergency</div> : ""}
          </TableCell>
          <TableCell align="right">{moment(new Date(row.requested_completion_date)).format("MM-DD-YYYY")}</TableCell>
          <TableCell align="right">{<div style={{ "whiteSpace": "nowrap" }}>{row?.user_submitted ? `${row.user_submitted?.first_name} ${row.user_submitted?.last_name}` : ""}</div>}</TableCell>
          <TableCell align="right" style={{ "whiteSpace": "nowrap" }}>
            <div>{row?.campaign_review_assigned_user ? `${row.campaign_review_assigned_user?.first_name} ${row.campaign_review_assigned_user?.last_name}` : ""}</div>
            <div>{row?.reporting_request_assigned_user ? `${row.reporting_request_assigned_user?.first_name} ${row.reporting_request_assigned_user?.last_name}` : ""}</div>
            <div>{row?.call_tracking_issue_assigned_user ? `${row.call_tracking_issue_assigned_user?.first_name} ${row.call_tracking_issue_assigned_user?.last_name}` : ""}</div>
            <div>{row?.campaign_performance_issue_assigned_user ? `${row.campaign_performance_issue_assigned_user?.first_name} ${row.campaign_performance_issue_assigned_user?.last_name}` : ""}</div>
            <div>{row?.update_creative_assigned_user ? `${row.update_creative_assigned_user?.first_name} ${row.update_creative_assigned_user?.last_name}` : ""}</div>
            <div>{row?.budget_review_assigned_user ? `${row.budget_review_assigned_user?.first_name} ${row.budget_review_assigned_user?.last_name}` : ""}</div>
            <div>{row?.website_edit_assigned_user ? `${row.website_edit_assigned_user?.first_name} ${row.website_edit_assigned_user?.last_name}` : ""}</div>
            <div>{row?.other_assigned_user ? `${row.other_assigned_user?.first_name} ${row.other_assigned_user?.last_name}` : ""}</div>
            <div>{row?.paidsearch_text_ads_assigned_user?.first_name} {row?.paidsearch_text_ads_assigned_user?.last_name}</div>
            <div>{row?.paidsearch_ad_extensions_assigned_user?.first_name} {row?.paidsearch_ad_extensions_assigned_user?.last_name}</div>
            <div>{row?.promotion_bannerads_assigned_user?.first_name} {row?.promotion_bannerads_assigned_user?.last_name}</div>
            <div>{row?.promotion_onlinevideo_assigned_user?.first_name} {row?.promotion_onlinevideo_assigned_user?.last_name}</div>
            <div>{row?.social_media_post_assigned_user?.first_name} {row?.social_media_post_assigned_user?.last_name}</div>
            <div>{row?.new_website_content_pages_assigned_user?.first_name} {row?.new_website_content_pages_assigned_user?.last_name}</div>
            <div>{row?.blog_content_assigned_user?.first_name} {row?.blog_content_assigned_user?.last_name}</div>
            <div>{row?.cancellation_assigned_user?.first_name} {row?.cancellation_assigned_user?.last_name}</div>
            <div>{row?.promotion_hydrate_assigned_user?.first_name} {row?.promotion_hydrate_assigned_user?.last_name}</div>
            <div>{row?.promotion_out_of_home_assigned_user?.first_name} {row?.promotion_out_of_home_assigned_user?.last_name}</div>
            <div>{row?.website_coupons_assigned_user?.first_name} {row?.website_coupons_assigned_user?.last_name}</div>
          </TableCell>
          <TableCell align="right">{row.ready_for_review
            ? <div style={{ color: "green" }}>Yes</div>
            : <div style={{ color: "red" }}>No</div>}</TableCell>
          <TableCell align="right">{row.completed
            ? <div style={{ color: "green" }}>Yes</div>
            : <div style={{ color: "red" }}>No</div>}</TableCell>
          <TableCell align="right">{
            <>
              <div>{row?.user_completed ? `${row.user_completed?.first_name} ${row.user_completed?.last_name}` : ""}</div>
              {((editable && !row?.completed && me?.readMe?.id === row?.submitted_by) || (!row?.completed && me?.readMe?.user_level === "master")) && (
                <div>
                  <Button
                    color="warning"
                    style={{ width: "100px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      completeCreativeForm({
                        variables: {
                          id: row?.id,
                          data: omit(submissionData, ["completed", "__typename", "user_submitted", "submitted_by",
                            "completed_by", "user_completed", "completed_time", "assigned_to_time",
                            "closed_by_time", "campaign_review_assigned_user", "reporting_request_assigned_user",
                            "call_tracking_issue_assigned_user", "campaign_performance_issue_assigned_user",
                            "update_creative_assigned_user", "budget_review_assigned_user",
                            "website_edit_assigned_user", "other_assigned_user",
                            "promotion_out_of_home_assigned_user", "promotion_hydrate_assigned_user",
                            "social_media_post_assigned_user", "promotion_onlinevideo_assigned_user",
                            "new_website_content_pages_assigned_user", "blog_content_assigned_user",
                            "cancellation_assigned_user",
                            "promotion_bannerads_assigned_user", "paidsearch_ad_extensions_assigned_user",
                            "paidsearch_text_ads_assigned_user", "website_coupons_assigned_user",
                            "campaign_review_assignment_completed_time", "reporting_request_assignment_completed_time",
                            "call_tracking_issue_assignment_completed_time", "campaign_performance_issue_assignment_completed_time",
                            "update_creative_assignment_completed_time", "budget_review_assignment_completed_time",
                            "website_edit_assignment_completed_time", "other_assignment_completed_time",
                            "promotion_bannerads_completed_time", "paidsearch_text_ads_completed_time",
                            "paidsearch_ad_extensions_completed_time", "promotion_onlinevideo_completed_time",
                            "promotion_hydrate_completed_time", "promotion_out_of_home_completed_time",
                            "website_coupons_completed_time",
                            "social_media_post_completed_time", "new_website_content_pages_completed_time",
                            "blog_content_completed_time", "cancellation_completed_time"])
                        }
                      });
                    }}
                  >
                    {"Close Ticket"}
                  </Button>
                </div>
              )}
            </>}
          </TableCell>
          <TableCell align="left">{editable && !row.completed && (me?.readMe?.id === row?.submitted_by || me?.readMe?.user_level === "master") && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedCreative(row);
              }}
            >
              Update
            </a>
          )}
          </TableCell>
        </TableRow>
        <TableRow key={row?.length}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={10}>
                <Table size="medium" aria-label="purchases">
                  <TableCell style={{ width: "300px" }}>Ticket ID:</TableCell>
                  <TableCell align="left"><div>{row?.id}</div>
                  </TableCell>
                  {row?.completed_time !== null && row?.completed_time !== 'undefined' && row?.completed_time !== '' ? <TableRow key={row?.length}>
                    <TableCell>Completed Time:</TableCell>
                    <TableCell align="left">{moment(new Date(parseInt(row.completed_time))).format("MM-DD-YYYY, h:mm:ss")}</TableCell>
                  </TableRow> : ""}
                  {row?.notes !== null && row?.notes !== 'undefined' && row?.notes !== '' ? <TableRow key={row?.length}>
                    <TableCell>Notes:</TableCell>
                    <TableCell align="left"><textarea readOnly="true" type="text" value={row.notes} multiline rows="5" /></TableCell>
                  </TableRow> : ""}
                  {row?.link_to_assets !== null && row?.link_to_assets !== 'undefined' && row?.link_to_assets !== '' ? <TableRow key={row?.length}>
                    <TableCell>Assets Uploaded:</TableCell>
                    <TableCell align="left"><a href={row?.link_to_assets} target="_blank">{row?.link_to_assets ? "Download" : ""}</a></TableCell>
                  </TableRow> : ""}
                  {row?.created_time !== null && row?.created_time !== 'undefined' && row?.created_time !== '' ? <TableRow key={row?.length}>
                    <TableCell>Created Time:</TableCell>
                    <TableCell align="left">{moment(new Date(row.created_time)).format("MM-DD-YYYY")}</TableCell>
                  </TableRow> : ""}
                  {row?.assigned_to_time !== null && row?.assigned_to_time !== 'undefined' && row?.assigned_to_time !== '' ? <TableRow key={row?.length}>
                    <TableCell>Assigned To Time:</TableCell>
                    <TableCell align="left">{moment(new Date(parseInt(row.assigned_to_time))).format("MM-DD-YYYY, h:mm:ss a")}</TableCell>
                  </TableRow> : ""}
                  {row?.website !== null && row?.website !== 'undefined' && row?.website !== '' ? <TableRow key={row?.length}>
                    <TableCell>Website:</TableCell>
                    <TableCell align="left">{row.website}</TableCell>
                  </TableRow> : ""}
                  {row?.asset_name !== null && row?.asset_name !== 'undefined' && row?.asset_name !== '' ? <TableRow key={row?.length}>
                    <TableCell>Asset Name:</TableCell>
                    <TableCell align="left">{row.asset_name}</TableCell>
                  </TableRow> : ""}
                  {row?.creative_promotion_name !== null && row?.creative_promotion_name !== 'undefined' && row?.creative_promotion_name !== '' ? <TableRow key={row?.length}>
                    <TableCell>Creative Promotion Name:</TableCell>
                    <TableCell align="left">{row.creative_promotion_name}</TableCell>
                  </TableRow> : ""}
                  {row?.creative_message !== null && row?.creative_message !== 'undefined' && row?.creative_message !== '' ? <TableRow key={row?.length}>
                    <TableCell>Creative Message:</TableCell>
                    <TableCell align="left">{row.creative_message}</TableCell>
                  </TableRow> : ""}
                  {row?.creative_start !== null && row?.creative_start !== 'undefined' && row?.creative_start !== '' ? <TableRow key={row?.length}>
                    <TableCell>Creative Start of Promotion:</TableCell>
                    <TableCell align="left">{row.creative_start}</TableCell>
                  </TableRow> : ""}
                  {row?.creative_end !== null && row?.creative_end !== 'undefined' && row?.creative_end !== '' ? <TableRow key={row?.length}>
                    <TableCell>Creative End of Promotion:</TableCell>
                    <TableCell align="left">{row.creative_end}</TableCell>
                  </TableRow> : ""}
                  {row?.landing_page !== null && row?.landing_page !== 'undefined' && row?.landing_page !== '' ? <TableRow key={row?.length}>
                    <TableCell>Landing Page:</TableCell>
                    <TableCell align="left"><a target="_blank" href={row.landing_page}>{row.landing_page}</a></TableCell>
                  </TableRow> : ""}
                  {row?.creative_bannerads !== null && row?.creative_bannerads !== 'undefined' && row?.creative_bannerads !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Creative Banner Ads:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.socialads !== null && row?.socialads !== 'undefined' && row?.socialads !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Creative Social Ads:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.creative_onlinevideo !== null && row?.creative_onlinevideo !== 'undefined' && row?.creative_onlinevideo !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Creative Online Video:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.creative_hydrate !== null && row?.creative_hydrate !== 'undefined' && row?.creative_hydrate !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Creative Hydrate:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.creative_out_of_home !== null && row?.creative_out_of_home !== 'undefined' && row?.creative_out_of_home !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Creative Out Of Home:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  <br />
                  {row?.paidsearch_text_ads !== null && row?.paidsearch_text_ads !== 'undefined' && row?.paidsearch_text_ads !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Paid Search Text Ads:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.paidsearch_text_ads_assigned_user !== null && row?.paidsearch_text_ads_assigned_user !== 'undefined' && row?.paidsearch_text_ads_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Paid Search Text Ads Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.paidsearch_text_ads_assigned_user?.first_name} {row?.paidsearch_text_ads_assigned_user?.last_name}
                      <div>{row?.paidsearch_text_ads_completed === 1 ? `Completed on ` : ""} {row?.paidsearch_text_ads_completed_time ? moment(new Date(parseInt(row.paidsearch_text_ads_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.paidsearch_text_ads_completed_notes !== null ? `Completion Notes: ` : ""} {row?.paidsearch_text_ads_completed_notes}</div>
                      {((!row?.paidsearch_text_ads_completed && me?.readMe?.id === row?.paidsearch_text_ads_assignment) || (!row?.paidsearch_text_ads_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="paidsearch_text_ads_completed_notes"
                            labelText="Completed Notes"
                            id="paidsearch_text_ads_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("paidsearch_text_ads_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.paidsearch_text_ads_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "paidsearch_text_ads_completed",
                                    notes: submissionData?.paidsearch_text_ads_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.paidsearch_text_ads_assigned_user !== null && row?.paidsearch_text_ads_assigned_user !== 'undefined' && row?.paidsearch_text_ads_assigned_user !== '' ? <TableRow>
                    <TableCell>Paidsearch Text Ads Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"paidsearch_text_ads_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("paidsearch_text_ads_completed_status" + row.id, "paidsearch_text_ads_completed_status")}
                        value={row?.paidsearch_text_ads_completed_status ? row?.paidsearch_text_ads_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.paidsearch_ad_extensions !== null && row?.paidsearch_ad_extensions !== 'undefined' && row?.paidsearch_ad_extensions !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Paid Search Ads Extensions:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.paidsearch_ad_extensions_assigned_user !== null && row?.paidsearch_ad_extensions_assigned_user !== 'undefined' && row?.paidsearch_ad_extensions_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Paid Search Ads Extensions Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.paidsearch_ad_extensions_assigned_user?.first_name} {row?.paidsearch_ad_extensions_assigned_user?.last_name}
                      <div>{row?.paidsearch_ad_extensions_completed === 1 ? `Completed on ` : ""} {row?.paidsearch_ad_extensions_completed_time ? moment(new Date(parseInt(row.paidsearch_ad_extensions_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.paidsearch_ad_extensions_completed_notes !== null ? `Completion Notes: ` : ""} {row?.paidsearch_ad_extensions_completed_notes}</div>
                      {((!row?.paidsearch_ad_extensions_completed && me?.readMe?.id === row?.paidsearch_ad_extensions_assignment) || (!row?.paidsearch_ad_extensions_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <form>
                            <CustomInput
                              name="paidsearch_ad_extensions_completed_notes"
                              labelText="Completed Notes"
                              id="paidsearch_ad_extensions_completed_notes"
                              required={true}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "string",
                                onChange: updateFieldHandler("paidsearch_ad_extensions_completed_notes"),
                                multiline: true,
                                rows: 5,
                              }}
                            />
                          </form>
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.paidsearch_ad_extensions_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "paidsearch_ad_extensions_completed",
                                    notes: submissionData?.paidsearch_ad_extensions_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.paidsearch_ad_extensions_assigned_user !== null && row?.paidsearch_ad_extensions_assigned_user !== 'undefined' && row?.paidsearch_ad_extensions_assigned_user !== '' ? <TableRow>
                    <TableCell>Paidsearch Ad Extensions Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"paidsearch_ad_extensions_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("paidsearch_ad_extensions_completed_status" + row.id, "paidsearch_ad_extensions_completed_status")}
                        value={row?.paidsearch_ad_extensions_completed_status ? row?.paidsearch_ad_extensions_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.website_coupons !== null && row?.website_coupons !== 'undefined' && row?.website_coupons !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Website Coupons:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.website_coupons_assigned_user !== null && row?.website_coupons_assigned_user !== 'undefined' && row?.website_coupons_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Website Coupons Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.website_coupons_assigned_user?.first_name} {row?.website_coupons_assigned_user?.last_name}
                      <div>{row?.website_coupons_completed === 1 ? `Completed on ` : ""} {row?.website_coupons_completed_time ? moment(new Date(parseInt(row.website_coupons_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.website_coupons_completed_notes !== null ? `Completion Notes: ` : ""} {row?.website_coupons_completed_notes}</div>
                      {((!row?.website_coupons_completed && me?.readMe?.id === row?.website_coupons_assignment) || (!row?.website_coupons_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="website_coupons_completed_notes"
                            labelText="Completed Notes"
                            id="website_coupons_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("website_coupons_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.website_coupons_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "website_coupons_completed",
                                    notes: submissionData?.website_coupons_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.website_coupons_assigned_user !== null && row?.website_coupons_assigned_user !== 'undefined' && row?.website_coupons_assigned_user !== '' ? <TableRow>
                    <TableCell>Website Coupons Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"website_coupons_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("website_coupons_completed_status" + row.id, "website_coupons_completed_status")}
                        value={row?.website_coupons_completed_status ? row?.website_coupons_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_bannerads !== null && row?.promotion_bannerads !== 'undefined' && row?.promotion_bannerads !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Banner Ads:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.promotion_bannerads_assigned_user !== null && row?.promotion_bannerads_assigned_user !== 'undefined' && row?.promotion_bannerads_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Banner Ads Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.promotion_bannerads_assigned_user?.first_name} {row?.promotion_bannerads_assigned_user?.last_name}
                      <div>{row?.promotion_bannerads_completed === 1 ? `Completed on ` : ""} {row?.promotion_bannerads_completed_time ? moment(new Date(parseInt(row.promotion_bannerads_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.promotion_bannerads_completed_notes !== null ? `Completion Notes: ` : ""} {row?.promotion_bannerads_completed_notes}</div>
                      {((!row?.promotion_bannerads_completed && me?.readMe?.id === row?.promotion_bannerads_assignment) || (!row?.promotion_bannerads_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="promotion_bannerads_completed_notes"
                            labelText="Completed Notes"
                            id="promotion_bannerads_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("promotion_bannerads_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.promotion_bannerads_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "promotion_bannerads_completed",
                                    notes: submissionData?.promotion_bannerads_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_bannerads_assigned_user !== null && row?.promotion_bannerads_assigned_user !== 'undefined' && row?.promotion_bannerads_assigned_user !== '' ? <TableRow>
                    <TableCell>Promotion BannerAds Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"promotion_bannerads_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("promotion_bannerads_completed_status" + row.id, "promotion_bannerads_completed_status")}
                        value={row?.promotion_bannerads_completed_status ? row?.promotion_bannerads_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_onlinevideo !== null && row?.promotion_onlinevideo !== 'undefined' && row?.promotion_onlinevideo !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Online Video:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.promotion_onlinevideo_assigned_user !== null && row?.promotion_onlinevideo_assigned_user !== 'undefined' && row?.promotion_onlinevideo_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Online Video Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.promotion_onlinevideo_assigned_user?.first_name} {row?.promotion_onlinevideo_assigned_user?.last_name}
                      <div>{row?.promotion_onlinevideo_completed === 1 ? `Completed on ` : ""} {row?.promotion_onlinevideo_completed_time ? moment(new Date(parseInt(row.promotion_onlinevideo_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.promotion_onlinevideo_completed_notes !== null ? `Completion Notes: ` : ""} {row?.promotion_onlinevideo_completed_notes}</div>
                      {((!row?.promotion_onlinevideo_completed && me?.readMe?.id === row?.promotion_onlinevideo_assignment) || (!row?.promotion_onlinevideo_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="promotion_onlinevideo_completed_notes"
                            labelText="Completed Notes"
                            id="promotion_onlinevideo_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("promotion_onlinevideo_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.promotion_onlinevideo_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "promotion_onlinevideo_completed",
                                    notes: submissionData?.promotion_onlinevideo_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_onlinevideo_assigned_user !== null && row?.promotion_onlinevideo_assigned_user !== 'undefined' && row?.promotion_onlinevideo_assigned_user !== '' ? <TableRow>
                    <TableCell>Promotion OnlineVideo Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"promotion_onlinevideo_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("promotion_onlinevideo_completed_status" + row.id, "promotion_onlinevideo_completed_status")}
                        value={row?.promotion_onlinevideo_completed_status ? row?.promotion_onlinevideo_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_hydrate !== null && row?.promotion_hydrate !== 'undefined' && row?.promotion_hydrate !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Hydrate:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.promotion_hydrate_assigned_user !== null && row?.promotion_hydrate_assigned_user !== 'undefined' && row?.promotion_hydrate_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Hydrate Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.promotion_hydrate_assigned_user?.first_name} {row?.promotion_hydrate_assigned_user?.last_name}
                      <div>{row?.promotion_hydrate_completed === 1 ? `Completed on ` : ""} {row?.promotion_hydrate_completed_time ? moment(new Date(parseInt(row.promotion_hydrate_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.promotion_hydrate_completed_notes !== null ? `Completion Notes: ` : ""} {row?.promotion_hydrate_completed_notes}</div>
                      {((!row?.promotion_hydrate_completed && me?.readMe?.id === row?.promotion_hydrate_assignment) || (!row?.promotion_hydrate_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="promotion_hydrate_completed_notes"
                            labelText="Completed Notes"
                            id="promotion_hydrate_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("promotion_hydrate_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.promotion_hydrate_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "promotion_hydrate_completed",
                                    notes: submissionData?.promotion_hydrate_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_hydrate_assigned_user !== null && row?.promotion_hydrate_assigned_user !== 'undefined' && row?.promotion_hydrate_assigned_user !== '' ? <TableRow>
                    <TableCell>Promotion Hydrate Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"promotion_hydrate_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("promotion_hydrate_completed_status" + row.id, "promotion_hydrate_completed_status")}
                        value={row?.promotion_hydrate_completed_status ? row?.promotion_hydrate_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_out_of_home !== null && row?.promotion_out_of_home !== 'undefined' && row?.promotion_out_of_home !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Out Of Home:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.promotion_out_of_home_assigned_user !== null && row?.promotion_out_of_home_assigned_user !== 'undefined' && row?.promotion_out_of_home_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Promotion Out Of Home Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.promotion_out_of_home_assigned_user?.first_name} {row?.promotion_out_of_home_assigned_user?.last_name}
                      <div>{row?.promotion_out_of_home_completed === 1 ? `Completed on ` : ""} {row?.promotion_out_of_home_completed_time ? moment(new Date(parseInt(row.promotion_out_of_home_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.promotion_out_of_home_completed_notes !== null ? `Completion Notes: ` : ""} {row?.promotion_out_of_home_completed_notes}</div>
                      {((!row?.promotion_out_of_home_completed && me?.readMe?.id === row?.promotion_out_of_home_assignment) || (!row?.promotion_out_of_home_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="promotion_out_of_home_completed_notes"
                            labelText="Completed Notes"
                            id="promotion_out_of_home_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("promotion_out_of_home_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.promotion_out_of_home_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "promotion_out_of_home_completed",
                                    notes: submissionData?.promotion_out_of_home_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.promotion_out_of_home_assigned_user !== null && row?.promotion_out_of_home_assigned_user !== 'undefined' && row?.promotion_out_of_home_assigned_user !== '' ? <TableRow>
                    <TableCell>Promotion Out Of Home Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"promotion_out_of_home_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("promotion_out_of_home_completed_status" + row.id, "promotion_out_of_home_completed_status")}
                        value={row?.promotion_out_of_home_completed_status ? row?.promotion_out_of_home_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.social_media_post !== null && row?.social_media_post !== 'undefined' && row?.social_media_post !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Social Media Post:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.social_media_post_assigned_user !== null && row?.social_media_post_assigned_user !== 'undefined' && row?.social_media_post_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Social Media Post Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.social_media_post_assigned_user?.first_name} {row?.social_media_post_assigned_user?.last_name}
                      <div>{row?.social_media_post_assignment_completed === 1 ? `Completed on ` : ""} {row?.social_media_post_assignment_completed_time ? moment(new Date(parseInt(row.social_media_post_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.social_media_post_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.social_media_post_assignment_completed_notes}</div>
                      {((!row?.social_media_post_assignment_completed && me?.readMe?.id === row?.social_media_post_assignment) || (!row?.social_media_post_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="social_media_post_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="social_media_post_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("social_media_post_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.social_media_post_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "social_media_post_assignment_completed",
                                    notes: submissionData?.social_media_post_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.social_media_post_assigned_user !== null && row?.social_media_post_assigned_user !== 'undefined' && row?.social_media_post_assigned_user !== '' ? <TableRow>
                    <TableCell>Social Media Post Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"social_media_post_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("social_media_post_assignment_completed_status" + row.id, "social_media_post_assignment_completed_status")}
                        value={row?.social_media_post_assignment_completed_status ? row?.social_media_post_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.new_website_content_pages !== null && row?.new_website_content_pages !== 'undefined' && row?.new_website_content_pages !== 0 ? <TableRow key={row?.length}>
                    <TableCell>New Website Content Pages:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.new_website_content_pages_assigned_user !== null && row?.new_website_content_pages_assigned_user !== 'undefined' && row?.new_website_content_pages_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>New Website Content Pages Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.new_website_content_pages_assigned_user?.first_name} {row?.new_website_content_pages_assigned_user?.last_name}
                      <div>{row?.new_website_content_pages_assignment_completed === 1 ? `Completed on ` : ""} {row?.new_website_content_pages_assignment_completed_time ? moment(new Date(parseInt(row.new_website_content_pages_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.new_website_content_pages_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.new_website_content_pages_assignment_completed_notes}</div>
                      {((!row?.new_website_content_pages_assignment_completed && me?.readMe?.id === row?.new_website_content_pages_assignment) || (!row?.new_website_content_pages_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="new_website_content_pages_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="new_website_content_pages_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("new_website_content_pages_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.new_website_content_pages_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "new_website_content_pages_assignment_completed",
                                    notes: submissionData?.new_website_content_pages_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.new_website_content_pages_assigned_user !== null && row?.new_website_content_pages_assigned_user !== 'undefined' && row?.new_website_content_pages_assigned_user !== '' ? <TableRow>
                    <TableCell>New Website Content Pages Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"new_website_content_pages_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("new_website_content_pages_assignment_completed_status" + row.id, "new_website_content_pages_assignment_completed_status")}
                        value={row?.new_website_content_pages_assignment_completed_status ? row?.new_website_content_pages_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.blog_content !== null && row?.blog_content !== 'undefined' && row?.blog_content !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Blog Content:</TableCell>
                    <TableCell align="left">Yes</TableCell>
                  </TableRow> : ""}
                  {row?.blog_content_assigned_user !== null && row?.blog_content_assigned_user !== 'undefined' && row?.blog_content_assigned_user !== 0 ? <TableRow key={row?.length}>
                    <TableCell>Blog Content Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.blog_content_assigned_user?.first_name} {row?.blog_content_assigned_user?.last_name}
                      <div>{row?.blog_content_assignment_completed === 1 ? `Completed on ` : ""} {row?.blog_content_assignment_completed_time ? moment(new Date(parseInt(row.blog_content_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.blog_content_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.blog_content_assignment_completed_notes}</div>
                      {((!row?.blog_content_assignment_completed && me?.readMe?.id === row?.blog_content_assignment) || (!row?.blog_content_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="blog_content_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="blog_content_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("blog_content_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.blog_content_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "blog_content_assignment_completed",
                                    notes: submissionData?.blog_content_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.blog_content_assigned_user !== null && row?.blog_content_assigned_user !== 'undefined' && row?.blog_content_assigned_user !== '' ? <TableRow>
                    <TableCell>Blog Content Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"blog_content_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("blog_content_assignment_completed_status" + row.id, "blog_content_assignment_completed_status")}
                        value={row?.blog_content_assignment_completed_status ? row?.blog_content_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.campaign_review_assigned_user !== null && row?.campaign_review_assigned_user !== 'undefined' && row?.campaign_review_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Campaign Review Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.campaign_review_assigned_user ? `${row.campaign_review_assigned_user?.first_name} ${row.campaign_review_assigned_user?.last_name}` : ""}
                      <div>{row?.campaign_review_assignment_completed === 1 ? `Completed on ` : ""} {row?.campaign_review_assignment_completed_time ? moment(new Date(parseInt(row.campaign_review_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.campaign_review_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.campaign_review_assignment_completed_notes}</div>
                      {((!row?.campaign_review_assignment_completed && me?.readMe?.id === row?.campaign_review_assignment) || (!row?.campaign_review_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="campaign_review_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="campaign_review_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("campaign_review_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.campaign_review_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "campaign_review_assignment_completed",
                                    notes: submissionData?.campaign_review_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.campaign_review_assigned_user !== null && row?.campaign_review_assigned_user !== 'undefined' && row?.campaign_review_assigned_user !== '' ? <TableRow>
                    <TableCell>Campaign Review Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"campaign_review_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("campaign_review_assignment_completed_status" + row.id, "campaign_review_assignment_completed_status")}
                        value={row?.campaign_review_assignment_completed_status ? row?.campaign_review_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.reporting_request_assigned_user !== null && row?.reporting_request_assigned_user !== 'undefined' && row?.reporting_request_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Reporting Request Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.reporting_request_assigned_user ? `${row.reporting_request_assigned_user?.first_name} ${row.reporting_request_assigned_user?.last_name}` : ""}
                      <div>{row?.reporting_request_assignment_completed === 1 ? `Completed on ` : ""} {row?.reporting_request_assignment_completed_time ? moment(new Date(parseInt(row.reporting_request_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.reporting_request_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.reporting_request_assignment_completed_notes}</div>
                      {((!row?.reporting_request_assignment_completed && me?.readMe?.id === row?.reporting_request_assignment) || (!row?.reporting_request_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="reporting_request_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="reporting_request_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("reporting_request_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.reporting_request_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "reporting_request_assignment_completed",
                                    notes: submissionData?.reporting_request_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.reporting_request_assigned_user !== null && row?.reporting_request_assigned_user !== 'undefined' && row?.reporting_request_assigned_user !== '' ? <TableRow>
                    <TableCell>Reporting Request Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"reporting_request_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("reporting_request_assignment_completed_status" + row.id, "reporting_request_assignment_completed_status")}
                        value={row?.reporting_request_assignment_completed_status ? row?.reporting_request_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.call_tracking_issue_assigned_user !== null && row?.call_tracking_issue_assigned_user !== 'undefined' && row?.call_tracking_issue_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Call Tracking Issue Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.call_tracking_issue_assigned_user ? `${row.call_tracking_issue_assigned_user?.first_name} ${row.call_tracking_issue_assigned_user?.last_name}` : ""}
                      <div>{row?.call_tracking_issue_assignment_completed === 1 ? `Completed on ` : ""} {row?.call_tracking_issue_assignment_completed_time ? moment(new Date(parseInt(row.call_tracking_issue_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.call_tracking_issue_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.call_tracking_issue_assignment_completed_notes}</div>
                      {((!row?.call_tracking_issue_assignment_completed && me?.readMe?.id === row?.call_tracking_issue_assignment) || (!row?.call_tracking_issue_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="call_tracking_issue_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="call_tracking_issue_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("call_tracking_issue_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.call_tracking_issue_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "call_tracking_issue_assignment_completed",
                                    notes: submissionData?.call_tracking_issue_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.call_tracking_issue_assigned_user !== null && row?.call_tracking_issue_assigned_user !== 'undefined' && row?.call_tracking_issue_assigned_user !== '' ? <TableRow>
                    <TableCell>Call Tracking Issue Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"call_tracking_issue_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("call_tracking_issue_assignment_completed_status" + row.id, "call_tracking_issue_assignment_completed_status")}
                        value={row?.call_tracking_issue_assignment_completed_status ? row?.call_tracking_issue_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.campaign_performance_issue_assigned_user !== null && row?.campaign_performance_issue_assigned_user !== 'undefined' && row?.campaign_performance_issue_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Campaign Performance Issue Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.campaign_performance_issue_assigned_user ? `${row.campaign_performance_issue_assigned_user?.first_name} ${row.campaign_performance_issue_assigned_user?.last_name}` : ""}
                      <div>{row?.campaign_performance_issue_assignment_completed === 1 ? `Completed on ` : ""} {row?.campaign_performance_issue_assignment_completed_time ? moment(new Date(parseInt(row.campaign_performance_issue_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.campaign_performance_issue_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.campaign_performance_issue_assignment_completed_notes}</div>
                      {((!row?.campaign_performance_issue_assignment_completed && me?.readMe?.id === row?.campaign_performance_issue_assignment) || (!row?.campaign_performance_issue_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="campaign_performance_issue_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="campaign_performance_issue_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("campaign_performance_issue_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.campaign_performance_issue_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "campaign_performance_issue_assignment_completed",
                                    notes: submissionData?.campaign_performance_issue_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.campaign_performance_issue_assigned_user !== null && row?.campaign_performance_issue_assigned_user !== 'undefined' && row?.campaign_performance_issue_assigned_user !== '' ? <TableRow>
                    <TableCell>Campaign Performance Issue Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"campaign_performance_issue_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("campaign_performance_issue_assignment_completed_status" + row.id, "campaign_performance_issue_assignment_completed_status")}
                        value={row?.campaign_performance_issue_assignment_completed_status ? row?.campaign_performance_issue_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.update_creative_assigned_user !== null && row?.update_creative_assigned_user !== 'undefined' && row?.update_creative_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Redesign Creative Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.update_creative_assigned_user ? `${row.update_creative_assigned_user?.first_name} ${row.update_creative_assigned_user?.last_name}` : ""}
                      <div>{row?.update_creative_assignment_completed === 1 ? `Completed on ` : ""} {row?.update_creative_assignment_completed_time ? moment(new Date(parseInt(row.update_creative_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.update_creative_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.update_creative_assignment_completed_notes}</div>
                      {((!row?.update_creative_assignment_completed && me?.readMe?.id === row?.update_creative_assignment) || (!row?.update_creative_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="update_creative_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="update_creative_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("update_creative_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.update_creative_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "update_creative_assignment_completed",
                                    notes: submissionData?.update_creative_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.update_creative_assigned_user !== null && row?.update_creative_assigned_user !== 'undefined' && row?.update_creative_assigned_user !== '' ? <TableRow>
                    <TableCell>Update Creative Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"update_creative_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("update_creative_assignment_completed_status" + row.id, "update_creative_assignment_completed_status")}
                        value={row?.update_creative_assignment_completed_status ? row?.update_creative_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.budget_review_assigned_user !== null && row?.budget_review_assigned_user !== 'undefined' && row?.budget_review_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Budget Review Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.budget_review_assigned_user ? `${row.budget_review_assigned_user?.first_name} ${row.budget_review_assigned_user?.last_name}` : ""}
                      <div>{row?.budget_review_assignment_completed === 1 ? `Completed on ` : ""} {row?.budget_review_assignment_completed_time ? moment(new Date(parseInt(row.budget_review_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.budget_review_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.budget_review_assignment_completed_notes}</div>
                      {((!row?.budget_review_assignment_completed && me?.readMe?.id === row?.budget_review_assignment) || (!row?.budget_review_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="budget_review_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="budget_review_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("budget_review_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.budget_review_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "budget_review_assignment_completed",
                                    notes: submissionData?.budget_review_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.budget_review_assigned_user !== null && row?.budget_review_assigned_user !== 'undefined' && row?.budget_review_assigned_user !== '' ? <TableRow>
                    <TableCell>Budget Review Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"budget_review_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("budget_review_assignment_completed_status" + row.id, "budget_review_assignment_completed_status")}
                        value={row?.budget_review_assignment_completed_status ? row?.budget_review_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.website_edit_assigned_user !== null && row?.website_edit_assigned_user !== 'undefined' && row?.website_edit_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Website Edit Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.website_edit_assigned_user ? `${row.website_edit_assigned_user?.first_name} ${row.website_edit_assigned_user?.last_name}` : ""}
                      <div>{row?.website_edit_assignment_completed === 1 ? `Completed on ` : ""} {row?.website_edit_assignment_completed_time ? moment(new Date(parseInt(row.website_edit_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.website_edit_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.website_edit_assignment_completed_notes}</div>
                      {((!row?.website_edit_assignment_completed && me?.readMe?.id === row?.website_edit_assignment) || (!row?.website_edit_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="website_edit_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="website_edit_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("website_edit_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.website_edit_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "website_edit_assignment_completed",
                                    notes: submissionData?.website_edit_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.website_edit_assigned_user !== null && row?.website_edit_assigned_user !== 'undefined' && row?.website_edit_assigned_user !== '' ? <TableRow>
                    <TableCell>Website Edit Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"website_edit_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("website_edit_assignment_completed_status" + row.id, "website_edit_assignment_completed_status")}
                        value={row?.website_edit_assignment_completed_status ? row?.website_edit_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.cancellation_assigned_user !== null && row?.cancellation_assigned_user !== 'undefined' && row?.cancellation_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Cancellation Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.cancellation_assigned_user ? `${row.cancellation_assigned_user?.first_name} ${row.cancellation_assigned_user?.last_name}` : ""}
                      <div>{row?.cancellation_assignment_completed === 1 ? `Completed on ` : ""} {row?.cancellation_assignment_completed_time ? moment(new Date(parseInt(row.cancellation_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.cancellation_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.cancellation_assignment_completed_notes}</div>
                      {((!row?.cancellation_assignment_completed && me?.readMe?.id === row?.cancellation_assignment) || (!row?.cancellation_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="cancellation_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="cancellation_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("cancellation_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.cancellation_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "cancellation_assignment_completed",
                                    notes: submissionData?.cancellation_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.cancellation_assigned_user !== null && row?.cancellation_assigned_user !== 'undefined' && row?.cancellation_assigned_user !== '' ? <TableRow>
                    <TableCell>Cancellation Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"cancellation_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("cancellation_assignment_completed_status" + row.id, "cancellation_assignment_completed_status")}
                        value={row?.cancellation_assignment_completed_status ? row?.cancellation_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.other_assigned_user !== null && row?.other_assigned_user !== 'undefined' && row?.other_assigned_user !== '' ? <TableRow key={row?.length}>
                    <TableCell>Other Assigned To:</TableCell>
                    <TableCell align="left">
                      {row?.other_assigned_user ? `${row.other_assigned_user?.first_name} ${row.other_assigned_user?.last_name}` : ""}
                      <div>{row?.other_assignment_completed === 1 ? `Completed on ` : ""} {row?.other_assignment_completed_time ? moment(new Date(parseInt(row.other_assignment_completed_time))).format("MM-DD-YYYY, h:mm:ss a") : ""}</div>
                      <div>{row?.other_assignment_completed_notes !== null ? `Completion Notes: ` : ""} {row?.other_assignment_completed_notes}</div>
                      {((!row?.other_assignment_completed && me?.readMe?.id === row?.other_assignment) || (!row?.other_assignment_completed && me?.readMe?.user_level === "master")) && (
                        <div>
                          <CustomInput
                            name="other_assignment_completed_notes"
                            labelText="Completed Notes"
                            id="other_assignment_completed_notes"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler("other_assignment_completed_notes"),
                              multiline: true,
                              rows: 5,
                            }}
                          />
                          <Button
                            color="warning"
                            style={{ width: "100px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (submissionData === null || submissionData?.other_assignment_completed_notes === "") {
                                alert("Please enter a comment.");
                              } else {
                                completeCreativeTask({
                                  variables: {
                                    id: row?.id,
                                    data: "other_assignment_completed",
                                    notes: submissionData?.other_assignment_completed_notes
                                  }
                                });
                              }
                            }}
                          >
                            {"Complete Task"}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow> : ""}
                  {row?.other_assigned_user !== null && row?.other_assigned_user !== 'undefined' && row?.other_assigned_user !== '' ? <TableRow>
                    <TableCell>Other Status:</TableCell>
                    <TableCell align="left"><div>
                      <div style={{ "float": "left" }}><select
                        id={"other_assignment_completed_status" + row.id}
                        required={true}
                        onChange={updateStatus("other_assignment_completed_status" + row.id, "other_assignment_completed_status")}
                        value={row?.other_assignment_completed_status ? row?.other_assignment_completed_status : 1}
                      >
                        <option value="1">Submitted</option>
                        <option value="2">In Progress</option>
                        <option value="3">Needs Info</option>
                        <option value="4">Ready For Review</option>
                        <option value="5">Resolved</option>
                      </select></div>
                    </div>
                    </TableCell>
                  </TableRow> : ""}
                  {row?.closed_by !== null && row?.closed_by !== 'undefined' && row?.closed_by !== '' ? <TableRow key={row?.length}>
                    <TableCell>Closed By:</TableCell>
                    <TableCell align="left">{row.closed_by}</TableCell>
                  </TableRow> : ""}
                  {row?.closed_by_time !== null && row?.closed_by_time !== 'undefined' && row?.closed_by_time !== '' ? <TableRow key={row?.length}>
                    <TableCell>Closed By Time:</TableCell>
                    <TableCell align="left">{moment(new Date(parseInt(row.closed_by_time))).format("MM-DD-YYYY, h:mm:ss a")}</TableCell>
                  </TableRow> : ""}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const classes = useRowStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>Tickets</h2>
      {ticketid !== "0" ? <a href="/admin/tickets" style={{ marginTop: "20px" }}>See All Tickets</a> : ""}
      {ticketid === "0" ? <select
        style={{ marginTop: "20px" }}
        id="see_tickets"
        onChange={event => { handleSeeTickets(event.target.value) }}
        value={seetickets === 0 ? "opened" : seetickets === 1 ? "closed" : "all"}
      >
        <option value="all">See all Tickets</option>
        <option value="opened">See opened Tickets</option>
        <option value="closed">See closed Tickets</option>
      </select> : ""}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Search by Company Name&nbsp;<input
        id="Customer_Search"
        type="text"
        maxLength="30"
        size="30"
        key="Customer_Search"
        onChange={event => { handleCustomerSearch(event.target.value) }}
        inputprops={{
          type: "string",
        }}
      />
      {ticketid === "0" ?
        <TablePagination
          component="div"
          count={all_data.length > 0 ? all_data[0]?.ticket_count : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        /> : ""}
      <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
        <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableCell>&nbsp;</TableCell>
            {data.map((datarow) => (
              <Row key={datarow?.id} row={datarow} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={Boolean(selectedCreative)}
        onClose={() => setSelectedCreative(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent>
          <CreateTicket
            data={selectedCreative}
            onCompleted={() => {
              setSelectedCreative(null);
              refetch();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}