import React from "react";
import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import UploadInput from "components/UploadInput";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";

const filter = createFilterOptions();

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}


const AddUpdateDocument = (CRMActivitySubmission, updateActivityFieldHandler, updateDocumentDatabase,
  CRMPeople, DocumentsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, updateDocumentFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, Update = false, CRMActivity = []) => {

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Upload A"} Document</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "145px" }}>Document Name:</label>
        <input
          id="documents_name"
          maxLength={255}
          type="text"
          value={DocumentsubmissionData?.documents_name || ""}
          onChange={updateDocumentFieldHandler("documents_name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {uploadPercentage && (
        <LinearDeterminate uploadPercentage={uploadPercentage} />
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ textAlign: "left", float: "left", width: "145px" }}>Upload Document:</label>
        <div style={{ width: "610px", height: "40px !important", float: "left" }}>
          { DocumentsubmissionData["documents_url"] && (
            <a href={DocumentsubmissionData["documents_url"]} target="_blank" rel="noopener noreferrer">View</a>
          )}
          <UploadInput
            path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
            onChange={value => {updateDocumentFieldHandler("documents_url")({ target: { value } })}}
            value={DocumentsubmissionData["documents_url"] || ""}
            //label={DocumentsubmissionData["documents_url"] ? "View" : "Upload"}
            isImage={false}
            setuploadPercentage={setuploadPercentage}
          />
        </div>
      </div>
      <div style={{ clear: "both", marginTop: "60px" }}></div>
      {window.location.href.indexOf("crmpersondetail") === -1 && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", float: "left", width: "122px" }}>People:</label>
            {Update === true ?
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === DocumentsubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: DocumentsubmissionData?.people_name ? DocumentsubmissionData?.people_name : "" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Document")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Document")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Document")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
              :
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Document")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Document")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Document")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
            }
            {PersonFound ? "" :
              <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
          </div>
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <label style={{ marginTop: "8px", width: "122px", float: "left" }}>Assign To:</label>
      <Select
        options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
          key: id,
          label: first_name + " " + last_name
        }))}
        selected={DocumentsubmissionData?.assigned_to || 0}
        onSelect={updateDocumentFieldHandler("assigned_to")}
        style={{ marginBottom: 20, float: "left", height: "40px" }}
      />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === DocumentsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === DocumentsubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === DocumentsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === DocumentsubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px", width: "100%" }} /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateDocumentDatabase();
      }}>
        Save
      </Button>
    </div>
  )
};

export default AddUpdateDocument;
