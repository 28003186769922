import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

const ProposalList = (row, index, CRMProposal, object_key, CRMRepresentative, isProposalOpenCollapse, customer_id) => {
  return CRMProposal?.filter((CurrentProposal) => CurrentProposal[object_key] === row?.id).map(CurrentProposal => {
    return (
      <>
        {isProposalOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isProposalOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Proposal Name</b><br /> <a href={"/admin/crmproposaldetail?customer=" + customer_id + "&id=" + CurrentProposal?.crm_customers_id + "&peopleid=" + CurrentProposal?.people_id + "&proposalid=" + CurrentProposal?.id}>{CurrentProposal?.proposal_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Proposal Value</b><br />{parseFloat(CurrentProposal?.proposal_value?.replace(/,/g, '')).toLocaleString('en-US', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Proposal</b><br /> <a href={CurrentProposal?.proposal_url} target="_blank">Link</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Proposal Status</b><br /> {CurrentProposal?.proposal_status === 1 ? "Won" : CurrentProposal?.proposal_status === 2 ? "Lost" : "Pending"}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === CurrentProposal?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow >
        )}
      </>
    )
  })
};

export default ProposalList;
