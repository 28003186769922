import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { omit } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// @mui/icons-material
import PermIdentity from "@mui/icons-material/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import Switch from '@mui/material/Switch';
import { READ_ME, UPDATE_ME, ADD_USER } from "queries/users";
import withSubmissions from "hocs/withSubmissions";
import { LIST_CUSTOMER_USERS } from "queries/users";
import {
  UPDATE_CUSTOMER,
  LIST_CUSTOMERS,
  LIST_MUTED_NOTIFICATIONS,
  UPDATE_MUTED_NOTIFICATIONS,
  ADD_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  ADD_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORIES,
  DELETE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
  GET_CUSTOMER_STATUS,
  ADD_LEAD_STATUS,
  UPDATE_LEAD_STATUS,
  DELETE_LEAD_STATUS,
  GET_CONTRACT_STATUS,
  ADD_CONTRACT_STATUS,
  GET_JOB_STATUS,
  ADD_JOB_STATUS,
  UPDATE_CONTRACT_STATUS,
  UPDATE_JOB_STATUS,
  DELETE_CONTRACT_STATUS,
  DELETE_JOB_STATUS,
  GET_PROPOSAL_STATUS,
  ADD_PROPOSAL_STATUS,
  UPDATE_PROPOSAL_STATUS,
  DELETE_PROPOSAL_STATUS
} from "queries/customers";


import {
  GET_LEAD_STATUS,
  ADD_LEAD_STATUS as ADD_PEOPLE_LEAD_STATUS,
  GET_LEAD_TYPE,
  DELETE_LEAD_STATUS as DELETE_PEOPLE_LEAD_STATUS,
  UPDATE_HYDRATE_CAMPAIGN
} from "queries/hydrate";

import HelpTooltip from "components/HelpTooltip";
import { Context } from "../../redux/store";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import { on } from "events";

const useStyles = makeStyles(styles);

const CRMSettings = ({ formSubmitted }) => {
  const me = useQuery(READ_ME)?.data?.readMe;
  const [userInfo, setUserInfo] = useState(me);
  const [state] = useContext(Context);
  const [newuserSubmission, setnewuserSubmission] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [MutedNotifications, setMutedNotifications] = useState([]);
  const [B2B, setB2B] = useState(null);
  const [AddProductCategory, setAddProductCategory] = useState(false);
  const [UpdateProductCategory, setUpdateProductCategory] = useState(false);
  const [DeleteProductCategory, setDeleteProductCategory] = useState(false);
  const [submissionData, setSubmissionData] = useState([]);
  const [submissionPeopleLeadStatusData, setSubmissionPeopleLeadStatusData] = useState([]);
  const [LeadStatusSubmissionData, setLeadStatusSubmissionData] = useState([]);
  const [CustomerStatusSubmissionData, setCustomerStatusSubmissionData] = useState([]);
  const [ContractStatusSubmissionData, setContractStatusSubmissionData] = useState([]);
  const [JobStatusSubmissionData, setJobStatusSubmissionData] = useState([]);
  const [ProposalStatusSubmissionData, setProposalStatusSubmissionData] = useState([]);
  const [UpdateCategory, setUpdateCategory] = useState([]);
  const [UpdateLeadStatus, setUpdateLeadStatus] = useState(false);
  const [DeleteCategory, setDeleteCategory] = useState(null);
  const [AddServiceCategory, setAddServiceCategory] = useState(false);
  const [UpdateServiceCategory, setUpdateServiceCategory] = useState(false);
  const [DeleteServiceCategory, setDeleteServiceCategory] = useState(false);
  const [AddLeadStatus, setAddLeadStatus] = useState(false);
  const [AddCustomerStatus, setAddCustomerStatus] = useState(false);
  const [UpdateCustomerStatus, setUpdateCustomerStatus] = useState(false);
  const [AddContractStatus, setAddContractStatus] = useState(false);
  const [AddJobStatus, setAddJobStatus] = useState(false);
  const [UpdateContractStatus, setUpdateContractStatus] = useState(false);
  const [UpdateJobStatus, setUpdateJobStatus] = useState(false);
  const [DeleteContractStatusDialog, setDeleteContractStatusDialog] = useState(false);
  const [DeleteJobStatusDialog, setDeleteJobStatusDialog] = useState(false);
  const [AddProposalStatus, setAddProposalStatus] = useState(false);
  const [UpdateProposalStatus, setUpdateProposalStatus] = useState(false);
  const [DeleteProposalStatusDialog, setDeleteProposalStatusDialog] = useState(false);
  const [UpdateCustomerStatusSubmissionData, setUpdateCustomerStatusSubmissionData] = useState([]);
  const [UpdateContractStatusSubmissionData, setUpdateContractStatusSubmissionData] = useState([]);
  const [UpdateJobStatusSubmissionData, setUpdateJobStatusSubmissionData] = useState([]);
  const [UpdateProposalStatusSubmissionData, setUpdateProposalStatusSubmissionData] = useState([]);
  const [AddPersonStatus, setAddPersonStatus] = useState(false);
  const [DeletePersonStatus, setDeletePersonStatus] = useState(false);
  const [DeleteCustomerStatus, setDeleteCustomerStatus] = useState('');
  const [DeleteContractStatus, setDeleteContractStatus] = useState('');
  const [DeleteJobStatus, setDeleteJobStatus] = useState('');
  const [DeleteProposalStatus, setDeleteProposalStatus] = useState('');
  const [DeleteCustomerStatusDialog, setDeleteCustomerStatusDialog] = useState(false);
  const [DeleteLeadStatus, setDeleteLeadStatus] = useState(false);
  const [CategoryData, setCategoryData] = useState(null);
  const [ServiceCategoryData, setServiceCategoryData] = useState(null);
  const [LeadStatusData, setLeadStatusData] = useState(null);
  const [ContractStatusData, setContractStatusData] = useState(null);
  const [JobStatusData, setJobStatusData] = useState(null);
  const [ProposalStatusData, setProposalStatusData] = useState(null);
  const [CustomerStatusData, setCustomerStatusData] = useState(null);
  const [ServicesubmissionData, setServiceSubmissionData] = useState([]);
  const [ServiceUpdateCategory, setServiceUpdateCategory] = useState([]);
  const [ServiceDeleteCategory, setServiceDeleteCategory] = useState(null);
  const [deleteType, setSelectedDeleteType] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.crm_user_level;
  const customer_id = state.customers?.selected?.id;

  const { data: users, refetch } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  if (crm_user_level && crm_user_level !== "crm_admin")
    navigate("/admin/crmcustomers?customer=" + customer_id);

  const [updateMe] = useMutation(UPDATE_ME);

  const [addUser] = useMutation(ADD_USER,
    {
      onCompleted: (reponse) => {
        if (reponse?.createUser === false)
          alert("Email Address Already In Use.")
        else {
          const notify = options => {
            const key = enqueueSnackbar(options?.text, {
              variant: options?.variant,
              persist: true
            });
            setTimeout(() => {
              closeSnackbar(key);
            }, options?.delay || 2000);
          }
          notify({
            text: "Information has been successfully submitted",
            variant: "success"
          });

          setnewuserSubmission(null);
          refetch();
        }
      }
    });

  const classes = useStyles();

  let { data: customer_data, refetch: refetch_customers } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  const [GetMutedNotifications] = useLazyQuery(LIST_MUTED_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
    skip: !state.customers?.selected?.id || !me?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
      user_id: me?.id
    },
    onCompleted: (data) => {
      console.log(data)
      setMutedNotifications(data?.listMutedNotifications);
    }
  });

  const [updateCustomer, { loading: updatingCustomer }] = useMutation(
    UPDATE_CUSTOMER,
    {
      onCompleted: () => {
        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        refetch_customers();
      }
    }
  );

  const { data: Category_Data, refetch: refetchCategories } = useQuery(GET_PRODUCT_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  const { data: ServiceCategory_Data, refetch: refetchServiceCategories } = useQuery(GET_SERVICE_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  const [deleteServiceCategoryForm] = useMutation(DELETE_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      refetchServiceCategories();
    }
  });

  let { data: CustomerStatus_data, refetch: refetchCustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: LeadStatus_data, refetch: refetchLeadStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 0,
      customonly: 1
    }
  });

  let { data: ContractStatus_data, refetch: refetchContractStatus_data } = useQuery(GET_CONTRACT_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 1,
      customonly: 1
    }
  });


  let { data: JobStatus_data, refetch: refetchJobStatus_data } = useQuery(GET_JOB_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: ProposalStatus_data, refetch: refetchProposalStatus_data } = useQuery(GET_PROPOSAL_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 1,
      customonly: 1
    }
  });

  const { data: LeadStatusPeople_data, refetch: refetchPersonLeadStatus } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let LeadStatus_data_temp = LeadStatusPeople_data?.getHydrateLeadStatus || [];

  let defaultLeadStatuses = [];
  let defaultLeadCustomerStatuses = [];

  if (LeadStatus_data_temp) {
    LeadStatus_data_temp.forEach(obj => {
      defaultLeadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
    })

    LeadStatus_data_temp.forEach(obj => {
      if (obj.lead_status > 11)
        defaultLeadCustomerStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
    })
  }

  const { data: LeadType_data, refetch: refetchleadtypes } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  let LeadType_data_temp = LeadType_data?.getHydrateLeadType || [];

  let defaultLeadTypes = [];
  let defaultLeadCustomerTypes = [];

  if (LeadType_data_temp) {
    defaultLeadTypes.push({ label: "Add Custom Lead Type", value: -1 })

    LeadType_data_temp.forEach(obj => {
      defaultLeadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })

    LeadType_data_temp.forEach(obj => {
      if (obj.lead_type > 4)
        defaultLeadCustomerTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }


  useEffect(() => {
    GetMutedNotifications();
    setCRMAssignedToUsers(users?.listCustomerUsers);
    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
    setCategoryData(Category_Data?.listProductCategories || []);
    setServiceCategoryData(ServiceCategory_Data?.listServiceCategories || []);
    setCustomerStatusData(CustomerStatus_data?.getCustomerStatus || []);
    setLeadStatusData(LeadStatus_data?.getCustomerStatus || []);

    setJobStatusData(JobStatus_data?.getJobStatus || []);
    setContractStatusData(ContractStatus_data?.getContractStatus || []);
    setProposalStatusData(ProposalStatus_data?.getProposalStatus || []);

  }, [users, customer_data, Category_Data, ServiceCategory_Data,
    CustomerStatus_data, LeadStatus_data, ContractStatus_data,
    ProposalStatus_data, JobStatus_data])


  const [updateCampaign, { loading: updatingCampaign }] = useMutation(
    UPDATE_HYDRATE_CAMPAIGN
  );

  const deleteFieldHandler = (key, convert) => e => {
    setDeleteData({
      ...deleteData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateB2BFieldHandler = (key) => e => {
    setB2B(
      e.target.value
    );

    updateCustomer({
      variables: {
        id: customer_id,
        data: {
          B2B: e.target.value === "B2B" ? 1 : 0
        }
      }
    });
  }

  const [addPeopleLeadStatusForm] = useMutation(ADD_PEOPLE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchPersonLeadStatus();
      refetchleadtypes();
    }
  });

  const [DeleteLeadStatusForm] = useMutation(DELETE_PEOPLE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchPersonLeadStatus();
      refetchleadtypes();
    }
  });

  const ServicedeleteCategory = (id) => {
    deleteServiceCategoryForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteCategory = (id) => {
    deleteCategoryForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteLeadStatus = (id) => {
    deleteLeadStatusForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteCustomerStatus = (id) => {
    deleteCustomerStatusForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteContactStatus = (id) => {
    deleteContractStatusForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteJobStatus = (id) => {
    deleteJobStatusForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const deleteProposalStatus = (id) => {
    deleteProposalStatusForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const updateCategoryFieldHandler = (key, convert) => e => {
    if (key === "category_text") {
      setUpdateCategory({
        ...UpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_category"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateCategory({
        ...UpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateCustomerStatusFieldHandler = (key, convert) => e => {
    if (key === "customer_status_text") {
      setUpdateCustomerStatusSubmissionData({
        ...UpdateCustomerStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_customer_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateCustomerStatusSubmissionData({
        ...UpdateCustomerStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };


  const updateContractStatusFieldHandler = (key, convert) => e => {
    if (key === "contract_status_text") {
      setUpdateContractStatusSubmissionData({
        ...UpdateContractStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_contract_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateContractStatusSubmissionData({
        ...UpdateContractStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateJobStatusFieldHandler = (key, convert) => e => {
    if (key === "job_status_text") {
      setUpdateJobStatusSubmissionData({
        ...UpdateJobStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_job_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateJobStatusSubmissionData({
        ...UpdateJobStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateProposalStatusFieldHandler = (key, convert) => e => {
    if (key === "proposal_status_text") {
      setUpdateProposalStatusSubmissionData({
        ...UpdateProposalStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_proposal_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateProposalStatusSubmissionData({
        ...UpdateProposalStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateServiceCategoryFieldHandler = (key, convert) => e => {
    if (key === "category_text") {
      setServiceUpdateCategory({
        ...ServiceUpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_category"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setServiceUpdateCategory({
        ...ServiceUpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const [addCategoryForm] = useMutation(ADD_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setSubmissionData({});
      refetch();
      refetchCategories();
    }
  });

  const [addLeadStatusForm] = useMutation(ADD_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      setLeadStatusSubmissionData({});
      refetch();
      refetchLeadStatus_data();
    }
  });

  const [addCustomerStatusForm] = useMutation(ADD_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      setCustomerStatusSubmissionData({});
      refetch();
      refetchCustomerStatus_data();
    }
  });

  const [addContractStatusForm] = useMutation(ADD_CONTRACT_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      setContractStatusSubmissionData({});
      refetch();
      refetchContractStatus_data();
    }
  });

  const [addJobStatusForm] = useMutation(ADD_JOB_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      setJobStatusSubmissionData({});
      refetch();
      refetchJobStatus_data();
    }
  });

  const [addProposalStatusForm] = useMutation(ADD_PROPOSAL_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      setProposalStatusSubmissionData({});
      refetch();
      refetchProposalStatus_data();
    }
  });

  const [addServiceCategoryForm] = useMutation(ADD_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setServiceSubmissionData({});
      refetch();
      refetchServiceCategories();
    }
  });

  const [UpdateCategoryForm] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchCategories();
    }
  });

  const [UpdateLeadStatusForm] = useMutation(UPDATE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchLeadStatus_data();
    }
  });

  const [UpdateCustomerStatusForm] = useMutation(UPDATE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchCustomerStatus_data();
    }
  });

  const [UpdateContractStatusForm] = useMutation(UPDATE_CONTRACT_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchContractStatus_data();
    }
  });

  const [UpdateMutedNotifications] = useMutation(UPDATE_MUTED_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
    skip: !customer_id || !me?.id,
    onCompleted: () => {
      GetMutedNotifications();
    }
  });

  const [UpdateJobStatusForm] = useMutation(UPDATE_JOB_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchJobStatus_data();
    }
  });

  const [UpdateProposalStatusForm] = useMutation(UPDATE_PROPOSAL_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchProposalStatus_data();
    }
  });

  const [UpdateServiceCategoryForm] = useMutation(UPDATE_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchServiceCategories();
    }
  });

  const [deleteCategoryForm] = useMutation(DELETE_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      refetchCategories();
    }
  });

  const [deleteLeadStatusForm] = useMutation(DELETE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchLeadStatus_data();
    }
  });

  const [deleteCustomerStatusForm] = useMutation(DELETE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchCustomerStatus_data();
    }
  });

  const [deleteContractStatusForm] = useMutation(DELETE_CONTRACT_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchContractStatus_data();
    }
  });

  const [deleteJobStatusForm] = useMutation(DELETE_JOB_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchJobStatus_data();
    }
  });

  const [deleteProposalStatusForm] = useMutation(DELETE_PROPOSAL_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetchProposalStatus_data();
    }
  });

  const handleProductCategoryOpen = row => {
    setAddProductCategory(true);
  };

  const handleUpdateProductCategoryOpen = row => {
    setUpdateProductCategory(true);
  };

  const handleDeleteProductCategoryOpen = row => {
    setDeleteProductCategory(true);
  };

  const handleLeadStatusOpen = row => {
    setAddLeadStatus(true);
  };

  const handleUpdateLeadStatusOpen = row => {
    setUpdateLeadStatus(true);
  };

  const handleDeleteLeadStatusOpen = row => {
    setDeleteLeadStatus(true);
  };

  const handleCustomerStatusOpen = row => {
    setAddCustomerStatus(true);
  };

  const handleUpdateCustomerStatusOpen = row => {
    setUpdateCustomerStatus(true);
  };

  const handleDeleteCustomerStatusOpen = row => {
    setDeleteCustomerStatusDialog(true);
  };

  const handleContractStatusOpen = row => {
    setAddContractStatus(true);
  };

  const handleJobStatusOpen = row => {
    setAddJobStatus(true);
  };

  const handleUpdateContractStatusOpen = row => {
    setUpdateContractStatus(true);
  };

  const handleUpdateJobStatusOpen = row => {
    setUpdateJobStatus(true);
  };

  const handleDeleteContractStatusOpen = row => {
    setDeleteContractStatusDialog(true);
  };

  const handleDeleteJobStatusOpen = row => {
    setDeleteJobStatusDialog(true);
  };

  const handleProposalStatusOpen = row => {
    setAddProposalStatus(true);
  };

  const handleUpdateProposalStatusOpen = row => {
    setUpdateProposalStatus(true);
  };

  const handleDeleteProposalStatusOpen = row => {
    setDeleteProposalStatusDialog(true);
  };

  const handlePersonStatusOpen = row => {
    setAddPersonStatus(true);
  };

  const handleDeletePersonStatusOpen = row => {
    setDeletePersonStatus(true);
  };

  const handleDeletePersonTypeOpen = row => {
    setSelectedDeleteType(true);
  };

  const handleServiceCategoryOpen = row => {
    setAddServiceCategory(true);
  };

  const handleUpdateServiceCategoryOpen = row => {
    setUpdateServiceCategory(true);
  };

  const handleDeleteServiceCategoryOpen = row => {
    setDeleteServiceCategory(true);
  };

  const updateFieldHandler = (key, id) => e => {
    updateMe({
      variables: {
        id: id,
        crm_user_level: e.target.value === "inactive" ? null : e.target.value,
      }
    });

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    refetch();
  };

  const updateFieldPersonStatusHandler = (key, convert) => e => {
    if (key === "add_new_lead") {
      setSubmissionPeopleLeadStatusData({
        ...omit(submissionPeopleLeadStatusData, ["lead_status"]),
        [key]: String(e.target.value)
      });
    } else if (key === "lead_status") {
      setSubmissionPeopleLeadStatusData({
        ...omit(submissionPeopleLeadStatusData, ["add_new_lead"]),
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
    else
      setSubmissionPeopleLeadStatusData({
        ...submissionPeopleLeadStatusData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
  }

  const updateProductCategoryFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateLeadStatusFieldHandler = (key, convert) => e => {
    if (key === "customer_status_text") {
      setLeadStatusSubmissionData({
        ...LeadStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_customer_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setLeadStatusSubmissionData({
        ...LeadStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  }

  const addCustomerStatusFieldHandler = (key, convert) => e => {
    setCustomerStatusSubmissionData({
      ...CustomerStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addContractStatusFieldHandler = (key, convert) => e => {
    setContractStatusSubmissionData({
      ...ContractStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addJobStatusFieldHandler = (key, convert) => e => {
    setJobStatusSubmissionData({
      ...JobStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addProposalStatusFieldHandler = (key, convert) => e => {
    setProposalStatusSubmissionData({
      ...ProposalStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateNewUserFieldHandler = (key) => e => {
    setnewuserSubmission({
      ...newuserSubmission,
      [key]: e.target.value
    });
  };

  const addServiceCategoryFieldHandler = (key, convert) => e => {
    setServiceSubmissionData({
      ...ServicesubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  useEffect(() => {
    setUserInfo(me);
  }, [me]);

  return (
    <div>
      <GridContainer marginTop="20px">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon>
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                LinearCRM User Permissions
                <HelpTooltip>
                  A Standard User has the ability to create and view calendar, activities, leads, people, jobs, customers, photos, documents, contracts, and proposals. They only have the ability to view, products, services, representatives, and reporting.<br /><br />
                  A CRM Editor has the same ablity as Standard User. In addition, they can create products, services, and representatives.<br /><br />
                  A CRM Admin - has the same ablity as CRM Editor. In addition, they can delete people, jobs, customers, products, services, photos, documents, contracts, propsals, and representatives. They also have access to billing and can add or modify user levels.<br />
                </HelpTooltip>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {CRMAssignedToUsers?.map(({ id, first_name, last_name, email, crm_user_level }) => {
                    return (
                      <div style={{ marginTop: "15px" }}>{first_name} {last_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{email}
                        <select
                          id={"crm_user_level" + id}
                          onChange={updateFieldHandler("crm_user_level", id)}
                          value={crm_user_level ? crm_user_level : "select_role"}
                          style={{ float: "right" }}
                        >
                          <option value="inactive">Inactive</option>
                          <option value="crm_admin">CRM Admin</option>
                          <option value="crm_editor">CRM Editor</option>
                          <option value="crm_standard">CRM Standard</option>
                        </select>
                      </div>
                    )
                  })}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>LinearCRM B2B/B2C Setting
                <HelpTooltip>
                  A Standard User has the ability to create and view calendar, activities, leads, people, jobs, customers, photos, documents, contracts, and proposals. They only have the ability to view, products, services, representatives, and reporting.<br /><br />
                  A CRM Editor has the same ablity as Standard User. In addition, they can create products, services, and representatives.<br /><br />
                  A CRM Admin - has the same ablity as CRM Editor. In addition, they can delete people, jobs, customers, products, services, photos, documents, contracts, propsals, and representatives. They also have access to billing and can add or modify user levels.<br />
                </HelpTooltip>
              </h4>
            </CardHeader>
            <CardBody>
              <select
                id={"b2b"}
                onChange={updateB2BFieldHandler("B2B")}
                value={B2B}
                style={{ float: "left" }}
              >
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
              </select>
            </CardBody>
          </Card>
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && B2B === "B2B" && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Leads Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleLeadStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Lead Status
                </Button>
                <Button
                  onClick={() => handleUpdateLeadStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Lead Status
                </Button>
                <Button
                  onClick={() => handleDeleteLeadStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Lead Status
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && B2B === "B2B" && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Customers Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleCustomerStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Customer Status
                </Button>
                <Button
                  onClick={() => handleUpdateCustomerStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Customer Status
                </Button>
                <Button
                  onClick={() => handleDeleteCustomerStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Customer Status
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>People Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handlePersonStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add/Update Person Status/Type
                </Button>
                <Button
                  onClick={() => handleDeletePersonStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Person Status
                </Button>
                <Button
                  onClick={() => handleDeletePersonTypeOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Person Type
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Products Category Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Product Category
                </Button>
                <Button
                  onClick={() => handleUpdateProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Product Category
                </Button>
                <Button
                  onClick={() => handleDeleteProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Product Category
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Services Category Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Service Category
                </Button>
                <Button
                  onClick={() => handleUpdateServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Service Category
                </Button>
                <Button
                  onClick={() => handleDeleteServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Service Category
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Job Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleJobStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Job Status
                </Button>
                <Button
                  onClick={() => handleUpdateJobStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Job Status
                </Button>
                <Button
                  onClick={() => handleDeleteJobStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Job Status
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Contracts Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleContractStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Contract Status
                </Button>
                <Button
                  onClick={() => handleUpdateContractStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Contract Status
                </Button>
                <Button
                  onClick={() => handleDeleteContractStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Contract Status
                </Button>
              </CardBody>
            </Card>
          )}
          {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Propsals Status Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleProposalStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Propsal Status
                </Button>
                <Button
                  onClick={() => handleUpdateProposalStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Propsal Status
                </Button>
                <Button
                  onClick={() => handleDeleteProposalStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Propsal Status
                </Button>
              </CardBody>
            </Card>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardHeader color="rose" icon>
              <CardIcon>
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle} style={{ float: "left" }}>Add CRM User</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="First Name"
                    id="first_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: newuserSubmission?.first_name || "",
                      onChange: updateNewUserFieldHandler("first_name")
                    }}
                  />
                  <CustomInput
                    labelText="Last Name"
                    id="last_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: newuserSubmission?.last_name || "",
                      onChange: updateNewUserFieldHandler("last_name")
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "email",
                      value: newuserSubmission?.email || "",
                      onChange: updateNewUserFieldHandler("email")
                    }}
                  />
                  <CustomInput
                    labelText="Cell Phone Number"
                    id="cell_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: newuserSubmission?.cell_phone || "",
                      onChange: updateNewUserFieldHandler("cell_phone", e => `${e}`)
                    }}
                  />
                  <CustomInput
                    labelText="Birthday"
                    id="birthday"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "date",
                      value:
                        moment(newuserSubmission?.birthday).format("YYYY-MM-DD") || "",
                      onChange: updateNewUserFieldHandler("birthday")
                    }}
                  />
                  <CustomInput
                    labelText="Office Phone Number"
                    id="office_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: newuserSubmission?.office_phone || "",
                      onChange: updateNewUserFieldHandler("office_phone", e => `${e}`)
                    }}
                    value={me?.office_phone}
                  />
                  <CustomInput
                    labelText="Office Extension"
                    id="office_extension"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "integer",
                      value: newuserSubmission?.office_extension || "",
                      onChange: updateNewUserFieldHandler(
                        "office_extension",
                        e => `${e}`
                      )
                    }}
                  />
                  <div style={{ clear: "both", marginTop: "30px" }}></div>
                  <label>LinearCRM User Permissions</label>
                  <select
                    id={"crm_new_user_level"}
                    onChange={updateNewUserFieldHandler("crm_user_level")}
                    value={newuserSubmission?.crm_user_level ? newuserSubmission?.crm_user_level : "select_role"}
                    style={{ float: "right" }}
                  >
                    <option value="inactive">Inactive</option>
                    <option value="crm_admin">CRM Admin</option>
                    <option value="crm_editor">CRM Editor</option>
                    <option value="crm_standard">CRM Standard</option>
                  </select>
                  <div style={{ clear: "both", marginTop: "30px" }}></div>
                </GridItem>
              </GridContainer>
              <Button
                color="orange"
                className={classes.updateProfileButton}
                onClick={() => {
                  if (newuserSubmission?.length === 0)
                    alert("No Changes Detected.");
                  else if (newuserSubmission?.email?.length === 0)
                    alert("New User Email is Required.");
                  else {
                    addUser({
                      skip: !customer_id,
                      variables: {
                        customer_id: customer_id,
                        first_name: newuserSubmission?.first_name,
                        last_name: newuserSubmission?.last_name,
                        email: newuserSubmission?.email,
                        cell_phone: newuserSubmission?.cell_phone?.replace(/[^0-9\-]+/g, ""),
                        commission: null,
                        office_phone: newuserSubmission?.office_phone?.replace(/[^0-9\-]+/g, ""),
                        office_extension: newuserSubmission?.office_extension?.replace(/[^0-9]+/g, ""),
                        user_level: "crm_user",
                        crm_user_level: newuserSubmission?.crm_user_level
                      }
                    });
                  }
                }
                }
              >
                Add CRM User
              </Button>
            </CardBody>
          </Card>
          {AddLeadStatus && (
            <Dialog
              open={Boolean(AddLeadStatus)}
              onClose={() => setAddLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Lead Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={LeadStatusSubmissionData?.customer_status_text}
                  onChange={updateLeadStatusFieldHandler("customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!LeadStatusSubmissionData?.customer_status_text) {
                      alert("Please enter a Lead Status.");
                      return;
                    }

                    if (LeadStatusSubmissionData && LeadStatusSubmissionData?.customer_status_text) {
                      await addLeadStatusForm({
                        variables: {
                          customer_id: customer_id,
                          customer_status_text: LeadStatusSubmissionData?.customer_status_text,
                          status: 0
                        }
                      });

                      setAddLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateLeadStatus && (
            <Dialog
              open={Boolean(UpdateLeadStatus)}
              onClose={() => setUpdateLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Lead Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Lead Status to Update</label>
                <div>
                  <CustomSelect
                    value={LeadStatusSubmissionData?.customer_status_text ? LeadStatusSubmissionData?.customer_status_text : ""}
                    onChange={updateLeadStatusFieldHandler("customer_status_text")}
                    data={LeadStatusData?.map(({ customer_status_text }) => ({
                      value: customer_status_text,
                      label: customer_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Lead Status</label>
                <TextField
                  fullWidth
                  value={LeadStatusSubmissionData?.update_customer_status_text || ""}
                  onChange={updateLeadStatusFieldHandler("update_customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (LeadStatusSubmissionData?.customer_status_text ===
                      LeadStatusSubmissionData?.update_customer_status_text || !LeadStatusSubmissionData?.update_customer_status_text) {
                      alert("Please enter an updated Lead Status.");
                      return;
                    }

                    if (LeadStatusSubmissionData && LeadStatusSubmissionData?.customer_status_text && LeadStatusSubmissionData?.update_customer_status_text) {
                      await UpdateLeadStatusForm({
                        variables: {
                          customer_id: customer_id,
                          customer_status_text: LeadStatusSubmissionData?.customer_status_text,
                          update_customer_status_text: LeadStatusSubmissionData?.update_customer_status_text
                        }
                      });

                      setLeadStatusSubmissionData({});
                      setUpdateLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteLeadStatus && (
            <Dialog
              open={Boolean(DeleteLeadStatus)}
              onClose={() => setDeleteLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Lead Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Lead Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteLeadStatus || ""}
                    onChange={(e) => { setDeleteLeadStatus(e.target.value); }}
                    data={LeadStatusData?.map(({ id, customer_status_text }) => ({
                      value: id,
                      label: customer_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteLeadStatus) {
                      alert("Please choose a Lead Status to delete.");
                      return;
                    }

                    if (DeleteLeadStatus) {
                      deleteLeadStatus(DeleteLeadStatus);

                      setDeleteLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddCustomerStatus && (
            <Dialog
              open={Boolean(AddCustomerStatus)}
              onClose={() => setAddCustomerStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Customer Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={CustomerStatusSubmissionData?.customer_status_text}
                  onChange={addCustomerStatusFieldHandler("customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!CustomerStatusSubmissionData?.customer_status_text) {
                      alert("Please enter a Customer Status.");
                      return;
                    }

                    if (CustomerStatusSubmissionData && CustomerStatusSubmissionData?.customer_status_text) {
                      await addCustomerStatusForm({
                        variables: {
                          customer_id: customer_id,
                          customer_status_text: CustomerStatusSubmissionData?.customer_status_text,
                          status: 1
                        }
                      });

                      setAddCustomerStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateCustomerStatus && (
            <Dialog
              open={Boolean(UpdateCustomerStatus)}
              onClose={() => setUpdateCustomerStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Customer Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Customer Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateCustomerStatusSubmissionData?.customer_status_text ? UpdateCustomerStatusSubmissionData?.customer_status_text : ""}
                    onChange={updateCustomerStatusFieldHandler("customer_status_text")}
                    data={CustomerStatusData?.map(({ customer_status_text }) => ({
                      value: customer_status_text,
                      label: customer_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Customer Status</label>
                <TextField
                  fullWidth
                  value={UpdateCustomerStatusSubmissionData?.update_customer_status_text || ""}
                  onChange={updateCustomerStatusFieldHandler("update_customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateCustomerStatusSubmissionData?.customer_status_text ===
                      UpdateCustomerStatusSubmissionData?.update_customer_status_text || !UpdateCustomerStatusSubmissionData?.update_customer_status_text) {
                      alert("Please enter an updated Customer Status.");
                      return;
                    }

                    if (UpdateCustomerStatusSubmissionData && UpdateCustomerStatusSubmissionData?.customer_status_text && UpdateCustomerStatusSubmissionData?.update_customer_status_text) {
                      await UpdateCustomerStatusForm({
                        variables: {
                          customer_id: customer_id,
                          customer_status_text: UpdateCustomerStatusSubmissionData?.customer_status_text,
                          update_customer_status_text: UpdateCustomerStatusSubmissionData?.update_customer_status_text
                        }
                      });

                      setUpdateCustomerStatusSubmissionData([]);
                      setUpdateCustomerStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteCustomerStatusDialog && (
            <Dialog
              open={Boolean(DeleteCustomerStatusDialog)}
              onClose={() => setDeleteCustomerStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Customer Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Customer Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteCustomerStatus || ""}
                    onChange={(e) => { setDeleteCustomerStatus(e.target.value); }}
                    data={CustomerStatusData?.map(({ id, customer_status_text }) => ({
                      value: id,
                      label: customer_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteCustomerStatus) {
                      alert("Please choose a Customer Status to delete.");
                      return;
                    }

                    if (DeleteCustomerStatus) {
                      deleteCustomerStatus(DeleteCustomerStatus);

                      setDeleteCustomerStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddProductCategory && (
            <Dialog
              open={Boolean(AddProductCategory)}
              onClose={() => setAddProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Product Category
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={submissionData?.add_category}
                  onChange={updateProductCategoryFieldHandler("add_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!submissionData?.add_category) {
                      alert("Please enter a Category Name.");
                      return;
                    }

                    if (submissionData && submissionData?.add_category) {
                      await addCategoryForm({
                        variables: {
                          customer_id: customer_id,
                          add_category: submissionData?.add_category
                        }
                      });

                      setAddProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateProductCategory && (
            <Dialog
              open={Boolean(UpdateProductCategory)}
              onClose={() => setUpdateProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Product Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateCategory?.category_text ? UpdateCategory?.category_text : ""}
                    onChange={updateCategoryFieldHandler("category_text")}
                    data={CategoryData?.map(({ category_text }) => ({
                      value: category_text,
                      label: category_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Category</label>
                <TextField
                  fullWidth
                  value={UpdateCategory?.update_category || ""}
                  onChange={updateCategoryFieldHandler("update_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateCategory?.update_category === UpdateCategory?.category_text || !UpdateCategory?.update_category) {
                      alert("Please enter an updated Category Name.");
                      return;
                    }

                    if (UpdateCategory && UpdateCategory?.update_category && UpdateCategory?.category_text) {
                      await UpdateCategoryForm({
                        variables: {
                          customer_id: customer_id,
                          category_text: UpdateCategory?.category_text,
                          update_category: UpdateCategory?.update_category
                        }
                      });

                      setUpdateCategory("");
                      setUpdateProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteProductCategory && (
            <Dialog
              open={Boolean(DeleteProductCategory)}
              onClose={() => setDeleteProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Product Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteCategory || ""}
                    onChange={(e) => { setDeleteCategory(e.target.value); }}
                    data={CategoryData?.map(({ id, category_text }) => ({
                      value: id,
                      label: category_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteCategory) {
                      alert("Please choose a Category to delete.");
                      return;
                    }

                    if (DeleteCategory) {
                      deleteCategory(DeleteCategory);

                      setDeleteProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddServiceCategory && (
            <Dialog
              open={Boolean(AddServiceCategory)}
              onClose={() => setAddServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Service Category
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ServicesubmissionData?.add_category}
                  onChange={addServiceCategoryFieldHandler("add_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ServicesubmissionData?.add_category) {
                      alert("Please enter a Category Name.");
                      return;
                    }

                    if (ServicesubmissionData && ServicesubmissionData?.add_category) {
                      await addServiceCategoryForm({
                        variables: {
                          customer_id: customer_id,
                          add_category: ServicesubmissionData?.add_category
                        }
                      });

                      setAddServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateServiceCategory && (
            <Dialog
              open={Boolean(UpdateServiceCategory)}
              onClose={() => setUpdateServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Service Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Update</label>
                <div>
                  <CustomSelect
                    value={ServiceUpdateCategory?.category_text ? ServiceUpdateCategory?.category_text : ""}
                    onChange={updateServiceCategoryFieldHandler("category_text")}
                    data={ServiceCategoryData?.map(({ category_text }) => ({
                      value: category_text,
                      label: category_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Category</label>
                <TextField
                  fullWidth
                  value={ServiceUpdateCategory?.update_category || ""}
                  onChange={updateServiceCategoryFieldHandler("update_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (ServiceUpdateCategory?.update_category === ServiceUpdateCategory?.category_text || !ServiceUpdateCategory?.update_category) {
                      alert("Please enter an updated Category Name.");
                      return;
                    }

                    if (ServiceUpdateCategory && ServiceUpdateCategory?.update_category && ServiceUpdateCategory?.category_text) {
                      await UpdateServiceCategoryForm({
                        variables: {
                          customer_id: customer_id,
                          category_text: ServiceUpdateCategory?.category_text,
                          update_category: ServiceUpdateCategory?.update_category
                        }
                      });
                      setServiceUpdateCategory("");
                      setUpdateServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteServiceCategory && (
            <Dialog
              open={Boolean(DeleteServiceCategory)}
              onClose={() => setDeleteServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Service Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Delete</label>
                <div>
                  <CustomSelect
                    value={ServiceDeleteCategory || ""}
                    onChange={(e) => { setServiceDeleteCategory(e.target.value); }}
                    data={ServiceCategoryData?.map(({ id, category_text }) => ({
                      value: id,
                      label: category_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ServiceDeleteCategory) {
                      alert("Please choose a Category to delete.");
                      return;
                    }

                    if (ServiceDeleteCategory) {
                      ServicedeleteCategory(ServiceDeleteCategory);

                      setDeleteServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddJobStatus && (
            <Dialog
              open={Boolean(AddJobStatus)}
              onClose={() => setAddJobStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Job Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={JobStatusSubmissionData?.job_status_text}
                  onChange={addJobStatusFieldHandler("job_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!JobStatusSubmissionData?.job_status_text) {
                      alert("Please enter a Job Status.");
                      return;
                    }

                    if (JobStatusSubmissionData && JobStatusSubmissionData?.job_status_text) {
                      await addJobStatusForm({
                        variables: {
                          customer_id: customer_id,
                          job_status_text: JobStatusSubmissionData?.job_status_text
                        }
                      });

                      setAddJobStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateJobStatus && (
            <Dialog
              open={Boolean(UpdateJobStatus)}
              onClose={() => setUpdateJobStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Job Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Job Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateJobStatusSubmissionData?.job_status_text ? UpdateContractStatusSubmissionData?.job_status_text : ""}
                    onChange={updateJobStatusFieldHandler("job_status_text")}
                    data={JobStatusData?.map(({ job_status_text }) => ({
                      value: job_status_text,
                      label: job_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Job Status</label>
                <TextField
                  fullWidth
                  value={UpdateJobStatusSubmissionData?.update_job_status_text || ""}
                  onChange={updateJobStatusFieldHandler("update_job_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateJobStatusSubmissionData?.job_status_text ===
                      UpdateJobStatusSubmissionData?.update_job_status_text || !UpdateJobStatusSubmissionData?.update_job_status_text) {
                      alert("Please enter an updated Job Status.");
                      return;
                    }

                    if (UpdateJobStatusSubmissionData && UpdateJobStatusSubmissionData?.job_status_text && UpdateJobStatusSubmissionData?.update_job_status_text) {
                      await UpdateJobStatusForm({
                        variables: {
                          customer_id: customer_id,
                          job_status_text: UpdateJobStatusSubmissionData?.job_status_text,
                          update_job_status_text: UpdateJobStatusSubmissionData?.update_job_status_text
                        }
                      });

                      setUpdateJobStatusSubmissionData([]);
                      setUpdateJobStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteJobStatusDialog && (
            <Dialog
              open={Boolean(DeleteJobStatusDialog)}
              onClose={() => setDeleteJobStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Job Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Job Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteJobStatus || ""}
                    onChange={(e) => { setDeleteJobStatus(e.target.value); }}
                    data={JobStatusData?.map(({ id, job_status_text }) => ({
                      value: id,
                      label: job_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteJobStatus) {
                      alert("Please choose a Job Status to delete.");
                      return;
                    }

                    if (DeleteJobStatus) {
                      deleteJobStatus(DeleteJobStatus);

                      setDeleteJobStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddContractStatus && (
            <Dialog
              open={Boolean(AddContractStatus)}
              onClose={() => setAddContractStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Contract Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ContractStatusSubmissionData?.contract_status_text}
                  onChange={addContractStatusFieldHandler("contract_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ContractStatusSubmissionData?.contract_status_text) {
                      alert("Please enter a Contract Status.");
                      return;
                    }

                    if (ContractStatusSubmissionData && ContractStatusSubmissionData?.contract_status_text) {
                      await addContractStatusForm({
                        variables: {
                          customer_id: customer_id,
                          contract_status_text: ContractStatusSubmissionData?.contract_status_text
                        }
                      });

                      setAddContractStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateContractStatus && (
            <Dialog
              open={Boolean(UpdateContractStatus)}
              onClose={() => setUpdateContractStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Contract Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Contract Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateContractStatusSubmissionData?.contract_status_text ? UpdateContractStatusSubmissionData?.contract_status_text : ""}
                    onChange={updateContractStatusFieldHandler("contract_status_text")}
                    data={ContractStatusData?.map(({ contract_status_text }) => ({
                      value: contract_status_text,
                      label: contract_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Contract Status</label>
                <TextField
                  fullWidth
                  value={UpdateContractStatusSubmissionData?.update_contract_status_text || ""}
                  onChange={updateContractStatusFieldHandler("update_contract_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateContractStatusSubmissionData?.contract_status_text ===
                      UpdateContractStatusSubmissionData?.update_contract_status_text || !UpdateContractStatusSubmissionData?.update_contract_status_text) {
                      alert("Please enter an updated Contract Status.");
                      return;
                    }

                    if (UpdateContractStatusSubmissionData && UpdateContractStatusSubmissionData?.contract_status_text && UpdateContractStatusSubmissionData?.update_contract_status_text) {
                      await UpdateContractStatusForm({
                        variables: {
                          customer_id: customer_id,
                          contract_status_text: UpdateContractStatusSubmissionData?.contract_status_text,
                          update_contract_status_text: UpdateContractStatusSubmissionData?.update_contract_status_text
                        }
                      });

                      setUpdateContractStatusSubmissionData([]);
                      setUpdateContractStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteContractStatusDialog && (
            <Dialog
              open={Boolean(DeleteContractStatusDialog)}
              onClose={() => setDeleteContractStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Contract Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Contract Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteContractStatus || ""}
                    onChange={(e) => { setDeleteContractStatus(e.target.value); }}
                    data={ContractStatusData?.map(({ id, contract_status_text }) => ({
                      value: id,
                      label: contract_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteContractStatus) {
                      alert("Please choose a Contract Status to delete.");
                      return;
                    }

                    if (DeleteContractStatus) {
                      deleteContactStatus(DeleteContractStatus);

                      setDeleteContractStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddProposalStatus && (
            <Dialog
              open={Boolean(AddProposalStatus)}
              onClose={() => setAddProposalStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Proposal Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ProposalStatusSubmissionData?.proposal_status_text}
                  onChange={addProposalStatusFieldHandler("proposal_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ProposalStatusSubmissionData?.proposal_status_text) {
                      alert("Please enter a Proposal Status.");
                      return;
                    }

                    if (ProposalStatusSubmissionData && ProposalStatusSubmissionData?.proposal_status_text) {
                      await addProposalStatusForm({
                        variables: {
                          customer_id: customer_id,
                          proposal_status_text: ProposalStatusSubmissionData?.proposal_status_text
                        }
                      });

                      setAddProposalStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateProposalStatus && (
            <Dialog
              open={Boolean(UpdateProposalStatus)}
              onClose={() => setUpdateProposalStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Proposal Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Proposal Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateProposalStatusSubmissionData?.proposal_status_text ? UpdateProposalStatusSubmissionData?.proposal_status_text : ""}
                    onChange={updateProposalStatusFieldHandler("proposal_status_text")}
                    data={ProposalStatusData?.map(({ proposal_status_text }) => ({
                      value: proposal_status_text,
                      label: proposal_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Proposal Status</label>
                <TextField
                  fullWidth
                  value={UpdateProposalStatusSubmissionData?.update_proposal_status_text || ""}
                  onChange={updateProposalStatusFieldHandler("update_proposal_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateProposalStatusSubmissionData?.proposal_status_text ===
                      UpdateProposalStatusSubmissionData?.update_proposal_status_text || !UpdateProposalStatusSubmissionData?.update_proposal_status_text) {
                      alert("Please enter an updated Proposal Status.");
                      return;
                    }

                    if (UpdateProposalStatusSubmissionData && UpdateProposalStatusSubmissionData?.proposal_status_text && UpdateProposalStatusSubmissionData?.update_proposal_status_text) {
                      await UpdateProposalStatusForm({
                        variables: {
                          customer_id: customer_id,
                          proposal_status_text: UpdateProposalStatusSubmissionData?.proposal_status_text,
                          update_proposal_status_text: UpdateProposalStatusSubmissionData?.update_proposal_status_text
                        }
                      });

                      setUpdateProposalStatusSubmissionData([]);
                      setUpdateProposalStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteProposalStatusDialog && (
            <Dialog
              open={Boolean(DeleteProposalStatusDialog)}
              onClose={() => setDeleteProposalStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Proposal Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Proposal Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteProposalStatus || ""}
                    onChange={(e) => { setDeleteProposalStatus(e.target.value); }}
                    data={ProposalStatusData?.map(({ id, proposal_status_text }) => ({
                      value: id,
                      label: proposal_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteProposalStatus) {
                      alert("Please choose a Proposal Status to delete.");
                      return;
                    }

                    if (DeleteProposalStatus) {
                      deleteProposalStatus(DeleteProposalStatus);

                      setDeleteProposalStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            open={Boolean(AddPersonStatus)}
            onClose={() => setAddPersonStatus(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Add Custom Lead Type and Status for People
            </DialogTitle>
            <DialogContent>
              <div>
                <lable>Select Lead Type</lable><CustomSelect
                  value={submissionPeopleLeadStatusData?.lead_type ? submissionPeopleLeadStatusData?.lead_type : ""}
                  onChange={updateFieldPersonStatusHandler("lead_type")}
                  data={defaultLeadTypes}
                />
              </div>
              {!submissionPeopleLeadStatusData?.lead_type || submissionPeopleLeadStatusData?.lead_type === -1 ?
                <div>
                  <lable>Or Add Custom Lead Type</lable>
                  <TextField
                    fullWidth
                    onChange={updateFieldPersonStatusHandler("lead_type_text")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                </div> : ""}
              <div>
                <label>Select Lead Status</label><CustomSelect
                  value={submissionPeopleLeadStatusData?.lead_status ? submissionPeopleLeadStatusData?.lead_status : ""}
                  onChange={updateFieldPersonStatusHandler("lead_status")}
                  data={defaultLeadStatuses}
                />
              </div>
              <label>Add Custom Lead Status</label><TextField
                fullWidth
                value={submissionPeopleLeadStatusData?.add_new_lead ? submissionPeopleLeadStatusData?.add_new_lead : ""}
                onChange={updateFieldPersonStatusHandler("add_new_lead")}
                inputProps={{
                  className: classes.textField
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={async (e) => {
                  e.preventDefault();

                  let ReturnedPromise = '';

                  if (!submissionPeopleLeadStatusData?.add_new_lead && !submissionPeopleLeadStatusData?.lead_status) {
                    alert("Lead Status Required.  Please choose or add a Lead Status.");
                    return;
                  } else if (!submissionPeopleLeadStatusData?.lead_type_text && (!submissionPeopleLeadStatusData?.lead_type || submissionPeopleLeadStatusData?.lead_type === -1)) {
                    alert("Lead Type Required.  Please choose or add a Lead Type.");
                    return;
                  }
                  if (submissionPeopleLeadStatusData) {
                    ReturnedPromise = await addPeopleLeadStatusForm({
                      variables: {
                        customer_id: customer_id,
                        data: submissionPeopleLeadStatusData
                      }
                    });

                    if (ReturnedPromise.data.addLeadStatusHydrate !== 0) {
                      updateCampaign({
                        variables: {
                          data: {
                            customer_id,
                            list_name: ReturnedPromise.data.addLeadStatusHydrate,
                            sms_text: 1,
                            mms_text: 1,
                            email: 1,
                            postcard: 1,
                            letter: 1,
                            voice_message: 1,
                            sms_text_day: 1,
                            mms_text_day: 5,
                            email_day: 3,
                            letter_day: 11,
                            postcard_day: 9,
                            voice_message_day: 7,
                            frequency_template: 3
                          }
                        }
                      });
                    }

                    setAddPersonStatus(null);
                    setSubmissionPeopleLeadStatusData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(DeletePersonStatus)}
            onClose={() => setDeletePersonStatus(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Delete Custom Lead Statuses
            </DialogTitle>
            <DialogContent>
              Select Lead Status to Delete<CustomSelect
                value={deleteData?.lead_status ? deleteData?.lead_status : ""}
                onChange={deleteFieldHandler("lead_status")}
                data={defaultLeadCustomerStatuses}
                deleteable={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!deleteData?.lead_status) {
                    alert("Lead Status Required.  Please choose a Lead Status to Delete");
                    return;
                  }
                  if (deleteData) {
                    DeleteLeadStatusForm({
                      variables: {
                        customer_id: customer_id,
                        data: deleteData
                      }
                    });

                    setDeletePersonStatus(false);
                    setDeleteData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(deleteType)}
            onClose={() => setSelectedDeleteType(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Delete Custom Lead Types
            </DialogTitle>
            <DialogContent>
              Select Lead Type to Delete<CustomSelect
                value={deleteData?.lead_type ? deleteData?.lead_type : ""}
                onChange={deleteFieldHandler("lead_type")}
                data={defaultLeadCustomerTypes}
                deleteable={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!deleteData?.lead_type_text && (!deleteData?.lead_type || deleteData?.lead_type === -1)) {
                    alert("Lead Type Required.  Please choose a Lead Type to Delete");
                    return;
                  }
                  if (deleteData) {
                    DeleteLeadStatusForm({
                      variables: {
                        customer_id: customer_id,
                        data: deleteData
                      }
                    });

                    setSelectedDeleteType(null);
                    setDeleteData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Switch
                checked={MutedNotifications?.length}
                onChange={async () => {
                  await UpdateMutedNotifications({
                    variables: {
                      customer_id: customer_id,
                      user_id: me?.id
                    }
                  });
                }}
                color="primary"
                name="budget_optimization"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />&nbsp;&nbsp;Mute Notifications
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withSubmissions(CRMSettings);