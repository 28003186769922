import React from "react";

import AccountOverview from "views/Dashboard/AccountOverview";
import AddAccount from "views/Dashboard/Accounts/AddAccount";
import Chat from "views/Dashboard/Chat";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import OutOfHome from "views/Dashboard/OutOfHome";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import UpdateAccount from "views/Dashboard/Accounts/UpdateAccount";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Awareness from "views/Dashboard/Awareness";
import Commissions from "views/Dashboard/Commissions";
import CPMCalculator from "views/Dashboard/CPMCalculator";
import MarketingGoals from "views/Dashboard/MarketingGoals";
import UpdateMarketingGoal from "views/Dashboard/UpdateMarketingGoal";
import TrackedLeads from "views/Dashboard/TrackedLeads";
import ParaDocuments from "views/Dashboard/ParaDocuments"
import TrackingCodes from "views/Dashboard/TrackingCodes";
import TrackingCodeMaker from "views/Dashboard/TrackingCodeMaker";
import AdvertisingConsultant from "views/Dashboard/AdConsultant";
import AgencyConsultant from "views/Dashboard/AgencyConsultant";
import CampaignConsultant from "views/Dashboard/CampaignConsultant";
import SupportTeam from "views/Dashboard/SupportTeam";
import UserRegistration from "views/Dashboard/Users/AddUser";
import PaidSearchCampaignAdSchedules from "views/Dashboard/PaidSearchCampaignAdSchedules";
import GoogleCampaigns from "views/Dashboard/GoogleCampaigns";
import CampaignBudgets from "views/Dashboard/CampaignBudgets";
import CreateTicket from "views/Dashboard/CreateTicket";
import OrganicSearch from "views/Dashboard/OrganicSearch";
import HeatMap from "views/Dashboard/HeatMap";
import MarketingMaterial from "views/Dashboard/MarketingMaterial";
import UpdateOrganicSearch from "views/Dashboard/UpdateOrganicSearch";
import WriteReview from "views/Dashboard/WriteReview";
import ReferFriend from "views/Dashboard/ReferFriend";
import BingCampaigns from "views/Dashboard/BingCampaigns";
import ErrorPage from "views/Pages/ErrorPage";
import ExtendedForms from "views/Forms/ExtendedForms";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage";
import LandingPage from "views/Pages/LandingPage";
import LoginPage from "views/Pages/LoginPage";
import ResetPasswordPage from "views/Pages/ResetPasswordPage";
import UserProfile from "views/Pages/UserProfile";
import Notifications from "views/Dashboard/Notifications";
import Payment from "views/Dashboard/Payment";
import RegularForms from "views/Forms/RegularForms";
import ValidationForms from "views/Forms/ValidationForms";
import Wizard from "views/Forms/Wizard";
import CreateBudget from "views/Dashboard/CreateBudget";
import UpdateBudget from "views/Dashboard/UpdateBudget";
import MyAccounts from "views/Dashboard/MyAccounts";
import OfflineCalltrack from "views/Dashboard/OfflineCalltrack";
import SocialAds from "views/Dashboard/SocialAds";
import Tutorials from "views/Dashboard/Tutorials";
import CRMSettings from "views/Pages/CRMSettings";
import CRMDataStructures from "views/Pages/CRMDataStructures";
import CRMTableofContents from "views/Pages/CRMTableofContents";
import LastestUpdates from "views/Pages/LastestUpdates";
import CustomerList from "views/Dashboard/CustomerList";
import Representatives from "views/Dashboard/CustomerManagement/Representatives";
import CustomerManagement from "views/Dashboard/CustomerManagement";
import CRMCustomerDetail from "views/Dashboard/CustomerManagement/CRMCustomerDetail";
import CRMLeadDetail from "views/Dashboard/CustomerManagement/CRMLeadDetail";
import CRMPersonDetail from "views/Dashboard/CustomerManagement/CRMPersonDetail";
import OnBoardingForm from "views/Dashboard/CustomerManagement/OnBoardingForm";
import CRMJobDetail from "views/Dashboard/CustomerManagement/CRMJobDetail";
import CRMPhotoDetail from "views/Dashboard/CustomerManagement/CRMPhotoDetail";
import CRMDocumentDetail from "views/Dashboard/CustomerManagement/CRMDocumentDetail";
import CRMContractDetail from "views/Dashboard/CustomerManagement/CRMContractDetail";
import CRMEstimateDetail from "views/Dashboard/CustomerManagement/CRMEstimateDetail";
import CRMProposalDetail from "views/Dashboard/CustomerManagement/CRMProposalDetail";
import CRMActivityDetail from "views/Dashboard/CustomerManagement/CRMActivityDetail";
import HydrateSummary from "views/Dashboard/Hydrate/Summary";
import HydrateAssets from "views/Dashboard/Hydrate/Assets";
import HydrateAssetTemplates from "views/Dashboard/Hydrate/AssetTemplates";
import GoogleLSA from "views/Dashboard/GoogleLSA";
import MyTickets from "views/Dashboard/MyTickets";
import Tickets from "views/Dashboard/Tickets";
import KeywordTool from "views/Dashboard/KeywordTool";

// @mui/icons-material
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallIcon from "@mui/icons-material/Call";
import ChatIcon from "@mui/icons-material/Chat";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CodeIcon from "@mui/icons-material/Code";
import ContactsIcon from "@mui/icons-material/Contacts";
import CreateIcon from "@mui/icons-material/Create";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupIcon from "@mui/icons-material/Group";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Image from "@mui/icons-material/Image";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SearchIcon from "@mui/icons-material/Search";
import TonalityIcon from "@mui/icons-material/Tonality";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TvIcon from "@mui/icons-material/Tv";
import UpdateIcon from "@mui/icons-material/Update";
import VideoIcon from "@mui/icons-material/Videocam";
import WebIcon from "@mui/icons-material/Web";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMma';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EvStationIcon from '@mui/icons-material/EvStation';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DescriptionIcon from '@mui/icons-material/Description';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkIcon from '@mui/icons-material/Work';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import HandymanIcon from '@mui/icons-material/Handyman';
import TaskIcon from '@mui/icons-material/Task';
import ArticleIcon from '@mui/icons-material/Article';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import UploadFileSharp from '@mui/icons-material/UploadFileSharp';

var dashRoutes = [
  {
    collapse: true,
    name: "Tool Bag",
    icon: BusinessCenterIcon,
    state: "toolBeltCollapse",
    permissions: [
      "master",
      "campaign_consultant",
      "ad_consultant",
      "agency",
      "agency_consultant",
      "paid_ad_manager",
      "content_manager",
      "agency_customer",
      "web_designer",
      "customer"
    ],
    views: [
      {
        path: "/tracking-info",
        name: "Tracking Info",
        mini: "TC",
        component: TrackingCodes,
        layout: "/admin",
        icon: CodeIcon,
        permissions: ["customer", "master", "campaign_consultant", "ad_consultant", "paid_ad_manager", "content_manager", "web_designer"]
      },
      {
        path: "/mytickets",
        name: "My Tickets",
        icon: ConfirmationNumberIcon,
        component: MyTickets,
        layout: "/admin",
        permissions: ["master", "campaign_consultant", "ad_consultant", "paid_ad_manager", "content_manager", "web_designer"]
      },
      {
        path: "/tickets",
        name: "Tickets",
        icon: CalendarTodayIcon,
        component: Tickets,
        layout: "/admin",
        permissions: ["master", "content_manager", "web_designer"]
      },
      {
        path: "/create-ticket",
        name: "Create Ticket",
        mini: "RC",
        component: CreateTicket,
        layout: "/admin",
        icon: EmojiObjectsIcon,
        permissions: ["master", "campaign_consultant", "ad_consultant", "paid_ad_manager", "content_manager", "web_designer"]
      },
      {
        path: "/commissions",
        name: "Commissions",
        mini: "C",
        component: Commissions,
        layout: "/admin",
        icon: AttachMoneyIcon,
        permissions: [
          "master"
        ]
      },
      {
        path: "/new-account",
        name: "Add Account/Contact",
        mini: "AA",
        component: AddAccount,
        icon: AddIcon,
        layout: "/admin",
        permissions: ["master", "campaign_consultant"]
      },
      {
        path: "/update-account",
        name: "Edit Account/Contact",
        mini: "EA",
        component: UpdateAccount,
        layout: "/admin",
        icon: EditIcon,
        permissions: ["master"]
      },
      {
        path: "/dashboard-users",
        name: "Dashboard Users",
        mini: "DU",
        component: UserRegistration,
        icon: PersonAddIcon,
        layout: "/admin",
        permissions: ["master", "campaign_consultant"]
      },
      {
        path: "/add-product",
        name: "Add New Product",
        mini: "AP",
        component: CreateBudget,
        layout: "/admin",
        icon: AddShoppingCartIcon,
        permissions: ["master", "campaign_consultant"]
      },
      {
        path: "/update-budget",
        name: "Update Budget",
        mini: "UB",
        component: UpdateBudget,
        icon: UpdateIcon,
        layout: "/admin",
        permissions: ["master", "campaign_consultant", "ad_consultant"]
      },
      {
        name: "Update Organic Search",
        icon: PlaylistAddIcon,
        component: UpdateOrganicSearch,
        layout: "/admin",
        path: "/update-organic-search",
        permissions: ["master"]
      },
      {
        path: "/cpm-calculator",
        name: "CPM Calculator",
        mini: "CC",
        component: CPMCalculator,
        layout: "/admin",
        icon: DynamicFeedIcon,
        permissions: ["master", "campaign_consultant", "paid_ad_manager", "content_manager", "web_designer"]
      },
      {
        path: "/tracking-code-maker",
        name: "Tracking Code Maker",
        mini: "TM",
        component: TrackingCodeMaker,
        layout: "/admin",
        icon: DeveloperModeIcon,
        permissions: ["master",
          "campaign_consultant",
          "ad_consultant",
          "agency",
          "agency_consultant",
          "paid_ad_manager",
          "content_manager",
          "agency_customer",
          "web_designer"]
      },
      {
        path: "/keyword-tool",
        name: "Keyword Tool",
        mini: "KT",
        component: KeywordTool,
        layout: "/admin",
        permissions: ["master",
          "campaign_consultant",
          "ad_consultant",
          "agency",
          "agency_consultant",
          "paid_ad_manager",
          "content_manager",
          "agency_customer",
          "web_designer"],
        icon: SportsMartialArtsIcon
      }
    ]
  },
  {
    collapse: true,
    name: "Accounts",
    icon: AssessmentIcon,
    state: "accountCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/my-accounts",
        name: "My Accounts",
        mini: "MA",
        component: MyAccounts,
        layout: "/admin",
        icon: AccountBalanceIcon,
        permissions: ["master", "paid_ad_manager", "ad_consultant"]
      },
      {
        path: "/account-overview",
        name: "Summary",
        mini: "AC",
        component: AccountOverview,
        layout: "/admin",
        icon: ListIcon
        // permissions: ["master", "campaign_consultant"],
      },
      {
        path: "/campaign-budgets",
        name: "Optimizations",
        mini: "S",
        icon: EvStationIcon,
        // eslint-disable-next-line react/display-name
        component: CampaignBudgets,
        layout: "/admin",
        permissions: ["master", "paid_ad_manager"],
      }
    ]
  },
  {
    collapse: true,
    name: "Goals",
    icon: EmojiEventsIcon,
    state: "goalsCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant"
    ],
    views: [
      {
        path: "/marketing-goals",
        name: "Marketing Goals",
        mini: "MG",
        component: MarketingGoals,
        layout: "/admin",
        icon: DoneIcon
      },
      {
        path: "/update-marketing-goal",
        name: "Update Goals",
        mini: "UG",
        component: UpdateMarketingGoal,
        layout: "/admin",
        icon: EditIcon
      }
    ]
  },
  {
    collapse: true,
    name: "Conversions",
    icon: TonalityIcon,
    state: "leadsCollapse",
    views: [
      {
        path: "/tracked-conversions",
        name: "Tracked Conversions",
        mini: "TL",
        component: TrackedLeads,
        layout: "/admin",
        icon: TrendingUpIcon
      }
    ]
  },
  {
    collapse: true,
    name: "PaidSearch",
    icon: SearchIcon,
    state: "searchCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/paid-search",
        name: "Summary",
        mini: "S",
        icon: ListIcon,
        // eslint-disable-next-line react/display-name
        component: () => <div><AccountOverview type="paid-search" /><PaidSearchCampaignAdSchedules /></div>,
        permissions: ["master", "paid_ad_manager", "campaign_consultant", "content_manager", "web_designer", "ad_consultant", "agency", "agency_customer", "customer"],
        layout: "/admin"
      },
      {
        path: "/google-campaign",
        name: "Google",
        mini: "G",
        component: GoogleCampaigns,
        layout: "/admin"
      },
      {
        path: "/bing-campaign",
        name: "Bing",
        mini: "B",
        component: BingCampaigns,
        layout: "/admin"
      },
      {
        name: "Google LSA",
        icon: LocationOnIcon,
        component: GoogleLSA,
        layout: "/admin",
        path: "/google-lsa",
        permissions: ["master", "ad_consultant", "campaign_consultant", "paid_ad_manager", "content_manager", "web_designer", "agency_customer", "customer"]
      }
    ]
  },
  {
    name: "OrganicSearch",
    collapse: true,
    icon: HowToRegIcon,
    state: "organicSearchCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/organic-search",
        name: "Reports",
        mini: "S",
        icon: ListIcon,
        component: OrganicSearch,
        layout: "/admin"
      },
      {
        path: "/heat-maps",
        name: "Heat Maps",
        mini: "S",
        icon: WhatshotIcon,
        // eslint-disable-next-line react/display-name
        component: HeatMap,
        layout: "/admin"
      },
      {
        name: "Chat",
        icon: ChatIcon,
        component: Chat,
        layout: "/admin",
        path: "/chat",
        permissions: [
          "master",
          "campaign_consultant",
          "ad_consultant",
          "paid_ad_manager",
          "customer",
          "agency",
          "agency_customer",
          "content_manager",
          "web_designer"
        ]
      }
    ]
  },
  {
    collapse: true,
    name: "Banner Ads",
    icon: WebIcon,
    state: "displayCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/banner-ads",
        name: "Summary",
        mini: "S",
        icon: ListIcon,
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="Awareness,GeoIntention,ReEngage"
            type_filter="DISPLAY"
            title="Banner Ads"
            campaignsTitle="All Banner Ad Campaigns"
            adsTitle="View Targeted Banner Ads"
            productLine="banner_ads_geo_demo:BannerAds | GeoDemo®,banner_ads_geo_intention:BannerAds | GeoIntention®,banner_ads_reengage:BannerAds | ReEngage®"
            hideAdsLinks
            hideAdsDetail
            product="banner_ads"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/geo-demo",
        name: "GeoDemo®",
        mini: "GD",
        permissions: [
          "master",
          "web_designer",
          "ad_consultant",
          "content_manager",
          "customer",
          "agency",
          "agency_consultant",
          "agency_customer",
          "campaign_consultant",
          "ad_consultant"
        ],
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="Awareness"
            type_filter="DISPLAY"
            title="Banner Ads GeoDemo®"
            productLine="banner_ads_geo_demo"
            campaignsTitle="All Banner Ads GeoDemo® Campaigns"
            adsHeading="Links to Banner Ads GeoDemo® Assets"
            adsTitle="View Banner GeoDemo® Ads"
            product="banner_ads"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/geo-intention",
        name: "GeoIntention®",
        mini: "GI",
        permissions: [
          "master",
          "web_designer",
          "ad_consultant",
          "content_manager",
          "customer",
          "agency",
          "agency_consultant",
          "agency_customer",
          "campaign_consultant",
          "ad_consultant"
        ],
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="GeoIntention"
            type_filter="DISPLAY"
            title="Banner Ads GeoIntention®"
            productLine="banner_ads_geo_intention"
            campaignsTitle="All Banner Ads GeoIntention® Campaigns"
            adsHeading="Links to Banner Ads GeoIntention® Assets"
            adsTitle="View Banner GeoIntention® Ads"
            product="banner_ads"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/re-engage",
        name: "ReEngage®",
        mini: "RE",
        permissions: [
          "master",
          "web_designer",
          "ad_consultant",
          "content_manager",
          "customer",
          "agency",
          "agency_consultant",
          "agency_customer",
          "campaign_consultant",
          "ad_consultant"
        ],
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="ReEngage"
            type_filter="DISPLAY"
            title="Banner Ads ReEngage®"
            productLine="banner_ads_reengage"
            campaignsTitle="All Banner Ads ReEngage® Campaigns"
            adsHeading="Links to Banner Ads ReEngage® Assets"
            adsTitle="View Banner ReEngage® Ads"
            product="banner_ads"
          />
        ),
        layout: "/admin"
      }
    ]
  },
  {
    name: "SocialAds",
    icon: HowToRegIcon,
    component: SocialAds,
    layout: "/admin",
    path: "/social-ads",
    state: "socialAdsCollapse",
    ignore: true
  },
  {
    collapse: true,
    name: "OnlineVideo",
    icon: VideoIcon,
    state: "videoCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/video",
        name: "Summary",
        mini: "S",
        icon: ListIcon,
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="Awareness,GeoIntention,ReEngage"
            type_filter="VIDEO"
            title="Video Ads"
            campaignsTitle="All Video Ad Campaigns"
            productLine="video_ads_geo_demo:OnlineVideo | GeoDemo®,video_ads_geo_intention:OnlineVideo | GeoIntention®,video_ads_reengage:OnlineVideo | ReEngage®"
            hideAdsLinks
            hideAdsDetail
            isVideo
            product="video_ads_geo_demo"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/video-geo-demo",
        name: "GeoDemo®",
        mini: "GD",
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="Awareness"
            type_filter="VIDEO"
            title="GeoDemo® Video"
            campaignsTitle="All Video GeoDemo® Ads Campaigns"
            productLine="video_ads_geo_demo"
            adsGroupTitle="Video Ad Group Breakdown"
            adsTitle="View Video GeoDemo® Ads"
            isVideo
            product="video_ads_geo_demo"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/video-geo-intention",
        name: "GeoIntention®",
        mini: "GI",
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="GeoIntention"
            type_filter="VIDEO"
            title="GeoIntention® Video"
            campaignsTitle="All Video GeoIntention® Ads Campaigns"
            productLine="video_ads_geo_intention"
            adsTitle="View Video GeoIntention® Ads"
            isVideo
            product="video_ads_geo_demo"
          />
        ),
        layout: "/admin"
      },
      {
        path: "/video-re-engage",
        name: "ReEngage®",
        mini: "RE",
        // eslint-disable-next-line
        component: () => (
          <Awareness
            filter="ReEngage"
            type_filter="VIDEO"
            title="ReEngage® Video"
            campaignsTitle="All Video ReEngage® Ads Campaigns"
            productLine="video_ads_reengage"
            adsTitle="View Video ReEngage® Ads"
            isVideo
            product="video_ads_geo_demo"
          />
        ),
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Hydrate",
    icon: ContactMailIcon,
    state: "hydrateCollapse",
    views: [
      {
        path: "/hydrate-summary",
        name: "Summary",
        mini: "S",
        icon: ListIcon,
        component: HydrateSummary,
        layout: "/admin"
      },
      {
        path: "/hydrate-assets",
        name: "Assets",
        mini: "A",
        icon: WebAssetIcon,
        component: HydrateAssets,
        layout: "/admin"
      },
      {
        path: "/hydrate-assets-templates",
        name: "Asset Templates",
        mini: "A",
        icon: WebAssetIcon,
        component: HydrateAssetTemplates,
        layout: "/admin",
        permissions: ["master", "content_manager", "web_designer"]
      },
      {
        path: "/customer-list",
        name: "Customer List",
        mini: "CL",
        component: CustomerList,
        layout: "/admin",
        icon: MailOutlineIcon
      },
      {
        path: "/representativeshydrate",
        name: "Representatives",
        mini: "CL",
        component: Representatives,
        layout: "/admin",
        icon: AssignmentIndIcon,
        permissions: ["master"]
      }
    ]
  },
  {
    collapse: true,
    name: "LinearCRM",
    state: "customermanagementCollapse",
    icon: AccountCircleIcon,
    permissions: ["master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant",
      "crm_user"],
    views: [
      {
        path: "/calendar",
        name: "Calendar",
        mini: "CL",
        component: Representatives,
        layout: "/admin",
        icon: CalendarTodayIcon,
        permissions: ["master"]
      },
      {
        path: "/activity",
        name: "Activity",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: AccountTreeIcon
      },
      {
        path: "/leads",
        name: "Leads",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: AssistantPhotoIcon
      },
      {
        path: "/crmcustomers",
        name: "Customers",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: HomeWorkIcon
      },
      {
        path: "/crmcustomerdetail",
        component: CRMCustomerDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmleaddetail",
        component: CRMLeadDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmpersondetail",
        component: CRMPersonDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/onboardingform",
        name: "Onboarding Form",
        mini: "OF",
        component: OnBoardingForm,
        layout: "/admin",
        permissions: ["master", "web_designer", "ad_consultant", "content_manager", "customer"],
        icon: AddToQueueIcon,
        ignore: true
      },
      {
        path: "/crmjobdetail",
        component: CRMJobDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmphotodetail",
        component: CRMPhotoDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmdocumentdetail",
        component: CRMDocumentDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmcontractdetail",
        component: CRMContractDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmestimatedetail",
        component: CRMEstimateDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmproposaldetail",
        component: CRMProposalDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crmactivitydetail",
        component: CRMActivityDetail,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/people",
        name: "People",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: PersonIcon
      },
      {
        path: "/jobs",
        name: "Jobs",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: WorkIcon
      },
      {
        path: "/products",
        name: "Products",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: Inventory2Icon
      },
      {
        path: "/services",
        name: "Services",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: HandymanIcon
      },
      {
        path: "/photo-gallery",
        name: "Photos",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: PhotoLibraryIcon
      },
      {
        path: "/documents",
        name: "Documents",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: UploadFileSharp
      },
      {
        path: "/contracts",
        name: "Contracts",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: TaskIcon
      },
      {
        path: "/proposals",
        name: "Proposals",
        mini: "CL",
        component: CustomerManagement,
        layout: "/admin",
        icon: ArticleIcon
      },
      {
        path: "/reporting",
        name: "Reporting",
        mini: "CL",
        component: Representatives,
        layout: "/admin",
        icon: AssessmentIcon,
        permissions: ["master"]
      },
      {
        path: "/representatives",
        name: "Representatives",
        mini: "CL",
        component: Representatives,
        layout: "/admin",
        icon: AssignmentIndIcon
      }
    ]
  },
  {
    name: "Out of Home",
    icon: TvIcon,
    component: OutOfHome,
    layout: "/admin",
    path: "/out-of-home",
    state: "outofhomeCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
  },
  {
    name: "OfflineTracking",
    icon: CallIcon,
    component: OfflineCalltrack,
    layout: "/admin",
    path: "/offline-tracking",
    state: "offlineCallTrackCollapse",
    permissions: [
      "master",
      "web_designer",
      "ad_consultant",
      "content_manager",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
  },
  {
    collapse: true,
    name: "My Team",
    icon: GroupIcon,
    state: "teamCollapse",
    permissions: [
      "master",
      "customer",
      "agency",
      "agency_consultant",
      "agency_customer",
      "campaign_consultant",
      "ad_consultant"
    ],
    views: [
      {
        path: "/advertising-consultant",
        name: "Advertising Consultant",
        mini: "AC",
        component: AdvertisingConsultant,
        layout: "/admin",
        icon: PeopleIcon,
        permissions: ["master", "customer", "agency", "agency_consultant"]
      },
      {
        path: "/campaign-consultant",
        name: "Campaign Consultant",
        mini: "CC",
        component: CampaignConsultant,
        layout: "/admin",
        icon: ContactsIcon,
        permissions: ["master", "customer", "agency", "agency_consultant"]
      },
      {
        path: "/support-team",
        name: "Support Team",
        mini: "ST",
        component: SupportTeam,
        layout: "/admin",
        icon: ChatBubbleIcon,
        permissions: [
          "master",
          "customer",
          "agency_customer",
          "agency",
          "agency_consultant",
          "campaign_consultant",
          "ad_consultant"
        ]
      },
      {
        path: "/contact-agency",
        name: "Contact PARA",
        mini: "CA",
        component: AgencyConsultant,
        layout: "/admin",
        icon: InvertColorsIcon,
        permissions: ["master", "agency_customer"]
      }
    ]
  },
  {
    name: "Para Resources",
    collapse: true,
    icon: FindInPageIcon,
    state: "paraResourcesCollapse",
    permissions: [
      "master",
      "campaign_consultant",
      "ad_consultant",
      "paid_ad_manager",
      "agency",
      "content_manager",
      "web_designer"
    ],
    views: [
      {
        path: "/paradocuments",
        name: "Documents",
        mini: "S",
        icon: DescriptionIcon,
        component: ParaDocuments,
        layout: "/admin"
      },
      {
        path: "/marketing-material",
        name: "Marketing Material",
        mini: "S",
        icon: CardTravelIcon,
        component: MarketingMaterial,
        layout: "/admin",
        permissions: ["master", "content_manager", "web_designer"]
      },
      {
        name: "Brand Standards",
        icon: LockIcon,
        component: () => {
          window.open("https://waterbearmarketing.com/brand-standards/", "_blank")
          window.location.href = '/admin/account-overview';
          return null;
        },
        layout: "/admin",
        path: '/brand-standards'
      }
    ]
  },
  {
    name: "Review",
    icon: CreateIcon,
    component: WriteReview,
    layout: "/admin",
    path: "/write-review",
    state: "reviewCollapse"
  },
  {
    name: "Refer Friend",
    icon: HowToRegIcon,
    component: ReferFriend,
    layout: "/admin",
    path: "/sweet-alert",
    state: "referFriendCollapse"
  },
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    hidden: true,
    views: [
      {
        path: "/landing-page",
        name: "Landing Page",
        mini: "PP",
        component: LandingPage,
        layout: "/auth"
      },
      {
        path: "/login",
        name: "Login Page",
        mini: "L",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/forgot-password",
        name: "Forgot Password Page",
        mini: "F",
        component: ForgotPasswordPage,
        layout: "/auth"
      },
      {
        path: "/resetpassword",
        name: "Reset Password Page",
        mini: "R",
        component: ResetPasswordPage,
        layout: "/auth"
      },
      {
        path: "/error-page",
        name: "Error Page",
        mini: "E",
        component: ErrorPage,
        layout: "/auth"
      },
      {
        path: "/notifications",
        name: "Notifications Page",
        mini: "N",
        component: Notifications,
        layout: "/admin",
        permissions: ["master", "campaign_consultant", "paid_ad_manager", "content_manager", "web_designer"]
      },
      {
        path: "/profile",
        name: "Profile Page",
        mini: "P",
        component: UserProfile,
        layout: "/admin"
      },
      {
        path: "/payment",
        name: "Payment Profile",
        mini: "P",
        component: Payment,
        layout: "/admin"
      },
      {
        path: "/tutorials",
        name: "Tutorials",
        mini: "T",
        component: Tutorials,
        layout: "/admin"
      },
      {
        path: "/crm_settings",
        name: "LinearCRM Settings",
        mini: "T",
        component: CRMSettings,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crm_data_structures",
        name: "LinearCRM Data Structures",
        mini: "T",
        component: CRMDataStructures,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/crm_table_of_contents",
        name: "LinearCRM Table of Contents",
        mini: "T",
        component: CRMTableofContents,
        layout: "/admin",
        ignore: true
      },
      {
        path: "/latestupdates",
        name: "Latest Updates",
        mini: "T",
        component: LastestUpdates,
        layout: "/admin",
        ignore: true
      }
    ]
  },
  {
    collapse: true,
    name: "Forms",
    icon: "content_paste",
    state: "formsCollapse",
    permissions: ["master"],
    hidden: true,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",

        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin"
      }
    ]
  }
];
export default dashRoutes;
