import React from "react";
import Select from "components/Select/Select";
import Button from '@mui/material/Button';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";

const AddUpdateNote = (CRMActivitySubmission, updateActivityFieldHandler, CRMAssignedToUsers, CRMRepresentative, updateActivityDatabase) => {
  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>Log A Note</label>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <RichTextEditor updateFieldHandler={updateActivityFieldHandler} current={CRMActivitySubmission} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px" }} />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <label style={{ marginTop: "8px", width: "125px", float: "left" }}>Assign To:</label>
      <Select
        options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
          key: id,
          label: first_name + " " + last_name
        }))}
        selected={CRMActivitySubmission?.assigned_to || 0}
        onSelect={updateActivityFieldHandler("assigned_to")}
        style={{ marginBottom: 20, float: "left", marginRight: "100px", height: "40px" }}
      />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => updateActivityDatabase("Note")}>
        Save
      </Button>
    </div>
  )
};

export default AddUpdateNote;
