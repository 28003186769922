import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";


import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import CustomSelect from "components/Select/Select";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from "@mui/material/ListItemText";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import CustomCheckbox from "components/CustomCheckbox";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import UploadInput from "components/UploadInput";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import { SUBMIT_CREATIVE_FORM } from "queries/formSubmission";
import { SELECT_CUSTOMER } from "../../../redux/constants";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

import { GET_CUSTOMER_PRODUCTS } from "queries/formSubmission";

const useStyles = makeStyles(styles);

const budgetProducts = [
  "Campaign Review",
  "Reporting Request",
  "Call Tracking Issue",
  "Campaign Performance Issue",
  "Redesign Creative",
  "Budget Review",
  "Website Edit",
  "Promotion Update",
  "Cancellation",
  "Other"
];

const products = [
  { label: "BannerAds", key: "creative_bannerads" },
  { label: "SocialAds", key: "socialads" },
  { label: "OnlineVideo", key: "creative_onlinevideo" },
  { label: "Hydrate", key: "creative_hydrate" },
  { label: "Ouf of Home", key: "creative_out_of_home" },
];

const promotion_products = [
  { label: "PaidSearch - Text Ads", key: "paidsearch_text_ads", default_user: "1" },
  { label: "PaidSearch - Ad Extensions", key: "paidsearch_ad_extensions", default_user: "1" },
  { label: "BannerAds", key: "promotion_bannerads", default_user: "1" },
  { label: "OnlineVideo", key: "promotion_onlinevideo", default_user: "1" },
  { label: "Change Existing Website Content", key: "new_website_content_pages", default_user: "111" },
  { label: "Blog Content", key: "blog_content", default_user: "103" },
  { label: "Website Coupons", key: "website_coupons", default_user: "111" },
  { label: "Hydrate", key: "promotion_hydrate", default_user: "1" },
  { label: "Ouf of Home", key: "promotion_out_of_home", default_user: "1" },
];

const CreateTicket = ({ data = null, formSubmitted, onCompleted }) => {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [customer_id, setcustomer_id] = useState(38);
  const [submissionData, setSubmissionData] = useState({});
  const [uploadPercentage, setuploadPercentage] = useState("");
  const navigate = useNavigate();
  const customers = state.customers?.data;

  let { loading, data: product_data, refetch } = useQuery(GET_CUSTOMER_PRODUCTS, {
    skip: !customer_id,
    variables: { customerId: customer_id }
  });

  product_data = product_data?.getCustomerProducts || [];

  useEffect(() => {
    if (!customers?.length) {
      return;
    }
    let value = null;
    if (customer_id) {
      value = Number(customer_id);
    } else {
      value = Number(customers[0].id);
    }
    dispatch({
      type: SELECT_CUSTOMER,
      payload: value
    });
  }, [customers, customer_id]);

  useEffect(() => {
    if (data) {
      setSubmissionData(data);
    }
  }, [data]);

  if (state.customers?.selected?.name && !submissionData?.customer_name) {
    setSubmissionData({ ...submissionData, customer_name: state.customers?.selected?.name });
  }

  const [submitCreativeForm, { loading: submitting }] = useMutation(
    SUBMIT_CREATIVE_FORM,
    {
      variables: {
        customerId: customer_id,
        data: omit(submissionData, ["completed", "__typename", "user_submitted", "submitted_by",
          "completed_by", "user_completed", "completed_time", "assigned_to_time",
          "closed_by_time", "campaign_review_assigned_user", "reporting_request_assigned_user",
          "call_tracking_issue_assigned_user", "campaign_performance_issue_assigned_user",
          "update_creative_assigned_user", "budget_review_assigned_user",
          "website_edit_assigned_user", "other_assigned_user",
          "promotion_out_of_home_assigned_user", "promotion_hydrate_assigned_user",
          "social_media_post_assigned_user", "promotion_onlinevideo_assigned_user",
          "new_website_content_pages_assigned_user", "blog_content_assigned_user",
          "cancellation_assigned_user",
          "promotion_bannerads_assigned_user", "paidsearch_ad_extensions_assigned_user",
          "paidsearch_text_ads_assigned_user", "website_coupons_assigned_user",
          "campaign_review_assignment_completed_time", "reporting_request_assignment_completed_time",
          "call_tracking_issue_assignment_completed_time", "campaign_performance_issue_assignment_completed_time",
          "update_creative_assignment_completed_time", "budget_review_assignment_completed_time",
          "website_edit_assignment_completed_time", "other_assignment_completed_time",
          "promotion_bannerads_completed_time", "paidsearch_text_ads_completed_time",
          "paidsearch_ad_extensions_completed_time", "promotion_onlinevideo_completed_time",
          "promotion_hydrate_completed_time", "promotion_out_of_home_completed_time",
          "website_coupons_completed_time",
          "social_media_post_assignment_completed_time", "new_website_content_pages_assignment_completed_time",
          "blog_content_assignment_completed_time", "cancellation_assignment_completed_time"])
      },
      onCompleted: (data) => {
        setSubmissionData({});
        formSubmitted();
        if (onCompleted) {
          onCompleted();
          return true;
        } else
          return false;
      }
    }
  );

  const updateFieldHandler = (key, convert) => e => {
    if (key == "issue_description") {
      submissionData.campaign_performance_issue_assigned_user = null;
      submissionData.campaign_performance_issue_assignment = null;
      submissionData.campaign_review_assigned_user = null;
      submissionData.campaign_review_assignment = null;
      submissionData.call_tracking_issue_assigned_user = null;
      submissionData.call_tracking_issue_assignment = null;
      submissionData.budget_review_assigned_user = null;
      submissionData.budget_review_assignment = null;
      submissionData.reporting_request_assigned_user = null;
      submissionData.reporting_request_assignment = null;
      submissionData.update_creative_assigned_user = null;
      submissionData.update_creative_assignment = null;
      submissionData.website_edit_assigned_user = null;
      submissionData.website_edit_assignment = null;
      submissionData.other_assigned_user = null;
      submissionData.other_assignment = null;
      submissionData.promotion_bannerads_assigned_user = null;
      submissionData.promotion_bannerads_assignment = null;
      submissionData.paidsearch_text_ads_assigned_user = null;
      submissionData.paidsearch_text_ads_assignment = null;
      submissionData.paidsearch_ad_extensions_assigned_user = null;
      submissionData.paidsearch_ad_extensions_assignment = null;
      submissionData.promotion_onlinevideo_assigned_user = null;
      submissionData.promotion_onlinevideo_assignment = null;
      submissionData.promotion_hydrate_assigned_user = null;
      submissionData.promotion_hydrate_assignment = null;
      submissionData.promotion_out_of_home_assigned_user = null;
      submissionData.promotion_out_of_home_assignment = null;
      submissionData.website_coupons_assigned_user = null;
      submissionData.website_coupons_assignment = null
      submissionData.social_media_post_assigned_user = null;
      submissionData.social_media_post_assignment = null;
      submissionData.new_website_content_pages_assigned_user = null;
      submissionData.new_website_content_pages_assignment = null;
      submissionData.blog_content_assigned_user = null;
      submissionData.blog_content_assignment = null;
      submissionData.cancellation_assigned_user = null;
      submissionData.cancellation_assignment = null;

      // set preassigned users

      if (e.target.value.indexOf("Campaign Review") > -1)
        submissionData.campaign_review_assignment = 1;
      if (e.target.value.indexOf("Reporting Request") > -1)
        submissionData.reporting_request_assignment = 1;
      if (e.target.value.indexOf("Call Tracking Issue") > -1)
        submissionData.call_tracking_issue_assignment = 1;
      if (e.target.value.indexOf("Campaign Performance Issue") > -1)
        submissionData.campaign_performance_issue_assignment = 1;
      if (e.target.value.indexOf("Redesign Creative") > -1)
        submissionData.update_creative_assignment = 1;
      if (e.target.value.indexOf("Budget Review") > -1)
        submissionData.budget_review_assignment = 1;
      if (e.target.value.indexOf("Website Edit") > -1)
        submissionData.website_edit_assignment = 111;
      if (e.target.value.indexOf("Cancellation") > -1)
        submissionData.cancellation_assignment = 1;

    }
    if (key === "creative_bannerads" || key === "socialads" ||
      key === "creative_onlinevideo" || key === "creative_hydrate" ||
      key === "creative_out_of_home" || key === "paidsearch_text_ads" ||
      key === "paidsearch_ad_extensions" || key === "promotion_bannerads" ||
      key === "promotion_onlinevideo" ||
      key === "website_coupons" || key === "promotion_hydrate" ||
      key === "promotion_out_of_home" || key === "social_media_post" ||
      key === "new_website_content_pages" || key === "blog_content"
    ) {
      if (key === "paidsearch_text_ads")
        submissionData.paidsearch_text_ads_assignment = 1;
      else if (key === "paidsearch_ad_extensions")
        submissionData.paidsearch_ad_extensions_assignment = 1;
      else if (key === "promotion_bannerads")
        submissionData.promotion_bannerads_assignment = 5;
      else if (key === "promotion_onlinevideo")
        submissionData.promotion_onlinevideo_assignment = 1;
      else if (key === "website_coupons")
        submissionData.website_coupons_assignment = 101;
      else if (key === "promotion_hydrate")
        submissionData.promotion_hydrate_assignment = 5;
      else if (key === "promotion_out_of_home")
        submissionData.promotion_out_of_home_assignment = 5;
      else if (key === "social_media_post")
        submissionData.social_media_post_assignment = 103;
      else if (key === "new_website_content_pages")
        submissionData.new_website_content_pages_assignment = 103;
      else if (key === "blog_content")
        submissionData.blog_content_assignment = 103;
      setSubmissionData({
        ...submissionData,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else {
      if (key === "campaign_review_assignment" || key === "reporting_request_assignment" ||
        key === "call_tracking_issue_assignment" || key === "campaign_performance_issue_assignment" ||
        key === "update_creative_assignment" || key === "budget_review_assignment" ||
        key === "website_edit_assignment" || key === "other_assignment" ||
        key === "promotion_bannerads_assignment" || key === "paidsearch_text_ads_assignment" ||
        key === "paidsearch_ad_extensions_assignment" || key === "promotion_onlinevideo_assignment" ||
        key === "promotion_hydrate_assignment" || key === "promotion_out_of_home_assignment" ||
        key === "social_media_post_assignment" || key === "new_website_content_pages_assignment" ||
        key === "blog_content_assignment" || key === "cancellation_assignment" ||
        key === "website_coupons_assignment") {
        setSubmissionData({
          ...submissionData,
          [key]: parseInt(e.target.value)
        });
      }
      else {
        setSubmissionData({
          ...submissionData,
          [key]: convert ? convert(e.target.value) : e.target.value
        });
      }
    }
  };

  const LinearDeterminate = ({ uploadPercentage }) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <LinearProgress variant="determinate" value={uploadPercentage.percent} />
        <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
          uploadPercentage.percent,
        )}% Please wait for the upload to complete.` : ""}</Typography>
      </div>
    );
  }

  const targetingTypesSelector = product => {
    const fieldName = `${product.toLowerCase()}_targeting_types`;
    return (
      submissionData &&
      (submissionData.issue_description || [])?.indexOf(product) !== -1 && (
        <>
          <CustomInput
            name="creative_message"
            labelText="Creative Message"
            id="creative_message"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            inputProps={{
              type: "string",
              onChange: updateFieldHandler("creative_message"),
              value: submissionData["creative_message"] || "",
              multiline: true,
              rows: 5,
            }}
          />
          <label
            className={classes.selectFormControl} >
            Start Date of Promotion</label><br />
          <CustomInput
            name="creative_start"
            id="creative_start"
            formControlProps={{
              required: true
            }}
            inputProps={{
              type: "date",
              value: submissionData["creative_start"] || "",
              onChange: updateFieldHandler("creative_start")
            }}
          /><br />
          <label
            className={classes.selectFormControl} >
            End Date of Promotion</label><br />
          <CustomInput
            name="creative_end"
            id="creative_end"
            formControlProps={{
              required: true
            }}
            inputProps={{
              type: "date",
              value: submissionData["creative_end"] || "",
              onChange: updateFieldHandler("creative_end")
            }}
          />

          <CustomInput
            name="landing_page"
            labelText="Landing Page"
            id="landing_page"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            inputProps={{
              type: "string",
              onChange: updateFieldHandler("landing_page"),
              value: submissionData["landing_page"] || ""
            }}
          />
          <br />
          <br />
          <label
            className={classes.selectFormControl} ><b>
              Choose Where <p style={{ color: 'red', display: 'inline' }}>Redesign Creative</p> should be visible</b></label>
          {products.map(({ key, label }) => (
            <FormControl
              fullWidth
              style={{ "display": "inline" }}
              className={classes.selectFormControl}
              key={key}
            >
              <div>
                <Checkbox
                  value={submissionData[key] || 0}
                  style={{ "width": "20px" }}
                  onChange={updateFieldHandler(key)}
                  inputProps={{
                    name: key,
                    id: key
                  }}
                />
                &nbsp;&nbsp;{label}
              </div>
            </FormControl>
          ))}
        </>
      )
    );
  };


  const customersMapped = customers?.map(({ id, name, status }) => ({
    key: id,
    label: name,
    fontStyle: status === 2 ? "italic" : ""
  }));

  const content = (
    <form
      style={{ width: "700px" }}
      onSubmit={e => {
        e.preventDefault();
        let ticket_id = submitCreativeForm();
        ticket_id.then(result => navigate("/admin/tickets?ticket_id=" + result.data.submitCreativeForm)).catch(err => console.log(err));
      }
      }
    >
      <label
        className={classes.selectFormControl} >
        Customer&nbsp;</label>
      <CustomSelect
        id="customer"
        options={customersMapped}
        selected={customer_id}
        onSelect={value => {
          dispatch({ type: SELECT_CUSTOMER, payload: value });
          setcustomer_id(value);
          setSubmissionData({ ...omit(submissionData, ["customer_name"]) });
        }}
        style={{ marginLeft: 30, marginBottom: 20 }}
      /><br /><br />
      <label
        className={classes.selectFormControl} >
        Priority *&nbsp;</label>
      <Select
        name="priority_level"
        id="priority_level"
        required={true}
        MenuProps={{
          className: classes.selectMenu
        }}
        inputProps={{
          type: "string",
          onChange: updateFieldHandler("priority_level"),
          value: submissionData["priority_level"] || ""
        }}
      >
        <MenuItem value={1}>Low (Earliest Convenience)</MenuItem>
        <MenuItem value={2}>Medium (4 - 5 Days)</MenuItem>
        <MenuItem value={3}>High (2 - 3 Days)</MenuItem>
        <MenuItem value={4}>Emergency (24 Hours)</MenuItem>
      </Select><br /><br />
      <label
        className={classes.selectFormControl}
      > Requested Completion Date *</label><CustomInput
        name="requested_completion_date"
        id="requested_completion_date"
        formControlProps={{
          required: true
        }}
        inputProps={{
          type: "date",
          onChange: updateFieldHandler("requested_completion_date"),
          value: submissionData?.requested_completion_date || ""
        }}
      />
      <FormControl fullWidth className={classes.selectFormControl}>
        <label
          htmlFor="simple-select"
          className={classes.selectLabel}
        >What do you want? {" "}
        </label>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.chrisHelp
          }}
          multiple
          required={true}
          input={<Input />}
          renderValue={selected => selected.join(", ")}
          onChange={updateFieldHandler("issue_description")}
          inputProps={{
            name: "issue_description",
            id: "issue_description",
            value: (typeof submissionData?.issue_description === 'string' || submissionData?.issue_description instanceof String) ? submissionData?.issue_description?.split(",").map((description) => { return description }) || [] : (typeof submissionData?.issue_description !== 'undefined') ? submissionData?.issue_description || [] : []
          }}
        >
          {budgetProducts.map(name => (

            <MenuItem key={name} value={name}>
              <CustomCheckbox
                checked={
                  submissionData?.issue_description?.indexOf(name) >
                  -1
                }
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {submissionData?.issue_description?.indexOf("Campaign Review") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Campaign Review:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}><select
            id="campaign_review_assignment"
            required={true}
            onChange={updateFieldHandler("campaign_review_assignment")}
            value={submissionData?.campaign_review_assignment ? submissionData?.campaign_review_assignment : "1"}
          >
            <option value="">Assign Task</option>
            <option value="1">Nate</option>

          </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Reporting Request") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Reporting Request:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="reporting_request_assignment"
              required={true}
              onChange={updateFieldHandler("reporting_request_assignment")}
              value={submissionData?.reporting_request_assignment ? submissionData?.reporting_request_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Call Tracking Issue") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Call Tracking Issue:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="call_tracking_issue_assignment"
              required={true}
              onChange={updateFieldHandler("call_tracking_issue_assignment")}
              value={submissionData?.call_tracking_issue_assignment ? submissionData?.call_tracking_issue_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Campaign Performance Issue") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Campaign Performance Issue:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="campaign_performance_issue_assignment"
              required={true}
              onChange={updateFieldHandler("campaign_performance_issue_assignment")}
              value={submissionData?.campaign_performance_issue_assignment ? submissionData?.campaign_performance_issue_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Redesign Creative") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Redesign Creative:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="update_creative_assignment"
              required={true}
              onChange={updateFieldHandler("update_creative_assignment")}
              value={submissionData?.update_creative_assignment ? submissionData?.update_creative_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Budget Review") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Budget Review:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="budget_review_assignment"
              required={true}
              onChange={updateFieldHandler("budget_review_assignment")}
              value={submissionData?.budget_review_assignment ? submissionData?.budget_review_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Website Edit") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Website Edit:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="website_edit_assignment"
              required={true}
              onChange={updateFieldHandler("website_edit_assignment")}
              value={submissionData?.website_edit_assignment ? submissionData?.website_edit_assignment : "111"}
            >
              <option value="">Assign Task</option>
              <option value="111">Mackenzie</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Promotion Update") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Promotion Update:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>Assigned to Multiple Water Bears (below)</div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Cancellation") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Cancellation:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="cancellation_assignment"
              required={true}
              onChange={updateFieldHandler("cancellation_assignment")}
              value={submissionData?.cancellation_assignment ? submissionData?.cancellation_assignment : "1"}
            >
              <option value="">Assign Task</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {submissionData?.issue_description?.indexOf("Other") > -1 ?
        <div><br /><div style={{ "display": "inline-block" }}>Assign Other:&nbsp;&nbsp;</div>
          <div style={{ "float": "right" }}>
            <select
              id="other_assignment"
              required={true}
              onChange={updateFieldHandler("other_assignment")}
              value={submissionData?.other_assignment ? submissionData?.other_assignment : ""}
            >
              <option value="">Assign Task</option>
              <option value="101">Andrew</option>
              <option value="103">Danielle</option>
              <option value="111">Mackenzie</option>
              <option value="163">Marlo</option>
              <option value="1">Nate</option>
            </select></div>
        </div> : ""}
      {targetingTypesSelector("Redesign Creative")}
      {submissionData?.issue_description?.indexOf("Promotion Update") > -1 ?
        <div>
          <br />
          <label
            className={classes.selectFormControl} ><b>
              Choose Where <p style={{ color: 'red', display: 'inline' }}>Promotion</p> should be visible</b></label>
          {promotion_products.map(({ key, label, default_user }) => (
            <FormControl
              style={{ "display": "inline" }}
              fullWidth
              key={key}
            >
              <div>
                <Checkbox
                  value={submissionData[key] || 0}
                  style={{ "width": "20px" }}
                  onChange={updateFieldHandler(key)}
                  inputProps={{
                    name: key,
                    id: key
                  }}
                />
                &nbsp;&nbsp;{label}
              </div>
              <div><div style={{ "display": "inline-block" }}>Assign {label}:&nbsp;&nbsp;</div>
                <div style={{ "float": "right" }}>
                  {label === "PaidSearch - Text Ads" ||
                    label === "PaidSearch - Ad Extensions" ||
                    label === "BannerAds" ||
                    label === "OnlineVideo" ||
                    label === "Hydrate" ||
                    label === "Ouf of Home" ?
                    <select
                      onChange={updateFieldHandler(key + "_assignment")}
                      value={submissionData[key + "_assignment"] || default_user}
                      inputProps={{
                        name: key + "_assignment",
                        id: key + "_assignment"
                      }}
                    >
                      <option value="">Assign Task</option>
                      <option value="1">Nate</option>
                    </select>
                    : label === "Change Existing Website Content" ?
                      <select
                        onChange={updateFieldHandler(key + "_assignment")}
                        value={submissionData[key + "_assignment"] || default_user}
                        inputProps={{
                          name: key + "_assignment",
                          id: key + "_assignment"
                        }}
                      >
                        <option value="">Assign Task</option>
                        <option value="111">Mackenzie</option>
                      </select>
                      : label === "Blog Content" ?
                        <select
                          onChange={updateFieldHandler(key + "_assignment")}
                          value={submissionData[key + "_assignment"] || default_user}
                          inputProps={{
                            name: key + "_assignment",
                            id: key + "_assignment"
                          }}
                        >
                          <option value="">Assign Task</option>
                          <option value="103">Danielle</option>
                        </select>
                        : label === "Website Coupons" ?
                          <select
                            onChange={updateFieldHandler(key + "_assignment")}
                            value={submissionData[key + "_assignment"] || default_user}
                            inputProps={{
                              name: key + "_assignment",
                              id: key + "_assignment"
                            }}
                          >
                            <option value="">Assign Task</option>
                            <option value="111">Mackenzie</option>
                          </select>
                          :
                          <select
                            onChange={updateFieldHandler(key + "_assignment")}
                            value={submissionData[key + "_assignment"] || default_user}
                            inputProps={{
                              name: key + "_assignment",
                              id: key + "_assignment"
                            }}
                          >
                            <option value="">Assign Task</option>
                            <option value="101">Andrew</option>
                            <option value="103">Danielle</option>
                            <option value="111">Mackenzie</option>
                            <option value="1">Nate</option>
                          </select>
                  }
                </div>
              </div>
              <br />
            </FormControl>
          ))}
        </div> : ""
      }
      <br />
      <br />
      <UploadInput
        path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
        onChange={value => updateFieldHandler("link_to_assets")({ target: { value } })}
        value={submissionData["link_to_assets"] || ""}
        label="Link to Assets"
        sublabel="*Zip files before uploading"
        isImage={false}
        setuploadPercentage={setuploadPercentage}
      />
      <LinearDeterminate uploadPercentage={uploadPercentage} />
      <br />
      <label
        className={classes.selectFormControl} >Website</label>
      <CustomInput
        name="website"
        id="website"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: "string",
          onChange: updateFieldHandler("website"),
          value: submissionData["website"] || customers?.filter(CurrentCustomer => CurrentCustomer?.id === customer_id)?.map(CurrentCustomer => CurrentCustomer?.website)
        }}
      />
      <br />
      <br />
      <CustomInput
        name="notes"
        labelText="Notes"
        id="notes"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: "string",
          onChange: updateFieldHandler("notes"),
          value: submissionData["notes"] || "",
          multiline: true,
          rows: 5,
        }}
      />
      <Button color="primary" type="submit">
        Submit
      </Button>
    </form >
  );

  if (data) {
    return (
      <GridContainer loading={submitting || !customer_id} style={{ padding: 20 }}>
        <h2 className={classes.pageHeader} style={{ padding: 20 }}>
          Update Ticket
        </h2>
        {content}
      </GridContainer>
    );
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>
        Create a New Ticket
      </h2><h5>Customer is using (with Monthly Budget): {product_data} </h5>
      <GridContainer loading={submitting || !customer_id}>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            {content}
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(CreateTicket);
