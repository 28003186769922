import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

import ArchiveIcon from '@mui/icons-material/Archive';

const CSVDownloader = ({ data, columns, filename, incomingstyle, text = null }) => {
  const csvColumns = columns.filter(({ noCSV }) => !noCSV);

  const csvData = [
    csvColumns.map(({ Header }) => Header),
    ...data?.map(row => {
      return csvColumns.map(({ accessor, Cell, renderCSV }) => {
        if (renderCSV || Cell) {
          return (renderCSV || Cell)({ original: row });
        }
        return row[accessor];
      });
    })
  ];

  return (
    <CSVLink
      data={csvData}
      filename={`${filename}.csv`}
      style={ incomingstyle ? { incomingstyle } : {float: "right", margin: 0 } }
    >
      {text ? text : ""}&nbsp;<ArchiveIcon />
    </CSVLink>
  );
};

CSVDownloader.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  filename: PropTypes.string
};

export default CSVDownloader;
