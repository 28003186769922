/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import ArchiveIcon from '@mui/icons-material/Archive';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// core components
import Button from "components/CustomButtons/Button";
import Select from "components/Select/Select";
import MultiSelect from "@mui/material/Select";
import CustomInput from "components/CustomInput/CustomInput";
import Input from '@mui/material/Input';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Card from "components/Card/Card";
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import {
  LIST_TRACKED_LEADS_CALLS,
  UPDATE_TRACKED_CALL
} from "queries/trackedLeads";
import { LIST_CUSTOMER_EMAILS, UPDATE_CUSTOMER_EMAIL, GET_PRODUCT_CATEGORIES, LIST_PRODUCT } from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";
import { READ_ME } from "queries/users";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = {
  ...formStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const leadQualities = [
  { label: "Good", key: 1 },
  { label: "Bad", key: 2 },
  { label: "Other", key: 3 }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setPhone, setSource, setCity, setState, setDevice, setRings } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns?.map((column) => (
          <TableCell
            key={column.length}
            sortDirection={orderBy === column.accessor ? order : false}
            align={'center'}
            style={{ verticalAlign: "top" }}
          >
            {column.Header !== "Select" && column.Header !== "Download" && column.Header !== "Player" && column.Header !== "Start Time" && column.Header !== "Call Duration" ?
              <>
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
              </>
              :
              <>
                {column.Header === "Select" && (
                  <Tooltip title="*Rate multiple calls that have the same result at the same time." placement="top">{column.Header}</Tooltip>
                )}
                {column.Header !== "Select" && (
                  column.Header
                )}
              </>}
            <br />
            {column.Header !== "Select" && column.Header !== "Rate Call" && column.Header !== "Download" && column.Header !== "Player" && column.Header !== "Start Time" && column.Header !== "Call Duration" ?
              <Input style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                column.Header === "Customer Name" ? setName(event.target.value) :
                  column.Header === "Customer Phone" ? setPhone(event.target.value) : column.Header === "Source" ? setSource(event.target.value) :
                    column.Header === "City" ? setCity(event.target.value) : column.Header === "State" ? setState(event.target.value) :
                      column.Header === "Device" ? setDevice(event.target.value) : column.Header === "Rings To" ? setRings(event.target.value) :
                        setDevice(event.target.value)
              }}></Input> : ""}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const CallRecording = ({ offline, filter, formSubmitted }) => {
  const [state] = useContext(Context);
  const [selectedCall, setSelectedCall] = useState(null);
  const [MultiSelectvalue, setMultiSelectvalue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('start_time');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [city, setCity] = React.useState('');
  const [customerstate, setState] = React.useState('');
  const [device, setDevice] = React.useState('');
  const [source, setSource] = React.useState('');
  const [rings, setRings] = React.useState('');
  const [CategoryData, setCategoryData] = React.useState(null);
  const [Calldata, setCalldata] = React.useState([]);
  const [ProductData, setProductData] = React.useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [customers, setCustomers] = useState();
  const customer_id = state.customers?.selected?.id;
  const dateRangeString = state.customers?.dateRangeString;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: call_data, refetch } = useQuery(LIST_TRACKED_LEADS_CALLS, {
    fetchPolicy: "no-cache",
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      filter: offline ? `search=${filter}` : "",
      keywords: 2,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: name ? name : "",
      phone: phone ? phone : "",
      city: city ? city : "",
      state: customerstate ? customerstate : "",
      device: device ? device : "",
      source: source ? source : "",
      rings: rings ? rings : "",
      count: 0
    }
  });

  const { data: budgetData } = useQuery(GET_BUDGET, {
    fetchPolicy: "no-cache",
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
      product_line: "hydrate"
    }
  });

  const { data: Category_Data } = useQuery(GET_PRODUCT_CATEGORIES, {
    fetchPolicy: "no-cache",
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });


  const { data: Product_Data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { refetch: refetchCustomerEmail } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables:
    {
      customer_id,
      count: 0
    }
  });

  let { data: all_data } = useQuery(LIST_TRACKED_LEADS_CALLS, {
    fetchPolicy: "no-cache",
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      filter: offline ? `search=${filter}` : "",
      keywords: 2,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: name ? name : "",
      phone: phone ? phone : "",
      city: city ? city : "",
      state: customerstate ? customerstate : "",
      device: device ? device : "",
      source: source ? source : "",
      rings: rings ? rings : "",
      count: 1
    }
  });

  all_data = all_data?.listTrackedLeadsCalls || [];

  let { data: keywordata } = useQuery(LIST_TRACKED_LEADS_CALLS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      keywords: 1,
      filter: offline ? `search=${filter}` : ""
    },
    onCompleted: () => {
      setPage(0);
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: true
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let defaultLeadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      defaultLeadStatuses.push({ label: obj.lead_status_text, key: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let defaultLeadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      defaultLeadTypes.push({ label: obj.lead_type_text, key: obj.lead_type })
    })
  }

  const updateFieldHandler = (key) => e => {
    const value = e?.target ? e?.target.value : e;
    setSelectedCall({
      ...selectedCall,
      [key]: value
    });
  };

  const updateSelectFieldHandler = (key, id, e) => {
    if (e?.target.checked) {
      setMultiSelectvalue({
        ...MultiSelectvalue,
        [key]: MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect + "," + id : id
      });
    } else {
      setMultiSelectvalue({
        ...MultiSelectvalue,
        [key]: MultiSelectvalue?.multiselect?.indexOf(",") ? MultiSelectvalue?.multiselect?.replace("," + id, "")?.replace(id, "")?.replace(",,", ",") : ""
      });
    }
  };

  const leadType = selectedCall?.lead_type;

  const [updateCall, { loading: updatingCall }] = useMutation(
    UPDATE_TRACKED_CALL,
    {
      variables: {
        id: selectedCall?.call_id,
        customer_id,
        lead_status: parseInt(selectedCall?.lead_status),
        value: Number(selectedCall?.value),
        note: selectedCall?.note,
        tags: [...(selectedCall?.tags || []), `type_${leadType}`],
        lead_type: selectedCall?.lead_type,
        lead_quality: selectedCall?.lead_quality,
        products: selectedCall?.products && typeof selectedCall?.products !== 'string' ? selectedCall?.products?.map((SelectedProduct) => { const ReturnedValue = ProductData?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : selectedCall?.products ? selectedCall?.products : "",
        categories: selectedCall?.categories && typeof selectedCall?.categories !== 'string' ? selectedCall?.categories?.map((SelectedCategory) => { const ReturnedValue = CategoryData?.map((CurrentCategory) => { if (SelectedCategory === CurrentCategory?.category_text) { return CurrentCategory?.id } })?.filter(CurrentCategory => CurrentCategory !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : selectedCall?.categories ? selectedCall?.categories : "",
        multiselect: MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect : null
      },
      onCompleted: () => {
        setMultiSelectvalue('');
        formSubmitted();
        refetch();
      }
    }
  );

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = customers.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setCustomers(newCustomers);
      }
    }
  );

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
    refetch();
  };

  const classes = useStyles();

  keywordata = keywordata?.listTrackedLeadsCalls || [];

  useEffect(() => {
    setCategoryData(Category_Data?.listProductCategories || []);
    setProductData(Product_Data?.ListProduct || []);
    setCalldata(call_data?.listTrackedLeadsCalls || []);
  }, [Category_Data, Product_Data, call_data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenEdit = async (row) => {
    let CustomerEmail = await refetchCustomerEmail({
      customer_id,
      cell_phone_number: row?.formatted_customer_phone_number ? row?.formatted_customer_phone_number?.replaceAll("-", "") : ""
    })

    if (CustomerEmail?.data?.listCustomerEmails?.length > 0) {
      setUpdatedCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0] || {});
      setCurrentCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0]?.id ? CustomerEmail?.data?.listCustomerEmails[0] : null);
    } else {
      setUpdatedCustomerEmail({
        first_name: row?.formatted_customer_name?.split(" ")[0],
        last_name: row?.formatted_customer_name?.split(" ")[1],
        cell_phone_number: row?.formatted_customer_phone_number?.replaceAll("-", ""),
        Callid: row?.id
      });
      setCurrentCustomerEmail({
        first_name: row?.formatted_customer_name?.split(" ")[0],
        last_name: row?.formatted_customer_name?.split(" ")[1],
        cell_phone_number: row?.formatted_customer_phone_number?.replaceAll("-", ""),
        Callid: row?.id
      });
    }
  };

  let columns = ""

  const Keywordcolumns = [
    {
      Header: "Keyword",
      accessor: "keywords"
    }
  ];

  columns = [
    {
      Header: "Select",
      noCSV: true,
    },
    {
      Header: "Rate Call",
      accessor: "lead_type",
      noCSV: true,
    },
    {
      Header: "Customer Name",
      accessor: "formatted_customer_name",
      id: "formatted_customer_name"
    },
    {
      Header: "Customer Phone",
      accessor: "formatted_customer_phone_number",
      id: "formatted_customer_phone_number"
    },
    {
      Header: "City",
      accessor: "customer_city"
    },
    {
      Header: "State",
      accessor: "customer_state"
    },
    {
      Header: "Call Duration",
      accessor: "formatted_duration"
    },
    {
      Header: "Start Time",
      accessor: "start_time"
    },
    {
      Header: "Device Type",
      accessor: "device_type"
    },
    {
      Header: "Source",
      accessor: "formatted_tracking_source"
    },
    {
      Header: "Download",
      accessor: "recording_player",
      sortable: false,
      noCSV: true
    },
    {
      Header: "Player",
      accessor: "recording_player",
      sortable: false,
      noCSV: true
    },
    {
      Header: "Rings to",
      accessor: "formatted_business_phone_number"
    }
  ];

  const csvColumns = columns.filter(({ noCSV }) => !noCSV);

  const csvData = [
    csvColumns?.map(({ Header }) => Header),
    ...Calldata?.map(row => {
      return csvColumns?.map(({ accessor, Cell }) => {
        if (Cell) {
          return Cell({ original: row });
        }
        return row[accessor];
      });
    })
  ];

  const csvKeywordData = [
    Keywordcolumns?.map(({ Header }) => Header),
    ...keywordata?.map(row => {
      return Keywordcolumns?.map(({ accessor, Cell }) => {
        if (Cell) {
          return Cell({ original: row });
        }
        return row[accessor];
      });
    })
  ];

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);

    const handleOpen = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
      }
    };

    const classes = useRowStyles();

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(index)}>
                  {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect?.split(",")?.filter(CurrentCall => CurrentCall === row?.id)?.length : false}
                  onChange={e => updateSelectFieldHandler("multiselect", row?.id, e)}
                >
                </Checkbox>
              </TableCell>
              <TableCell>
                <>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setSelectedCall({ ...row, submit: false });
                    }}
                  >
                    Rate Call
                  </a>
                  <div>{defaultLeadTypes.find(
                    ({ key }) => key === row?.lead_type
                  )?.label}</div>
                  <div>{defaultLeadStatuses.find(
                    ({ key }) => key === row?.lead_status
                  )?.label}</div>
                </>
              </TableCell>
              <TableCell align="center" maxWidth="80px">
                <div>
                  <a
                    href="#"
                    rel="noopener noreferrer"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpenEdit(row);
                    }}
                  >
                    {row?.formatted_customer_name}
                  </a>
                </div>
              </TableCell>
              <TableCell align="center"><div>{row?.formatted_customer_phone_number}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.customer_city}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.customer_state}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.formatted_duration}</div>
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: "nowrap" }}><div>{row?.start_time ? moment(row?.start_time).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.device_type}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.formatted_tracking_source}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.recording_player && (
                <a href={row?.recording_player?.replace(".us-east-1", "")} download>
                  Download
                </a>
              ) ||
                ""}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.recording_player && (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    window.open(
                      row?.recording_player?.replace(".mp3", "stream.mp3"),
                      "_blank",
                      "location=yes,height=400,width=700,scrollbars=yes,status=yes"
                    );
                  }}
                >
                  Play
                </a>
              ) ||
                ""}</div>
              </TableCell>
              <TableCell align="center"><div style={{ width: 150 }}>{row?.formatted_business_phone_number}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><div style={{ width: 100 }}><b>Tracking Number:</b><br />{row?.formatted_tracking_phone_number}</div></TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Call Type:</b><br />{row?.formatted_call_type}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><div style={{ width: 200 }}><b>Landing Page:</b><br />{row?.landing_page_url ? <a href={row?.landing_page_url}>Link</a> : ""}</div></TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><div style={{ width: 200 }}><b>Last Visited Page:</b><br />{row?.last_requested_url}</div></TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>First Call:</b><br />{row?.first_call === "1" ? "Yes" : "No"}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Repeat Call:</b><br />{row?.prior_calls}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Direction:</b><br />{row?.direction}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Voicemail:</b><br />{row?.voicemail === "1" ? "Yes" : "No"}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Tags:</b><br />{row?.tags}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Call Created:</b><br />{row?.created_at ? moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a") : ""}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Sale/Customer Value:</b><br />{row?.formatted_value === "--" || row?.formatted_value === "$0.00" ?
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setSelectedCall({ ...row, submit: false });
                          }}
                        >
                          Rate Call
                        </a> : row?.formatted_value}</TableCell>
                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Lead Status:</b>{defaultLeadStatuses.find(
                        ({ key }) => key === row?.lead_status
                      )?.label}</TableCell>
                    </TableRow>
                    {me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                      me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "content_manager" ?
                      <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Search Keywords:</b><br />{row?.keywords}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><div><b>Medium:</b><br />{row?.medium}</div></TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><div><b>Campaign:</b><br />{row?.campaign}</div></TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>UTM Source:</b><br />{row?.utm_source}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>UTM Medium:</b><br />{row?.utm_medium}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>UTM Campaign:</b><br />{row?.utm_campaign}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>UTM Term:</b><br />{row?.utm_term}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>UTM Content:</b>{row?.utm_content}</TableCell>
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}><b>Notes:</b><br />{row?.note}</TableCell>
                      </TableRow>
                      : ""}
                  </Table>
                  {(me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                    me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "content_manager") && JSON.parse(row?.milestones)?.first_touch && (
                      <Table size="medium">
                        <TableRow style={{ backgroundColor: "#F5F5F5", whiteSpace: "nowrap" }}>
                          <TableCell style={{ paddingTop: "25px", paddingBottom: "25px" }}><div style={{ width: "100%" }}><b>Milestone:</b>  First Touch<br />
                            <b>Event Date:</b>  {moment(JSON.parse(row?.milestones)?.first_touch?.event_date).format("YYYY-MM-DD hh:mm:ss a")}<br />
                            <b>Device:</b>  {JSON.parse(row?.milestones)?.first_touch?.device}<br />
                            <b>Medium:</b>  {JSON.parse(row?.milestones)?.first_touch?.medium}<br />
                            <b>Source:</b>  {JSON.parse(row?.milestones)?.first_touch?.campaign}<br />
                            <b>Landing Page:</b>  <a href={JSON.parse(row?.milestones)?.first_touch?.landing}>Link</a><br />
                            <b>Campaign:</b>  {JSON.parse(row?.milestones)?.first_touch?.campaign}<br />
                            <b>Keywords:</b>  {JSON.parse(row?.milestones)?.first_touch?.keywords}<br />
                            <b>Referring Page:</b>  <a href={JSON.parse(row?.milestones)?.first_touch?.referrer}>Link</a><br />
                            <b>Match Type:</b>  {JSON.parse(row?.milestones)?.first_touch?.match_type}<br />
                            <b>Ad Position:</b>  {JSON.parse(row?.milestones)?.first_touch?.ad_position}<br />
                            <b>Session Browser:</b>  {JSON.parse(row?.milestones)?.first_touch?.session_browser}<br />
                            <b>Ad Group ID:</b>  {JSON.parse(row?.milestones)?.first_touch?.landing_page_url_params?.adgroupid}<br />
                            <b>Campaign ID:</b>  {JSON.parse(row?.milestones)?.first_touch?.landing_page_url_params?.campaignid}<br />
                          </div>
                          </TableCell>
                        </TableRow>
                      </Table>
                    )}
                  {(me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                    me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "content_manager") && JSON.parse(row?.milestones)?.lead_created && (
                      <Table size="medium">
                        <TableRow style={{ backgroundColor: "#F5F5F5", whiteSpace: "nowrap" }}>
                          <TableCell style={{ paddingTop: "25px", paddingBottom: "25px" }}><div style={{ width: "100%" }}><b>Milestone:</b>  Lead Created<br />
                            <b>Event Date:</b>  {moment(JSON.parse(row?.milestones)?.lead_created?.event_date).format("YYYY-MM-DD hh:mm:ss a")}<br />
                            <b>Device:</b>  {JSON.parse(row?.milestones)?.lead_created?.device}<br />
                            <b>Medium:</b>  {JSON.parse(row?.milestones)?.lead_created?.medium}<br />
                            <b>Source:</b>  {JSON.parse(row?.milestones)?.lead_created?.campaign}<br />
                            <b>Landing Page:</b>  <a href={JSON.parse(row?.milestones)?.lead_created?.landing}>Link</a><br />
                            <b>Campaign:</b>  {JSON.parse(row?.milestones)?.lead_created?.campaign}<br />
                            <b>Keywords:</b>  {JSON.parse(row?.milestones)?.lead_created?.keywords}<br />
                            <b>Referring Page:</b>  <a href={JSON.parse(row?.milestones)?.lead_created?.referrer}>Link</a><br />
                            <b>Match Type:</b>  {JSON.parse(row?.milestones)?.lead_created?.match_type}<br />
                            <b>Ad Position:</b>  {JSON.parse(row?.milestones)?.lead_created?.ad_position}<br />
                            <b>Session Browser:</b>  {JSON.parse(row?.milestones)?.lead_created?.session_browser}<br />
                            <b>Ad Group ID:</b>  {JSON.parse(row?.milestones)?.lead_created?.landing_page_url_params?.adgroupid}<br />
                            <b>Campaign ID:</b>  {JSON.parse(row?.milestones)?.lead_created?.landing_page_url_params?.campaignid}<br />
                          </div>
                          </TableCell>
                        </TableRow>
                      </Table>
                    )}
                  {(me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                    me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "content_manager") && JSON.parse(row?.milestones)?.qualified && (
                      <Table size="medium">
                        <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                          <TableCell style={{ paddingTop: "25px", paddingBottom: "25px" }}><div style={{ width: "100%" }}><b>Milestone:</b>  Qualified<br />
                            <b>Event Date:</b>  {moment(JSON.parse(row?.milestones)?.qualified?.event_date).format("YYYY-MM-DD hh:mm:ss a")}<br />
                            <b>Device:</b>  {JSON.parse(row?.milestones)?.qualified?.device}<br />
                            <b>Medium:</b>  {JSON.parse(row?.milestones)?.qualified?.medium}<br />
                            <b>Source:</b>  {JSON.parse(row?.milestones)?.qualified?.campaign}<br />
                            <b>Landing Page:</b>  <a href={JSON.parse(row?.milestones)?.qualified?.landing}>Link</a><br />
                            <b>Campaign:</b>  {JSON.parse(row?.milestones)?.qualified?.campaign}<br />
                            <b>Keywords:</b>  {JSON.parse(row?.milestones)?.qualified?.keywords}<br />
                            <b>Referring Page:</b>  <a href={JSON.parse(row?.milestones)?.qualified?.referrer}>Link</a><br />
                            <b>Match Type:</b>  {JSON.parse(row?.milestones)?.qualified?.match_type}<br />
                            <b>Ad Position:</b>  {JSON.parse(row?.milestones)?.qualified?.ad_position}<br />
                            <b>Session Browser:</b>  {JSON.parse(row?.milestones)?.qualified?.session_browser}<br />
                            <b>Ad Group ID:</b>  {JSON.parse(row?.milestones)?.qualified?.landing_page_url_params?.adgroupid}<br />
                            <b>Campaign ID:</b>  {JSON.parse(row?.milestones)?.qualified?.landing_page_url_params?.campaignid}<br />
                          </div>
                          </TableCell>
                        </TableRow>
                      </Table>
                    )}
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
      </React.Fragment>
    );
  }

  const masterRole = me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager";

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="leads" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Call Recording
              <CSVLink
                data={csvData}
                filename={`Tracked Conversions Details (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                style={{ float: "right", margin: 0 }}
              >
                <ArchiveIcon />
              </CSVLink>
              {masterRole && (
                <CSVLink
                  data={csvKeywordData}
                  filename={`Call_Keywords.csv`}
                  style={{ float: "right", margin: 0 }}
                >
                  <Button
                    color="primary"
                    style={{ float: "right", marginRight: 10 }}
                  >
                    Download Keywords
                  </Button>
                </CSVLink>)}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={Calldata}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setPhone={setPhone}
                  setSource={setSource}
                  setCity={setCity}
                  setState={setState}
                  setDevice={setDevice}
                  setRings={setRings}
                />
                <TableBody>
                  <Row data={Calldata} />
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              open={Boolean(selectedCall)}
              onClose={() => setSelectedCall(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="sm"
            >
              <DialogTitle id="alert-dialog-title">
                Rate Call Recording
              </DialogTitle>
              <DialogContent>
                <p>{selectedCall?.call_summary}</p>
                <div style={{ clear: "both" }}></div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>

                  <div style={{ width: 120 }}>Quality</div>
                  <Select
                    options={leadQualities}
                    selected={selectedCall?.lead_quality}
                    onSelect={updateFieldHandler("lead_quality")}
                    style={{ flex: 1 }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Type</div>
                  <Select
                    options={defaultLeadTypes}
                    selected={leadType}
                    onSelect={updateFieldHandler("lead_type")}
                    style={{ flex: 1 }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Status</div>
                  <Select
                    options={defaultLeadStatuses.filter(
                      ({ types }) => types?.indexOf(leadType) !== -1
                    )}
                    selected={selectedCall?.lead_status}
                    onSelect={updateFieldHandler("lead_status")}
                    style={{ flex: 1 }}
                  />
                </div>
                {CategoryData?.length ?
                  <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 15, marginTop: 15 }}>
                    <div style={{ width: 120 }}>Category</div>
                    <MultiSelect
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      multiple
                      input={<Input />}
                      renderValue={selected => selected.join(", ")}
                      onChange={updateFieldHandler("categories")}
                      inputProps={{
                        name: "categories",
                        id: "categories",
                        value: (typeof selectedCall?.categories === 'string' || selectedCall?.categories instanceof String) ? selectedCall?.categories?.split(",")?.map((category_name) => { return CategoryData?.filter((CurrentCategory) => { if (CurrentCategory?.id.toString() === category_name.toString()) { return true } else { return false } })?.map((CurrentCategory) => { return CurrentCategory?.category_text }).join(", ") }) || [] : (typeof selectedCall?.categories !== 'undefined') ? selectedCall?.categories || [] : []
                      }}
                      style={{ flex: 1, border: "1px solid grey" }}
                    >
                      {CategoryData?.map((CurrentCategory) => {
                        var checked = false;
                        if ((typeof selectedCall?.categories === 'string' || selectedCall?.categories instanceof String)) {
                          var temp = selectedCall?.categories?.split(",");

                          for (var index = 0; index <= temp?.length; index++) {
                            if (temp[index]?.toString() === CurrentCategory?.id?.toString() || temp[index]?.toString() === CurrentCategory?.category_text?.toString()) { checked = true }
                          }
                        }
                        if (selectedCall?.categories instanceof Object) {
                          for (var index = 0; index <= selectedCall?.categories?.length; index++) {
                            if (selectedCall?.categories[index]?.toString() === CurrentCategory?.category_text?.toString() || selectedCall?.categories[index]?.toString() === CurrentCategory?.id?.toString()) { checked = true }
                          }
                        }

                        return (
                          <MenuItem key={CurrentCategory?.id} value={CurrentCategory?.category_text}>
                            <CustomCheckbox
                              checked={checked}
                            />
                            <ListItemText primary={CurrentCategory?.category_text} />
                          </MenuItem>
                        )
                      })}
                    </MultiSelect>
                  </div>
                  : ""}
                {ProductData?.length ?
                  <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 15, marginTop: 15 }}>
                    <div style={{ width: 120 }}>Product</div>
                    <MultiSelect
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      multiple
                      input={<Input />}
                      renderValue={selected => selected.join(", ")}
                      onChange={updateFieldHandler("products")}
                      inputProps={{
                        name: "products",
                        id: "products",
                        value: (typeof selectedCall?.products === 'string' || selectedCall?.products instanceof String) ? selectedCall?.products?.split(",")?.map((product_name) => { return ProductData?.filter((CurrentProduct) => { if (CurrentProduct?.id.toString() === product_name.toString()) { return true } else { return false } })?.map((CurrentProduct) => { return CurrentProduct?.product_name }).join(", ") }) || [] : (typeof selectedCall?.products !== 'undefined') ? selectedCall?.products || [] : []
                      }}
                      style={{ flex: 1, border: "1px solid grey" }}
                    >
                      {ProductData?.map((CurrentProduct) => {
                        var checked = false;
                        if ((typeof selectedCall?.products === 'string' || selectedCall?.products instanceof String)) {
                          var temp = selectedCall?.products?.split(",");

                          for (var index = 0; index <= temp?.length; index++) {
                            if (temp[index]?.toString() === CurrentProduct?.id?.toString() || temp[index]?.toString() === CurrentProduct?.product_name?.toString()) { checked = true }
                          }
                        }
                        if (selectedCall?.products instanceof Object) {
                          for (var index = 0; index <= selectedCall?.products?.length; index++) {
                            if (selectedCall?.products[index]?.toString() === CurrentProduct?.product_name?.toString() || selectedCall?.products[index]?.toString() === CurrentProduct?.id?.toString()) { checked = true }
                          }
                        }

                        return (
                          <MenuItem key={"products" + CurrentProduct?.id} value={CurrentProduct?.product_name}>
                            <CustomCheckbox
                              checked={checked}
                            />
                            <ListItemText primary={CurrentProduct?.product_name} />
                          </MenuItem>
                        )
                      })}
                    </MultiSelect>
                  </div>
                  : ""}
                  <div style={{ clear: "both", height: "20px"}}></div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ whiteSpace: "nowrap", width: "120px"}}>Sale Value:</div>
                  <CustomInput
                    labelText="Dollar Amount"
                    inputProps={{
                      value: selectedCall?.value || "",
                      onChange: updateFieldHandler("value"),
                      type: "number",
                      style: { width: "432px" }
                    }}
                  />
                </div>
                <div style={{ clear: "both", height: "40px"}}></div>
                <CustomInput
                  labelText="Note"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: selectedCall?.note || "",
                    onChange: updateFieldHandler("note")
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (selectedCall === null || selectedCall?.lead_quality === "") {
                      alert("Please select lead quality.")
                      return;
                    }
                    if (leadType === undefined) {
                      alert("Please select lead type.")
                      return;
                    }
                    if (selectedCall === null || selectedCall?.lead_status === "") {
                      alert("Please select lead status.")
                      return;
                    }

                    updateCall();
                    setSelectedCall(null);
                  }}
                  color="primary"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setCustomers={setCustomers}
                    customers={customers}
                    TrackedLead={1}
                    source={"Hydrate"}
                  />
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

CallRecording.propTypes = {
  offline: PropTypes.bool,
  filter: PropTypes.string
};

export default withSubmissions(CallRecording);
