import gql from "graphql-tag";

export const SUBMIT_FORM = gql`
  mutation($data: FormSubmissionInput!) {
    submitForm(data: $data)
  }
`;

export const SUBMIT_REVIEW = gql`
  mutation($data: ReviewSubmissionInput!) {
    submitReview(data: $data)
  }
`;

export const SUBMIT_REFER_FRIEND = gql`
  mutation($data: ReferFriendSubmissionInput!) {
    submitReferFriend(data: $data)
  }
`;

export const SUBMIT_ESTIMATE_REQUEST = gql`
  mutation($data: EstimateRequestSubmissionInput!) {
    submitEstimateRequest(data: $data)
  }
`;

export const SUBMIT_KEYWORDS_REMOVAL_REQUEST = gql`
  mutation($data: KeywordsRemovalRequestSubmissionInput!, $customerId: Int) {
    submitKeywordsRemovalRequest(data: $data, customerId: $customerId)
  }
`;

export const SUBMIT_UPDATE_REQUEST = gql`
  mutation($subject: String, $updateTable: String, $customerId: Int) {
    submitUpdateRequest(
      subject: $subject
      updateTable: $updateTable
      customerId: $customerId
    )
  }
`;

export const SUBMIT_CREATIVE_FORM = gql`
  mutation($data: CustomerCreativeInput!, $customerId: Int!) {
    submitCreativeForm(data: $data, customerId: $customerId)
  }
`;

export const SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM = gql`
  mutation($customerId: Int, $data: GoogleCampaignMonthlyBudgetInput!) {
    updateGoogleCampaignMonthlyBudgetForm(customerId: $customerId, data: $data)
  }
`;

export const UPDATE_OPTIMIZATIONS = gql`
  mutation($customerId: Int, $id: Int, $keyword_optimization: Int, $budget_optimization: Int, $banner_ad_optimization: Int, $share_budget: Int) {
    updateOptimizations(customerId: $customerId, id: $id, keyword_optimization: $keyword_optimization, budget_optimization: $budget_optimization, banner_ad_optimization: $banner_ad_optimization, share_budget: $share_budget)
  }
`;

export const COMPLETE_CREATIVE_FORM = gql`
  mutation($id: Int!, $data: CustomerCreativeInput!) {
    completeCreativeForm(id: $id, data: $data)
  }
`;

export const COMPLETE_CREATIVE_TASK = gql`
  mutation($id: Int!, $data: String!, $notes: String) {
    completeCreativeTask(id: $id, data: $data, notes: $notes)
  }
`;

export const UPDATE_TICKET_STATUS = gql`
  mutation($id: Int!, $ticket_status: String!, $data: String) {
    submitTicketReview(id: $id, ticket_status: $ticket_status, data: $data)
  }
`;

export const GET_CUSTOMER_PRODUCTS = gql`
  query($customerId: Int) {
    getCustomerProducts(customerId: $customerId)
  }
`;

export const GET_CREATIVE_FORM = gql`
  query($customerId: Int!, $ticketid: String, $completed: Int) {
    getCreativeForm(customerId: $customerId, ticketid: $ticketid, completed: $completed) {
      customer_id
      customer_name
      ticketid
      id
      notes
      priority_level
      requested_completion_date
      submitted_by
      assigned_to
      completed
      completed_by
      completed_time
      issue_description
      paidsearch_text_ads
      paidsearch_text_ads_assignment
      paidsearch_text_ads_completed
      paidsearch_text_ads_completed_time
      paidsearch_text_ads_completed_notes
      paidsearch_text_ads_completed_status
      paidsearch_ad_extensions
      paidsearch_ad_extensions_assignment
      paidsearch_ad_extensions_completed
      paidsearch_ad_extensions_completed_time
      paidsearch_ad_extensions_completed_notes
      paidsearch_ad_extensions_completed_status
      socialads
      creative_onlinevideo
      creative_hydrate
      creative_out_of_home
      campaign_review_assignment
      campaign_review_assignment_completed
      campaign_review_assignment_completed_time
      campaign_review_assignment_completed_notes
      campaign_review_assignment_completed_status
      reporting_request_assignment
      reporting_request_assignment_completed
      reporting_request_assignment_completed_time
      reporting_request_assignment_completed_notes
      reporting_request_assignment_completed_status
      call_tracking_issue_assignment
      call_tracking_issue_assignment_completed
      call_tracking_issue_assignment_completed_time
      call_tracking_issue_assignment_completed_notes
      call_tracking_issue_assignment_completed_status
      campaign_performance_issue_assignment
      campaign_performance_issue_assignment_completed
      campaign_performance_issue_assignment_completed_time
      campaign_performance_issue_assignment_completed_notes
      campaign_performance_issue_assignment_completed_status
      update_creative_assignment
      update_creative_assignment_completed
      update_creative_assignment_completed_time
      update_creative_assignment_completed_notes
      update_creative_assignment_completed_status
      budget_review_assignment
      budget_review_assignment_completed
      budget_review_assignment_completed_time
      budget_review_assignment_completed_notes
      budget_review_assignment_completed_status
      website_edit_assignment
      website_edit_assignment_completed
      website_edit_assignment_completed_time
      website_edit_assignment_completed_notes
      website_edit_assignment_completed_status
      other_assignment
      other_assignment_completed
      other_assignment_completed_time
      other_assignment_completed_notes
      other_assignment_completed_status
      creative_bannerads
      promotion_bannerads
      promotion_bannerads_assignment
      promotion_bannerads_completed
      promotion_bannerads_completed_time
      promotion_bannerads_completed_notes
      promotion_bannerads_completed_status
      socialads
      promotion_onlinevideo
      promotion_onlinevideo_assignment
      promotion_onlinevideo_completed
      promotion_onlinevideo_completed_time
      promotion_onlinevideo_completed_notes
      promotion_onlinevideo_completed_status
      promotion_hydrate
      promotion_hydrate_assignment
      promotion_hydrate_completed
      promotion_hydrate_completed_time
      promotion_hydrate_completed_notes
      promotion_hydrate_completed_status
      promotion_out_of_home
      promotion_out_of_home_assignment
      promotion_out_of_home_completed
      promotion_out_of_home_completed_time
      promotion_out_of_home_completed_notes
      promotion_out_of_home_completed_status
      social_media_post
      social_media_post_assignment
      social_media_post_assignment_completed
      social_media_post_assignment_completed_time
      social_media_post_assignment_completed_notes
      social_media_post_assignment_completed_status
      new_website_content_pages
      new_website_content_pages_assignment
      new_website_content_pages_assignment_completed
      new_website_content_pages_assignment_completed_time
      new_website_content_pages_assignment_completed_notes
      new_website_content_pages_assignment_completed_status
      blog_content
      blog_content_assignment
      blog_content_assignment_completed
      blog_content_assignment_completed_time
      blog_content_assignment_completed_notes
      blog_content_assignment_completed_status
      cancellation_assignment
      cancellation_assignment_completed
      cancellation_assignment_completed_time
      cancellation_assignment_completed_notes
      cancellation_assignment_completed_notes_status
      website_coupons
      website_coupons_assignment
      website_coupons_completed
      website_coupons_completed_time
      website_coupons_completed_notes
      website_coupons_completed_status
      website
      asset_name
      link_to_assets
      creative_promotion_name
      creative_message
      creative_start
      creative_end
      landing_page
      assigned_to_time
      closed_by
      closed_by_time
      created_time
    }
  }
`;

export const GET_TICKET_COUNT = gql`
  query($customerId: Int!, $completed: Int, $customer_search: String) {
    getTicketCount(customerId: $customerId, completed: $completed, customer_search: $customer_search) {
      ticket_count
      completed
      customer_search
    }
  }
`;

export const LIST_CREATIVE_FORM = gql`
  query($customerId: Int!, $ticketid: String, $completed: Int, $page: Int, $rows_per_page: Int, $customer_search: String, $order_by: String) {
    listCreativeForm(customerId: $customerId, ticketid: $ticketid, completed: $completed, page: $page, rows_per_page: $rows_per_page, customer_search: $customer_search, order_by: $order_by) {
      customer_id
      customer_name
      ticketid
      page
      rows_per_page
      customer_search
      order_by
      id
      notes
      priority_level
      requested_completion_date
      submitted_by
      assigned_to
      completed
      completed_by
      completed_time
      issue_description
      creative_bannerads
      promotion_bannerads
      promotion_bannerads_assignment
      promotion_bannerads_completed
      promotion_bannerads_completed_time
      promotion_bannerads_completed_notes
      promotion_bannerads_completed_status
      paidsearch_text_ads
      paidsearch_text_ads_assignment
      paidsearch_text_ads_completed
      paidsearch_text_ads_completed_time
      paidsearch_text_ads_completed_notes
      paidsearch_text_ads_completed_status
      paidsearch_ad_extensions
      paidsearch_ad_extensions_assignment
      paidsearch_ad_extensions_completed
      paidsearch_ad_extensions_completed_time
      paidsearch_ad_extensions_completed_notes
      paidsearch_ad_extensions_completed_status
      socialads
      creative_onlinevideo
      promotion_onlinevideo
      promotion_onlinevideo_assignment
      promotion_onlinevideo_completed
      promotion_onlinevideo_completed_time
      promotion_onlinevideo_completed_notes
      promotion_onlinevideo_completed_status
      creative_hydrate
      promotion_hydrate
      promotion_hydrate_assignment
      promotion_hydrate_completed
      promotion_hydrate_completed_time
      promotion_hydrate_completed_notes
      promotion_hydrate_completed_status
      creative_out_of_home
      promotion_out_of_home
      promotion_out_of_home_assignment
      promotion_out_of_home_completed
      promotion_out_of_home_completed_time
      promotion_out_of_home_completed_notes
      promotion_out_of_home_completed_status
      social_media_post
      social_media_post_assignment
      social_media_post_assignment_completed
      social_media_post_assignment_completed_time
      social_media_post_assignment_completed_notes
      social_media_post_assignment_completed_status
      new_website_content_pages
      new_website_content_pages_assignment
      new_website_content_pages_assignment_completed
      new_website_content_pages_assignment_completed_time
      new_website_content_pages_assignment_completed_notes
      new_website_content_pages_assignment_completed_status
      blog_content
      blog_content_assignment
      blog_content_assignment_completed
      blog_content_assignment_completed_time
      blog_content_assignment_completed_notes
      blog_content_assignment_completed_status
      cancellation_assignment
      cancellation_assignment_completed
      cancellation_assignment_completed_time
      cancellation_assignment_completed_notes
      cancellation_assignment_completed_status
      website_coupons
      website_coupons_assignment
      website_coupons_completed
      website_coupons_completed_time
      website_coupons_completed_notes
      website_coupons_completed_status
      campaign_review_assignment
      campaign_review_assignment_completed
      campaign_review_assignment_completed_time
      campaign_review_assignment_completed_notes
      campaign_review_assignment_completed_status
      reporting_request_assignment
      reporting_request_assignment_completed
      reporting_request_assignment_completed_time
      reporting_request_assignment_completed_notes
      reporting_request_assignment_completed_status
      call_tracking_issue_assignment
      call_tracking_issue_assignment_completed
      call_tracking_issue_assignment_completed_time
      call_tracking_issue_assignment_completed_notes
      call_tracking_issue_assignment_completed_status
      campaign_performance_issue_assignment
      campaign_performance_issue_assignment_completed
      campaign_performance_issue_assignment_completed_time
      campaign_performance_issue_assignment_completed_notes
      campaign_performance_issue_assignment_completed_status
      update_creative_assignment
      update_creative_assignment_completed
      update_creative_assignment_completed_time
      update_creative_assignment_completed_notes
      update_creative_assignment_completed_status
      budget_review_assignment
      budget_review_assignment_completed
      budget_review_assignment_completed_time
      budget_review_assignment_completed_notes
      budget_review_assignment_completed_status
      website_edit_assignment      
      website_edit_assignment_completed
      website_edit_assignment_completed_time
      website_edit_assignment_completed_notes
      website_edit_assignment_completed_status
      other_assignment
      other_assignment_completed
      other_assignment_completed_time      
      other_assignment_completed_notes
      other_assignment_completed_status
      website
      asset_name
      link_to_assets
      creative_promotion_name
      creative_message
      creative_start
      creative_end
      landing_page
      assigned_to_time
      closed_by
      closed_by_time
      created_time
      user_submitted {
        first_name
        last_name
      }
      user_completed {
        first_name
        last_name
      }
      campaign_review_assigned_user {
        first_name
        last_name
        email
      }
      reporting_request_assigned_user {
        first_name
        last_name
        email
      }
      call_tracking_issue_assigned_user {
        first_name
        last_name
        email
      }
      campaign_performance_issue_assigned_user {
        first_name
        last_name
        email
      }
      update_creative_assigned_user {
        first_name
        last_name
        email
      }
      budget_review_assigned_user {
        first_name
        last_name
        email
      }
      website_edit_assigned_user {
        first_name
        last_name
        email
      }
      other_assigned_user {
        first_name
        last_name
        email
      }
      paidsearch_text_ads_assigned_user {
        first_name
        last_name
        email
      }
      paidsearch_ad_extensions_assigned_user {
        first_name
        last_name
        email
      }
      promotion_bannerads_assigned_user {
        first_name
        last_name
        email
      }
      promotion_onlinevideo_assigned_user {
        first_name
        last_name
        email
      }
      social_media_post_assigned_user {
        first_name
        last_name
        email
      }
      new_website_content_pages_assigned_user {
        first_name
        last_name
        email
      }
      blog_content_assigned_user {
        first_name
        last_name
        email
      }
      cancellation_assigned_user {
        first_name
        last_name
        email
      }
      promotion_hydrate_assigned_user {
        first_name
        last_name
        email
      }
      promotion_out_of_home_assigned_user {
        first_name
        last_name
        email
      }
      website_coupons_assigned_user {
        first_name
        last_name
        email
      }
    }
  }
`;
