import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const moment = require("moment");

const PhotoList = (row, index, CRMPhotoGallery, CRMPhotos, object_key, CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id) => {
  return CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto[object_key] === row?.id).map((CurrentPhoto, photoIndex) => {
    return (
      <>
        {isPhotoOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isPhotoOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ width: "175px", float: "left", paddingTop: 15, paddingBottom: 15 }}>
                  {CRMPhotos?.filter((CurrentIndividualPhoto) => CurrentIndividualPhoto?.photo_gallery_id === CurrentPhoto?.id)?.length > 0 && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handlePhotosExpand(photoIndex)}>
                      {isPhotosOpenCollapse === photoIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
                  &nbsp;
                </div>
                <div style={{ display: "inline-block", textAlign: "center", paddingTop: 15, paddingBottom: 15 }}>
                  <div style={{ width: "175px", float: "left" }}><b>Gallery Name</b><br /> <a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + CurrentPhoto?.crm_customers_id + "&peopleid=" + CurrentPhoto?.people_id + "&photoid=" + CurrentPhoto?.id}>{CurrentPhoto?.photo_gallery_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Description</b><br /> {CurrentPhoto?.photo_gallery_description}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Last Updated</b><br /> {moment.utc(new Date(parseInt(CurrentPhoto?.photo_gallery_upload_date))).format("MM-DD-YYYY hh:mm:ss A")}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === CurrentPhoto?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
                </div>
                {CRMPhotos?.filter((CurrentIndividualPhoto) => CurrentIndividualPhoto?.photo_gallery_id === CurrentPhoto?.id).map(CurrentIndividualPhoto => {
                  return (
                    <Collapse in={isPhotosOpenCollapse === photoIndex} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                      <div style={{ display: "inline-block", textAlign: "center", paddingTop: 15, paddingBottom: 15 }}>
                        <div style={{ width: "175px", float: "left" }}><img src={CurrentIndividualPhoto?.photo_url} height="50px"></img></div>
                        <div style={{ width: "175px", float: "left" }}><b>Photo Name</b><br /> <a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + CurrentPhoto?.crm_customers_id + "&peopleid=" + CurrentPhoto?.people_id + "&photoid=" + CurrentPhoto?.id}>{CurrentIndividualPhoto?.photo_name}</a></div>
                        <div style={{ width: "175px", float: "left" }}><b>Description</b><br /> {CurrentIndividualPhoto?.photo_description}</div>
                      </div>
                    </Collapse>
                  )
                })}
              </Collapse >
            </TableCell >
          </TableRow >
        )}
      </>
    )
  })
};

export default PhotoList;
