import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

// @mui/icons-material
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Input from '@mui/material/Input';
import Button from "components/CustomButtons/Button";
import DeleteButton from '@mui/material/Button';
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import "assets/css/richtext_styles.css";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import UpdateCRMProduct from "views/Dashboard/UpdateCRMProduct";

import {
  UPDATE_CUSTOMER_EMAIL,
  DELETE_PRODUCT,
  ADD_PRODUCT_CATEGORY,
  LIST_PRODUCT,
  GET_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setCategory, setSKU, setPrice, setStock, crm_user_level } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'center'}
                style={{ verticalAlign: "top" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Image" && column.Header !== "Stock" && column.Header !== "Delete" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Name" ? setName(event.target.value) :
                        column.Header === "SKU" ? setSKU(event.target.value) :
                          column.Header === "Price" ? setPrice(event.target.value) :
                            setCategory(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

const Products = () => {
  const [state] = useContext(Context);
  const [tabledata, setTabledata] = useState();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [AddProductCategory, setAddProductCategory] = useState(false);
  const [UpdateProductCategory, setUpdateProductCategory] = useState(false);
  const [DeleteProductCategory, setDeleteProductCategory] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [name, setName] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [SKU, setSKU] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [Hover, setHover] = useState(null);
  const [submissionData, setSubmissionData] = useState([]);
  const [UpdateCategory, setUpdateCategory] = useState([]);
  const [DeleteCategory, setDeleteCategory] = useState(null);
  const [CategoryData, setCategoryData] = useState(null);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');

  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const crm_user_level = me?.readMe?.crm_user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { data, refetch } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      category,
      SKU,
      price,
      count: 0
    }
  });

  let { data: all_data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  const { data: Category_Data, refetch: refetchCategories } = useQuery(GET_PRODUCT_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const [addCategoryForm] = useMutation(ADD_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setSubmissionData({});
      refetch();
      refetchCategories();
    }
  });

  const [UpdateCategoryForm] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchCategories();
    }
  });

  const [deleteCategoryForm] = useMutation(DELETE_PRODUCT_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      refetchCategories();
    }
  });

  all_data = all_data?.ListProduct || [];

  useEffect(() => {
    setTabledata(data?.ListProduct || [])
    setCategoryData(Category_Data?.listProductCategories || []);
  }, [data, Category_Data]);

  const classes = useStyles();

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (tabledata?.length) {
    csvData.push(
      ...tabledata.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const deleteCategory = (id) => {
    deleteCategoryForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const updateCategoryFieldHandler = (key, convert) => e => {
    setUpdateCategory({
      ...UpdateCategory,
      [key]: convert ? convert(e.target.value) : e.target.value,
      ["update_category"]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = tabledata.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setTabledata(newCustomers);
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_PRODUCT,
    {
      onCompleted: ({ deleteProduct }) => {
        const newProducts = tabledata.filter(({ id }) => id !== deleteProduct);
        setTabledata(newProducts);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const handleOpen = row => {
    setCurrentProduct(row?.id ? row : {});
  };

  const handleProductCategoryOpen = row => {
    setAddProductCategory(true);
  };

  const handleUpdateProductCategoryOpen = row => {
    setUpdateProductCategory(true);
  };

  const handleDeleteProductCategoryOpen = row => {
    setDeleteProductCategory(true);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setCurrentProduct(null);
  };

  const columns = compact([
    {
      Header: "Image",
    },
    {
      Header: "Name",
    },
    {
      Header: "SKU",
    },
    {
      Header: "Category",
    },
    {
      Header: "Price",
    },
    {
      Header: "Delete",
    }
  ]);

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props) {
    const { data, handleOpen } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell align="center"><img src={row?.product_image} height="50px"></img></TableCell>
              <TableCell align="center">
                <>
                  {crm_user_level === "crm_admin" || crm_user_level === "crm_editor" ?
                    <a
                      href="#"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen(row);
                      }}
                    >
                      {row?.product_name}
                    </a>
                    : <div>{row?.product_name}</div>
                  }
                </>
              </TableCell>
              <TableCell align="center"><div>{row?.product_SKU}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.category_text}</div>
              </TableCell>
              <TableCell align="center"><div>{parseFloat(row?.product_price?.replace(/,/g, '')).toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}</div>
              </TableCell>
              <TableCell align="center">
                {crm_user_level === "crm_admin" && customer_id !== 38 && (
                  <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                    }
                  }}>
                    <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} />
                  </DeleteButton>
                )}
              </TableCell>
            </TableRow>
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = updating || deleting || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Inventory2OutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={tabledata || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                  disabled={isLoading}
                >
                  Add Product
                </Button>
              )}
              {/*
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Product Category
                </Button>
              )}
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleUpdateProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Product Category
                </Button>
              )}
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleDeleteProductCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Product Category
                </Button>
              )}
              */}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setSKU={setSKU}
                  setPrice={setPrice}
                  setCategory={setCategory}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={tabledata} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {currentProduct && (
              <Dialog
                open={Boolean(currentProduct)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCRMProduct
                    current={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                    handleClose={handleClose}
                    refetch={refetch}
                    setproduct_cost={setproduct_cost}
                    product_cost={product_cost}
                    product_margin={product_margin}
                    setproduct_margin={setproduct_margin}
                    product_price={product_price}
                    setproduct_price={setproduct_price}
                    product_discount={product_discount}
                    setproduct_discount={setproduct_discount}
                    product_sale_price={product_sale_price}
                    setproduct_sale_price={setproduct_sale_price}
                    product_tax={product_tax}
                    setproduct_tax={setproduct_tax}
                  />
                </DialogContent>
              </Dialog>
            )}
            {AddProductCategory && (
              <Dialog
                open={Boolean(AddProductCategory)}
                onClose={() => setAddProductCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                width="300px"
              >
                <DialogTitle id="alert-dialog-title">
                  Add Category
                </DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    value={submissionData?.add_category}
                    onChange={updateFieldHandler("add_category")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!submissionData?.add_category) {
                        alert("Please enter a Category Name.");
                        return;
                      }

                      if (submissionData && submissionData?.add_category) {
                        await addCategoryForm({
                          variables: {
                            customer_id: customer_id,
                            add_category: submissionData?.add_category
                          }
                        });

                        setAddProductCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {UpdateProductCategory && (
              <Dialog
                open={Boolean(UpdateProductCategory)}
                onClose={() => setUpdateProductCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"

              >
                <DialogTitle id="alert-dialog-title">
                  Update Category
                </DialogTitle>
                <DialogContent>
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                  <label>Choose Category to Update</label>
                  <div>
                    <CustomSelect
                      value={UpdateCategory?.category_text ? UpdateCategory?.category_text : ""}
                      onChange={updateCategoryFieldHandler("category_text")}
                      data={CategoryData?.map(({ category_text }) => ({
                        value: category_text,
                        label: category_text
                      })) || []}
                      deleteable={false}
                    />
                  </div>
                  <div style={{ clear: "both", marginTop: "20px" }}></div>
                  <label>Update Category</label>
                  <TextField
                    fullWidth
                    value={UpdateCategory?.update_category || ""}
                    onChange={updateCategoryFieldHandler("update_category")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (UpdateCategory?.update_category === UpdateCategory?.category_text || !UpdateCategory?.update_category) {
                        alert("Please enter an updated Category Name.");
                        return;
                      }

                      if (UpdateCategory && UpdateCategory?.update_category && UpdateCategory?.category_text) {
                        await UpdateCategoryForm({
                          variables: {
                            customer_id: customer_id,
                            category_text: UpdateCategory?.category_text,
                            update_category: UpdateCategory?.update_category
                          }
                        });

                        setUpdateProductCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {DeleteProductCategory && (
              <Dialog
                open={Boolean(DeleteProductCategory)}
                onClose={() => setDeleteProductCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
              >
                <DialogTitle id="alert-dialog-title">
                  Delete Category
                </DialogTitle>
                <DialogContent>
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                  <label>Choose Category to Delete</label>
                  <div>
                    <CustomSelect
                      value={DeleteCategory || ""}
                      onChange={(e) => { setDeleteCategory(e.target.value); }}
                      data={CategoryData?.map(({ id, category_text }) => ({
                        value: id,
                        label: category_text
                      })) || []}
                      deleteable={true}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!DeleteCategory) {
                        alert("Please choose a Category to delete.");
                        return;
                      }

                      if (DeleteCategory) {
                        deleteCategory(DeleteCategory);

                        setDeleteProductCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Products;
