import React from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CheckForContracts = (row, index, object_key, CRMContract, isContractOpenCollapse, handleContractExpand) => {
  for (var i = 0; i < CRMContract.length; i++) {
    if (CRMContract[i][object_key] === row?.id) {
      return (<TableRow style={{ backgroundColor: "#F5F5F5" }}>
        <TableCell style={{ paddingTop: 10, paddingBottom: 10 }} colSpan={50}>
          <Table size="medium">
            <tbody>
              <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                <TableCell style={{ width: "100px" }}>
                  <IconButton aria-label="expand row" size="small" onClick={() => handleContractExpand(index)}>
                    {isContractOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>Contract</TableCell>
              </TableRow>
            </tbody>
          </Table>
        </TableCell>
      </TableRow>)
    }
  }
  return null;
};

export default CheckForContracts;
