import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

// @mui/icons-material
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Input from '@mui/material/Input';
import Button from "components/CustomButtons/Button";
import DeleteButton from '@mui/material/Button';
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import "assets/css/richtext_styles.css";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import UpdateCRMService from "views/Dashboard/UpdateCRMService";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  DELETE_SERVICE,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_SERVICES,
  ADD_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORIES,
  DELETE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setCategory, setSKU, setStock, crm_user_level, setPrice } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'center'}
                style={{ verticalAlign: "top" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Image" && column.Header !== "Stock" && column.Header !== "Delete" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Name" ? setName(event.target.value) :
                        column.Header === "SKU" ? setSKU(event.target.value) :
                          column.Header === "Category" ? setCategory(event.target.value) :
                            setPrice(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

const Services = () => {
  const [state] = useContext(Context);
  const [tabledata, setTabledata] = useState();
  const [page, setPage] = useState(0);
  const [currentService, setCurrentService] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [name, setName] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [SKU, setSKU] = React.useState('');
  const [Hover, setHover] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const crm_user_level = me?.readMe?.crm_user_level;
  const [AddServiceCategory, setAddServiceCategory] = useState(false);
  const [UpdateServiceCategory, setUpdateServiceCategory] = useState(false);
  const [DeleteServiceCategory, setDeleteServiceCategory] = useState(false);

  const [submissionData, setSubmissionData] = useState([]);
  const [UpdateCategory, setUpdateCategory] = useState([]);
  const [DeleteCategory, setDeleteCategory] = useState(null);
  const [CategoryData, setCategoryData] = useState(null);
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { loading, data: people_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Activity_data } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data, refetch } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      category,
      SKU,
      price,
      count: 0
    }
  });

  let { data: all_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: 1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  all_data = all_data?.ListServices || [];

  const { data: Category_Data, refetch: refetchCategories } = useQuery(GET_SERVICE_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  const [addCategoryForm] = useMutation(ADD_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setSubmissionData({});
      refetch();
      refetchCategories();
    }
  });

  const [UpdateCategoryForm] = useMutation(UPDATE_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchCategories();
    }
  });

  const [deleteCategoryForm] = useMutation(DELETE_SERVICE_CATEGORY, {
    skip: !customer_id,
    onCompleted: () => {
      refetchCategories();
    }
  });

  useEffect(() => {
    setTabledata(data?.ListServices || []);
    setCategoryData(Category_Data?.listServiceCategories || []);
  }, [data, Category_Data]);

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const deleteCategory = (id) => {
    deleteCategoryForm({
      variables: {
        customer_id,
        id: id
      }
    })
  }

  const updateCategoryFieldHandler = (key, convert) => e => {
    setUpdateCategory({
      ...UpdateCategory,
      [key]: convert ? convert(e.target.value) : e.target.value,
      ["update_category"]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const handleServiceCategoryOpen = row => {
    setAddServiceCategory(true);
  };

  const handleUpdateServiceCategoryOpen = row => {
    setUpdateServiceCategory(true);
  };

  const handleDeleteServiceCategoryOpen = row => {
    setDeleteServiceCategory(true);
  };

  const classes = useStyles();

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (tabledata?.length) {
    csvData.push(
      ...tabledata.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = tabledata.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setTabledata(newCustomers);
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_SERVICE,
    {
      onCompleted: ({ deleteService }) => {
        const newServices = tabledata.filter(({ id }) => id !== deleteService);
        setTabledata(newServices);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setCurrentService(row?.id ? row : {});
  };

  const handleClose = updated => () => {
    setCurrentService(null);
  };

  const columns = compact([
    {
      Header: "Image",
    },
    {
      Header: "Name",
    },
    {
      Header: "SKU",
    },
    {
      Header: "Category",
    },
    {
      Header: "Price",
    },
    {
      Header: "Delete",
    }
  ]);

  function Row(props) {
    const { data, handleOpen } = props;

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell align="center" style={{ width: "50px" }}><img src={row?.service_image} width="50px"></img></TableCell>
              <TableCell align="center">
                <>
                  {crm_user_level === "crm_admin" || crm_user_level === "crm_editor" ?
                    <a
                      href="#"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen(row);
                      }}
                    >
                      {row?.service_name}
                    </a>
                    : row?.service_name}
                </>
              </TableCell>
              <TableCell align="center"><div>{row?.service_sku}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.category_text}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.service_price ? parseFloat(row?.service_price?.replace(/,/g, '')).toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              }) : "0.00"}</div>
              </TableCell>
              <TableCell align="center">
                {crm_user_level === "crm_admin" && (
                  <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                    }
                  }}>
                    <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} />
                  </DeleteButton>
                )}
              </TableCell>
            </TableRow>
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = loading || updating || deleting || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <HandymanOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={tabledata || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                  disabled={isLoading}
                >
                  Add Service
                </Button>
              )}
              {/*
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Service Category
                </Button>
              )}
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleUpdateServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Service Category
                </Button>
              )}
              {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
                <Button
                  onClick={() => handleDeleteServiceCategoryOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Service Category
                </Button>
              )}
              */}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setSKU={setSKU}
                  setPrice={setPrice}
                  setCategory={setCategory}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={tabledata} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {currentService && (
              <Dialog
                open={Boolean(currentService)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCRMService
                    current={currentService}
                    setCurrentService={setCurrentService}
                    handleClose={handleClose}
                    refetch={refetch}
                    setservice_cost={setservice_cost}
                    service_cost={service_cost}
                    service_margin={service_margin}
                    setservice_margin={setservice_margin}
                    service_price={service_price}
                    setservice_price={setservice_price}
                    service_discount={service_discount}
                    setservice_discount={setservice_discount}
                    service_sale_price={service_sale_price}
                    setservice_sale_price={setservice_sale_price}
                    service_tax={service_tax}
                    setservice_tax={setservice_tax}
                  />
                </DialogContent>
              </Dialog>
            )}
            {AddServiceCategory && (
              <Dialog
                open={Boolean(AddServiceCategory)}
                onClose={() => setAddServiceCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                width="300px"
              >
                <DialogTitle id="alert-dialog-title">
                  Add Category
                </DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    value={submissionData?.add_category}
                    onChange={updateFieldHandler("add_category")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!submissionData?.add_category) {
                        alert("Please enter a Category Name.");
                        return;
                      }

                      if (submissionData && submissionData?.add_category) {
                        await addCategoryForm({
                          variables: {
                            customer_id: customer_id,
                            add_category: submissionData?.add_category
                          }
                        });

                        setAddServiceCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {UpdateServiceCategory && (
              <Dialog
                open={Boolean(UpdateServiceCategory)}
                onClose={() => setUpdateServiceCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"

              >
                <DialogTitle id="alert-dialog-title">
                  Update Category
                </DialogTitle>
                <DialogContent>
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                  <label>Choose Category to Update</label>
                  <div>
                    <CustomSelect
                      value={UpdateCategory?.category_text ? UpdateCategory?.category_text : ""}
                      onChange={updateCategoryFieldHandler("category_text")}
                      data={CategoryData?.map(({ category_text }) => ({
                        value: category_text,
                        label: category_text
                      })) || []}
                      deleteable={false}
                    />
                  </div>
                  <div style={{ clear: "both", marginTop: "20px" }}></div>
                  <label>Update Category</label>
                  <TextField
                    fullWidth
                    value={UpdateCategory?.update_category || ""}
                    onChange={updateCategoryFieldHandler("update_category")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (UpdateCategory?.update_category === UpdateCategory?.category_text || !UpdateCategory?.update_category) {
                        alert("Please enter an updated Category Name.");
                        return;
                      }

                      if (UpdateCategory && UpdateCategory?.update_category && UpdateCategory?.category_text) {
                        await UpdateCategoryForm({
                          variables: {
                            customer_id: customer_id,
                            category_text: UpdateCategory?.category_text,
                            update_category: UpdateCategory?.update_category
                          }
                        });

                        setUpdateServiceCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {DeleteServiceCategory && (
              <Dialog
                open={Boolean(DeleteServiceCategory)}
                onClose={() => setDeleteServiceCategory(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
              >
                <DialogTitle id="alert-dialog-title">
                  Delete Category
                </DialogTitle>
                <DialogContent>
                  <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                  <label>Choose Category to Delete</label>
                  <div>
                    <CustomSelect
                      value={DeleteCategory || ""}
                      onChange={(e) => { setDeleteCategory(e.target.value); }}
                      data={CategoryData?.map(({ id, category_text }) => ({
                        value: id,
                        label: category_text
                      })) || []}
                      deleteable={true}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!DeleteCategory) {
                        alert("Please choose a Category to delete.");
                        return;
                      }

                      if (DeleteCategory) {
                        deleteCategory(DeleteCategory);

                        setDeleteServiceCategory(false);
                      }
                      const notify = options => {
                        const key = enqueueSnackbar(options?.text, {
                          variant: options?.variant,
                          persist: true
                        });
                        setTimeout(() => {
                          closeSnackbar(key);
                        }, options?.delay || 2000);
                      }
                      notify({
                        text: "Information has been successfully submitted",
                        variant: "success"
                      });
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Services;
