/*!

WaterBearReports.com is Water Bear Marketing's Customer Facing Reporting Dashboard.

© 2022 Water Bear Marketing Advertising With Intelligence®, GeoDemo®, GeoIntetion®, ReEngage®, AdPantry®, and Water Bear Marketing® are Registered Trademarks of Water Bear Marketing Cleveland, OH 44116
800-341-7138 WaterBearMarketing.com

Thanks for checking our source code!  Here's a little something for you...

What do you do with an elephant with 3 balls?


...walk him and pitch to the rhino...

*/
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { SnackbarProvider } from "notistack";

import apolloClient from "config/createApolloClient";
import Meta from "components/Meta";
import Store from "./redux/store";
import AuthLayout from "layouts/Auth";
import RtlLayout from "layouts/RTL";
import AdminLayout from "layouts/Admin";
import LoginPage from "views/Pages/LoginPage";
import Page404 from "views/Pages/Page404";
import { SET_TOKEN } from "./redux/constants";
import { Context } from "./redux/store";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/scss/custom.scss";
import { ApolloProvider } from "@apollo/client";

import routes from "routes";
import AccountOverview from "views/Dashboard/AccountOverview";

const hist = createBrowserHistory();

const RoutesStack = () => {
  const [state, dispatch] = useContext(Context);
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const tokenInfo = localStorage.getItem("tokenInfo");
    if (tokenInfo) {
      dispatch({ type: SET_TOKEN, payload: JSON.parse(tokenInfo) });
    } else {
      dispatch({ type: SET_TOKEN, payload: {} });
    }
    setLoading(false);
  }, [dispatch]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop?.path?.replace("/", "")}
            key={key}
            element={<prop.component />}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getRoutesAdmin = (routes) => {
    return routes.map((prop, key) => {
      if (prop.permissions && prop.permissions.indexOf(user_level) === -1) {
        return null;
      }
      if (prop.collapse) {
        return getRoutesAdmin(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop?.path?.replace("/", "")}
            element={<prop.component />}
            key={key}
          >
            {(...props) => (
              <div className={classes.content} key={prop.path}>
                <div className={classes.container}>
                  {prop.component && <prop.component {...props} />}
                </div>
              </div>
            )}
          </Route>
        );
      } else {
        return null;
      }
    });
  };

  if (loading) return null;
  return (
    <>
      <BrowserRouter>
        <Meta />
        {state?.auth?.tokenInfo?.token ? (
          <Routes>
            <Route path="/rtl" element={<RtlLayout />} />
            <Route path="/admin/" element={<AdminLayout />}>
              <Route
                path="/admin/account-overview"
                element={<AccountOverview />}
              />
            </Route>
            <Route path="/admin/:filter?" element={<AdminLayout />}>
              {getRoutesAdmin(routes)}
            </Route>
            <Route path="/" element={<Navigate replace to="/admin/account-overview" />} />
            <Route path="/admin/auth/landing-page" element={<Navigate replace to="/admin/account-overview"/>}/>
            <Route path="/auth" element={<AuthLayout />}>
              {getRoutes(routes)}
            </Route>
            <Route path="/" element={<Navigate replace to="/auth/login" />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/auth" element={<AuthLayout />}>
              {getRoutes(routes)}
            </Route>
            <Route path="/" element={<Navigate replace to="/auth/login" />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
};

const generateClassName = createGenerateClassName({
  productionPrefix: "wbm",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Store>
    <StylesProvider generateClassName={generateClassName}>
      <ApolloProvider client={apolloClient}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <RoutesStack />
        </SnackbarProvider>
      </ApolloProvider>
    </StylesProvider>
  </Store>
);

// serviceWorker.unregister();
