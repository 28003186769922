import React, { useState } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from "components/CustomButtons/Button";

import UploadFile from "./UploadFile";
import Mapping from "./Mapping";
import Preview from "./Preview";

const steps = [
  "Upload File",
  "Mapping",
  "Preview and finish"
];

const CSVReader = ({ onLoaded, disabled, inputs }) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [header, setHeader] = useState(true);
  const [content, setContent] = useState(null);
  const [mapping, setMapping] = useState({});

  const startRow = header ? 1 : 0;
  const customers = content?.slice(startRow).map(( data ) => {
    const obj = {};
    Object.keys(mapping).forEach(key => {
      obj[mapping[key]] = data[key];
    })
    return obj;
  });
  const previewCustomers = customers?.slice(0, 3);

  const result = customers?.map((row) => {
    const obj = {};
    Object.keys(row)?.forEach((key, index) => {
      const input = inputs.find(({ field }) => key === field);
      obj[key] = row[key];
      if (input?.type === "select") {
        obj[key] = input?.options.find(
          ({ label }) => label === row[key]
        )?.value;
      }
      if (
        input?.inputProps?.type === "number" ||
        input?.inputProps?.type === "tel"
      ) {
        obj[key] = obj[key]
          ? `${obj[key]}`.split("-").join("")
          : "";
        if (input?.inputProps?.type === "number") {
          obj[key] = obj[key]
            ? parseFloat(obj[key])
            : 0;
        }
      }
    });
    return obj;
  });

  const onReset = () => {
    setHeader(true);
    setStep(0);
    setContent(null);
    setMapping({});
  };

  return (
    <span style={{ float: "right" }}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Import Data
        </DialogTitle>
        <DialogContent>
          {steps.map((stepLabel, index) => (
            <span key={stepLabel} style={{ fontSize: 15 }}>
              {index > 0 && <span style={{ margin: "0 10px" }}>{">"}</span>}
              <span style={{ fontWeight: step === index ? "bold" : "normal" }}>Step {index + 1}: {stepLabel}</span>
            </span>
          ))}
          <div style={{ marginTop: 10 }}>
            {step === 0 && (
              <UploadFile
                inputs={inputs}
                onLoaded={(content) => {
                  setContent(content);
                  setHeader(true);
                  setMapping({});
                }}
                content={content}
              />
            )}
            {step === 1 && (
              <Mapping
                content={content}
                inputs={inputs}
                setMapping={setMapping}
                mapping={mapping}
                header={header}
                setHeader={setHeader}
              />
            )}
            {step === 2 && (
              <Preview
                content={content}
                mapping={mapping}
                inputs={inputs}
                header={header}
                customers={previewCustomers}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {step !== 0 && (
            <Button
              color="primary"
              onClick={() => {
                setStep(step => step - 1);
              }}
              style={{ marginRight: "auto" }}
            >
              Back
            </Button>
          )}
          <Button
            onClick={() => {
              setOpen(false);
              onReset();
            }}
          >
            Cancel Import
          </Button>
          {step !== steps.length - 1 && (
            <Button
              color="primary"
              onClick={() => {
                let FoundCellOrEmail = false;
                let FoundFirstName = false;
                let FoundBusinessName = false;
                Object.keys(mapping).map(key => {
                  if (mapping[key] === "email" || mapping[key] === "cell_phone_number")
                    FoundCellOrEmail = true;
                  if (mapping[key] === "first_name")
                    FoundFirstName = true;
                  if (mapping[key] === "business_name")
                    FoundBusinessName = true;
                })

                if (step === 1 && FoundBusinessName === false && window.location.href.indexOf("admin/crmcustomers") > -1) {
                  alert("Business Name is require.");
                }else if (step === 1 && FoundBusinessName === false && window.location.href.indexOf("admin/leads") > -1) {
                  alert("Business Name is require.");
                }
                else if (step === 1 && FoundCellOrEmail === false &&
                  window.location.href.indexOf("admin/crmcustomers") === -1 && 
                  window.location.href.indexOf("admin/leads") === -1) {
                  alert("Email or Cell Phone is Required.");
                } else if (step === 1 && FoundFirstName === false &&
                  window.location.href.indexOf("admin/crmcustomers") === -1 && 
                  window.location.href.indexOf("admin/leads") === -1) {
                  alert("First Name is Required.");
                } else {
                  setStep(step => step + 1)
                }
              }
              }
              disabled={step === 0 && !content}
            >
              Next
            </Button>
          )}
          {step === steps.length - 1 && (
            <Button
              color="primary"
              onClick={() => {
                onLoaded(result);
                setOpen(false);
                onReset();
              }}
            >
              Start Import
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        disabled={disabled}
      >
        Upload
      </Button>
    </span>
  );
};

export default CSVReader;
