import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { compact } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Input from "@mui/material/Input";
import Rating from "@mui/material/Rating";
import DeleteButton from "@mui/material/Button";

import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

import LinearNoContentWrapper from "components/LinearNoContentWrapper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumpster, faDumpsterFire } from "@fortawesome/pro-regular-svg-icons";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import {
  LIST_CUSTOMER_REPRESENTATIVES,
  DELETE_CUSTOMER_REPRESENTATIVES,
  LIST_CUSTOMER_EMAILS,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_DOCUMENT,
  LIST_PHOTOS,
  UPDATE_CRM_PHOTO,
  LIST_SERVICES,
  LIST_PRODUCT,
  LIST_CUSTOMERS,
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { READ_ME } from "queries/users";
import { Context } from ".././../../redux/store";

import SearchResults from "./SearchResults";
import UpdateCRMProduct from "views/Dashboard/UpdateCRMProduct";
import UpdateCRMService from "views/Dashboard/UpdateCRMService";
import UpdateRepresentativeProfile from "views/Dashboard/UpdateRepresentativeProfile";
import FormatPhone from "../Format/FormatPhone";
import { useLocation } from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles);

const columns = compact([
  {
    Header: "Name",
    accessor: "first_name",
    minWidth: 150,
  },
  {
    Header: "Job Title",
    accessor: "job_title",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Cell",
    accessor: "cell_phone_number",
    optional: true,
  },
  {
    Header: "Rating",
    accessor: "rating",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Office Phone",
    accessor: "office_phone_number",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Office Ext",
    accessor: "office_extension",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Delete",
    accessor: "delete",
    width: 100,
    sortable: false,
  },
]);

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setPageName,
    setTitle,
    setPageEmail,
    setCell,
    setBio,
    setRating,
    setPagefficePhone,
    setOfficeExt,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }}>
        {columns.map(
          (column) =>
            column.Header !== "" && (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={"center"}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : "asc"}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                <Input
                  style={{
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderBottom: "6px",
                    width: "100px",
                  }}
                  type="text"
                  onChange={(event) => {
                    column.Header === "Name"
                      ? setPageName(event.target.value)
                      : column.Header === "Job Title"
                        ? setTitle(event.target.value)
                        : column.Header === "Email"
                          ? setPageEmail(event.target.value)
                          : column.Header === "Cell"
                            ? setCell(event.target.value)
                            : column.Header === "Bio"
                              ? setBio(event.target.value)
                              : column.Header === "Rating"
                                ? setRating(event.target.value)
                                : column.Header === "Office Phone"
                                  ? setPagefficePhone(event.target.value)
                                  : setOfficeExt(event.target.value);
                  }}
                ></Input>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const Representatives = (props) => {
  const history = useLocation();
  const [state] = useContext(Context);
  const [customers, setCustomers] = useState([]);
  const [name, setName] = React.useState("");
  const [value, setValue] = React.useState("");
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [SKU, setSKU] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officephone, setOfficePhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("");
  const [service, setService] = React.useState("");
  const [representative, setRepresentative] = React.useState("");
  const [searchrepresentative, setSearchRepresentative] = React.useState("");
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMCustomersSearch, setCRMCustomersSearch] = useState(null);
  const [CRMLeadsSearch, setCRMLeadsSearch] = useState(null);
  const [ShowAll, setShowAll] = useState(true);
  const [ShowCustomers, setShowCustomers] = useState(false);
  const [ShowLeads, setShowLeads] = useState(false);
  const [ShowPeople, setShowPeople] = useState(false);
  const [ShowJobs, setShowJobs] = useState(false);
  const [ShowGalleries, setShowGalleries] = useState(false);
  const [ShowPhotos, setShowPhotos] = useState(false);
  const [ShowProducts, setShowProducts] = useState(false);
  const [ShowServices, setShowServices] = useState(false);
  const [ShowContracts, setShowContracts] = useState(false);
  const [ShowDocuments, setShowDocuments] = useState(false);
  const [ShowProposals, setShowProposals] = useState(false);
  const [ShowReps, setShowReps] = useState(false);
  const [CRMPeopleSearch, setCRMPeopleSearch] = useState(null);
  const [CRMJobSearch, setCRMJobSearch] = useState(null);
  const [CRMGallerySearch, setCRMGallerySearch] = useState(null);
  const [CRMPhotosSearch, setCRMPhotosSearch] = useState(null);
  const [CRMDocumentSearch, setCRMDocumentSearch] = useState(null);
  const [CRMContractSearch, setCRMContractSearch] = useState(null);
  const [CRMProposalSearch, setCRMProposalSearch] = useState(null);
  const [CRMProductSearch, setCRMProductSearch] = useState(null);
  const [CRMServiceSearch, setCRMServiceSearch] = useState(null);
  const [CRMRepresentativeSearch, setCRMRepresentativeSearch] = useState(null);
  const [UpdatePhoto, setUpdatePhoto] = useState([]);
  const [searchstring, setsearchstring] = useState(null);
  const [updateRep, setupdateRep] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);

  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [Pagename, setPageName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Pageemail, setPageEmail] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [rating, setRating] = React.useState("");
  const [Pageofficephone, setPagefficePhone] = React.useState("");
  const [officeext, setOfficeExt] = React.useState("");
  const [Hover, setHover] = useState(null);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');
  const [B2B, setB2B] = useState('');
  const [placeholderText, setplaceholderText] = useState("   \uf002 Search");
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { loading, data, refetch } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: Pagename ? Pagename : "",
      title: title ? title : "",
      email: Pageemail ? Pageemail : "",
      cell: cell ? cell : "",
      bio: bio ? bio : "",
      rating: rating ? rating : "",
      officephone: Pageofficephone ? Pageofficephone : "",
      officeext: officeext ? officeext : "",
    },
  });

  let { data: all_data, refetch: refetchtotal } = useQuery(
    LIST_CUSTOMER_REPRESENTATIVES,
    {
      skip: !customer_id,
      variables: {
        customer_id: customer_id,
        count: 1,
      },
    }
  );

  all_data = all_data?.listCustomerRepresentatives || [];

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    },
  });

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear;hydrate"
    }
  });

  useEffect(() => {
    setCustomers(
      data?.listCustomerRepresentatives?.filter(
        (CurrentRep) =>
          CurrentRep?.first_name !== "Not" &&
          CurrentRep?.first_name !== "Assigned"
      )
    );
    setCRMPhotos(Photo_data?.ListPhotos || []);

    setLinearCustomer(budget_data?.getBudget?.monthly_budget);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [data, Photo_data]);

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CUSTOMER_REPRESENTATIVES,
    {
      onCompleted: () => {
        const notify = (options) => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true,
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        };
        notify({
          text: "Information has been successfully submitted",
          variant: "success",
        });

        refetch();
        refetchtotal();
      },
    }
  );

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOpen = (row) => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = (updated) => {
    if (updated) {
      const notify = (options) => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true,
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      };
      notify({
        text: "Information has been successfully submitted",
        variant: "success",
      });
    }

    refetch();
    refetchtotal();
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  let { loading: Customerloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 1,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    },
  });

  const [CustomerSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    },
  });

  let { loading: Leadsloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    },
  });

  const [LeadSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    },
  });

  let { loading: Peopleloading } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      cell_phone_number: phone,
      name,
      email,
      address,
      representative: searchrepresentative,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    },
  });

  const [PeopleSearchQuery] = useLazyQuery(LIST_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    },
  });

  let { loading: Jobsloading } = useQuery(LIST_JOBS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      value,
      name,
      page: 0,
      rows_per_page: 3,
      representative: searchrepresentative,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    },
  });

  const [JobSearchQuery] = useLazyQuery(LIST_JOBS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    },
  });

  let { loading: Galleryloading } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    },
  });

  const [GallerySearchQuery] = useLazyQuery(LIST_PHOTO_GALLERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    },
  });

  let { loading: Photosloading } = useQuery(LIST_PHOTOS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      description,
      count: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    },
  });

  const [PhotosSearchQuery] = useLazyQuery(LIST_PHOTOS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    },
  });

  let { loading: Documentsloading } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    },
  });

  const [DocumentSearchQuery] = useLazyQuery(LIST_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    },
  });

  let { loading: Contractsloading } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    },
  });

  const [ContractSearchQuery] = useLazyQuery(LIST_CONTRACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    },
  });

  let { loading: Proposalsloading } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1,
    },
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    },
  });

  const [ProposalSearchQuery] = useLazyQuery(LIST_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    },
  });

  const { refetch: productrefetch, loading: Productsloading } = useQuery(
    LIST_PRODUCT,
    {
      skip: !customer_id || !name,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        price: !isNaN(+price) ? price : "",
        count: 0,
        search: 1,
      },
      onCompleted: (data) => {
        setCRMProductSearch(data?.ListProduct || []);
      },
    }
  );

  const [ProductSearchQuery] = useLazyQuery(LIST_PRODUCT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    },
  });

  const { refetch: servicesrefetch, loading: Servicesloading } = useQuery(
    LIST_SERVICES,
    {
      skip: !customer_id || !name,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        count: 0,
        search: 1,
      },
      onCompleted: (data) => {
        setCRMServiceSearch(data?.ListServices || []);
      },
    }
  );

  const [ServiceSearchQuery] = useLazyQuery(LIST_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    },
  });

  let { loading: Repsloading } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id || !name,
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      name: name ? name : "",
      search: 1,
    },
    onCompleted: (data) => {
      setCRMRepresentativeSearch(
        data?.listCustomerRepresentatives?.filter(
          (CurrentRep) =>
            CurrentRep?.first_name !== "Not" &&
            CurrentRep?.first_name !== "Assigned"
        ) || []
      );
    },
  });

  const [RepresentativeSearchQuery] = useLazyQuery(
    LIST_CUSTOMER_REPRESENTATIVES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCRMRepresentativeSearch(
          data?.listCustomerRepresentatives?.filter(
            (CurrentRep) =>
              CurrentRep?.first_name !== "Not" &&
              CurrentRep?.first_name !== "Assigned"
          ) || []
        );
      },
    }
  );

  const handleSearchClose = () => () => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
  };

  const handleProductOpen = (row) => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentProduct(row?.id ? row : {});
  };

  const handleServiceOpen = (row) => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentService(row?.id ? row : {});
  };

  const handleRepOpen = (row) => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setupdateRep(row || {});
  };

  const handleRepClose = (updated) => {
    if (updated) {
      const notify = (options) => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true,
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      };
      notify({
        text: "Information has been successfully submitted",
        variant: "success",
      });
    }
    setupdateRep(null);
    refetch();
    refetchtotal();
  };

  const handleServiceClose = (updated) => () => {
    const notify = (options) => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true,
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    };
    notify({
      text: "Information has been successfully submitted",
      variant: "success",
    });

    setCurrentService(null);
  };

  const handleProductClose = () => () => {
    const notify = (options) => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true,
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    };
    notify({
      text: "Information has been successfully submitted",
      variant: "success",
    });

    setCurrentProduct(null);
    refetch();
  };

  const OpenPhotoEdit = (id) => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setUpdatePhoto(
      CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map(
        (CurrentPhoto) => {
          return CurrentPhoto;
        }
      )?.length
        ? CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map(
          (CurrentPhoto) => {
            return CurrentPhoto;
          }
        )[0]
        : []
    );
  };

  const updatePhotoFieldHandler = (key) => (e) => {
    setUpdatePhoto({
      ...UpdatePhoto,
      [key]: e.target.value,
    });
  };

  const handleSave = async () => {
    if (
      UpdatePhoto?.photo_name === undefined ||
      UpdatePhoto?.photo_name === ""
    ) {
      alert("Photo Name is required");
      return false;
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (UpdatePhoto) {
      await submitUpdateCRMPhotoRequest({
        variables: {
          customer_id,
          photo_name: UpdatePhoto?.photo_name,
          photo_description: UpdatePhoto?.photo_description,
          photo_alt_text: UpdatePhoto?.photo_alt_text,
          id: UpdatePhoto?.id,
        },
      });
    }

    const notify = (options) => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true,
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    };
    notify({
      text: "Information has been successfully submitted",
      variant: "success",
    });

    return true;
  };

  const [submitUpdateCRMPhotoRequest] = useMutation(UPDATE_CRM_PHOTO, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setUpdatePhoto([]);
    },
  });

  const updateSearchHandler = () => (e) => {
    setsearchstring(e.target.value);

    if (e.target.value.length >= 2) {
      setName(e.target.value);
      setAddress(e.target.value);
      setPhone(e.target.value);
      setEmail(e.target.value);
      setOfficePhone(e.target.value);
      setService(e.target.value);
      setType(e.target.value);
      setDescription(e.target.value);
      setStatus(e.target.value);
      setSearchRepresentative(e.target.value);
      setValue(e.target.value);
      setStart(e.target.value);
      setEnd(e.target.value);
      setCategory(e.target.value);
      setSKU(e.target.value);
      setPrice(e.target.value);
    } else {
      setName(null);
      setAddress(null);
      setPhone(null);
      setSearchRepresentative(null);
      setEmail(null);
      setOfficePhone(null);
      setService(null);
      setType(null);
      setDescription(null);
      setStatus(null);
      setValue(null);
      setStart(null);
      setEnd(null);
      setCategory(null);
      setSKU(null);
      setPrice(null);

      setCRMCustomersSearch([]);
      setCRMLeadsSearch([]);
      setCRMPeopleSearch([]);
      setCRMJobSearch([]);
      setCRMGallerySearch([]);
      setCRMPhotosSearch([]);
      setCRMDocumentSearch([]);
      setCRMContractSearch([]);
      setCRMProposalSearch([]);
      setCRMProductSearch([]);
      setCRMServiceSearch([]);
      setCRMRepresentativeSearch([]);
    }
  };

  const updateSearchALL = () => {
    if (B2B === "B2B") {
      CustomerSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 1,
          search: 1,
        },
      });

      LeadSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 0,
          search: 1,
        },
      });
    }

    PeopleSearchQuery({
      variables: {
        customer_id,
        count: 0,
        page: 0,
        rows_per_page: 3,
        cell_phone_number: phone,
        name,
        email,
        address,
        representative: searchrepresentative,
        search: 1,
      },
    });

    JobSearchQuery({
      variables: {
        customer_id,
        count: 0,
        type,
        name,
        service,
        page: 0,
        rows_per_page: 3,
        representative: searchrepresentative,
        search: 1,
      },
    });

    GallerySearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1,
      },
    });

    PhotosSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1,
      },
    });

    DocumentSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        representative: searchrepresentative,
        count: 0,
        search: 1,
      },
    });

    ContractSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1,
      },
    });

    ProposalSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1,
      },
    });

    ProductSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        price,
        count: 0,
        search: 1,
      },
    });

    ServiceSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        count: 0,
        search: 1,
      },
    });

    RepresentativeSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        count: 0,
        search: 1,
      },
    });
  };

  const isLoading = loading || !customer_id;

  return (
    <>
    {LinearCustomer || LinearCustomer === undefined ?
      <>
      {history.pathname.indexOf("representativeshydrate") === -1 && (
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ width: "40%", float: "left" }}>
                  <label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Representatives List</label>
                </div>
                <div style={{ width: "40%", float: "left" }}>
                  <input
                    id="search"
                    placeholder={placeholderText}
                    type="text"
                    value={searchstring || ""}
                    autoFocus
                    onChange={updateSearchHandler()}
                    onClick={() => setplaceholderText("")}
                    onBlur={() => setplaceholderText("   \uf002 Search")}
                    style={{ borderColor: "#b7b7b7", border: "3px #0071CE solid", borderRadius: "100px", height: "50px", width: "300px", fontFamily: 'FontAwesome, Inter', fontSize: "18px", color: "#AAAAAA" }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {SearchResults(handleSearchClose, updateSearchALL, setShowAll, ShowAll, setShowCustomers, setShowLeads, setShowPeople, setShowJobs,
            setShowGalleries, setShowPhotos, setShowProducts, setShowServices, setShowContracts, setShowDocuments,
            setShowProposals, setShowReps, CustomerSearchQuery, LeadSearchQuery, PeopleSearchQuery, JobSearchQuery,
            ProductSearchQuery, ServiceSearchQuery, GallerySearchQuery, DocumentSearchQuery, PhotosSearchQuery,
            RepresentativeSearchQuery, ProposalSearchQuery, ContractSearchQuery,
            ShowCustomers, ShowLeads, ShowPeople, ShowJobs, ShowGalleries, ShowPhotos, ShowProducts, ShowServices, ShowContracts,
            ShowDocuments, ShowProposals, ShowReps,
            searchstring, CRMCustomersSearch, CRMLeadsSearch, CRMPeopleSearch, CRMJobSearch, CRMGallerySearch,
            CRMDocumentSearch, CRMContractSearch, CRMProposalSearch, CRMProductSearch, CRMServiceSearch, CRMRepresentativeSearch,
            CRMPhotosSearch, name, value, start, end, status, category, SKU, price, description, phone, address, officephone,
            email, type, service, representative, searchrepresentative,
            setCRMCustomersSearch, setCRMLeadsSearch, setCRMPeopleSearch, setCRMJobSearch, setCRMGallerySearch,
            setCRMPhotosSearch, setCRMDocumentSearch, setCRMContractSearch, setCRMProposalSearch, setCRMProductSearch,
            setCRMServiceSearch, setCRMRepresentativeSearch, Customerloading, Leadsloading, Peopleloading,
            Jobsloading, Galleryloading, Photosloading, Documentsloading, Contractsloading, Proposalsloading, Productsloading,
            Servicesloading, Repsloading, customer_id,
            handleProductOpen, handleServiceOpen, handleRepOpen, OpenPhotoEdit, B2B)}
        </>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="trackedKeywords" icon>
              <CardIcon>
                <AssignmentIndOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {history.pathname.indexOf("representativeshydrate") > -1 ? "Representatives" : ""}
                <Button
                  onClick={() => handleOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                  disabled={isLoading}
                >
                  Add Representative
                </Button>
              </h4>
            </CardHeader>
            <CardBody>
              <TablePagination
                component="div"
                count={all_data.length - 1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
              />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    setPageName={setPageName}
                    setTitle={setTitle}
                    setPageEmail={setPageEmail}
                    setCell={setCell}
                    setBio={setBio}
                    setRating={setRating}
                    setPagefficePhone={setPagefficePhone}
                    setOfficeExt={setOfficeExt}
                  />
                  <TableBody>
                    {customers?.map((datarow) => (
                      <TableRow key={datarow?.length}>
                        <TableCell><a
                          href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen(datarow);
                          }}
                        >
                          {datarow?.first_name}&nbsp;{datarow?.last_name}
                        </a></TableCell>
                        <TableCell>{datarow?.job_title}</TableCell>
                        <TableCell>{datarow?.email}</TableCell>
                        <TableCell>{FormatPhone(datarow?.cell_phone_number)}</TableCell>
                        <TableCell><Rating name="half-rating" defaultValue={datarow?.rating} precision={0.5} readOnly />
                          {/*<Rating
                            name="simple-controlled"
                            value={datarow?.rating}
                            precision={0.5}
                            onChange={(event, newValue) => {
                              submitUpdateRequest({ variables: { customer_id: customer_id, CustomerRepresentative: { id: datarow?.id, rating: newValue } } });
                            }}
                          />*/}
                            </TableCell>
                            <TableCell>
                              {FormatPhone(datarow?.office_phone_number)}
                            </TableCell>
                            <TableCell>{datarow?.office_extension}</TableCell>
                            <TableCell>
                              <DeleteButton
                                style={{
                                  color: Hover === datarow?.id ? "red" : "#000",
                                  float: "right",
                                  backgroundColor: "#FFF",
                                }}
                                onMouseOver={() => {
                                  setHover(datarow?.id);
                                }}
                                onMouseLeave={() => {
                                  setHover(null);
                                }}
                                onClick={() => {
                                  if (window.confirm("Are you sure?")) {
                                    submitDeleteRequest({
                                      variables: {
                                        customer_id: customer_id,
                                        id: datarow?.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={
                                    Hover === datarow?.id
                                      ? faDumpsterFire
                                      : faDumpster
                                  }
                                  onMouseOver={() => {
                                    setHover(datarow?.id);
                                  }}
                                  onMouseLeave={() => {
                                    setHover(null);
                                  }}
                                />
                              </DeleteButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {updatedCustomerEmail && (
                    <Dialog
                      open={Boolean(updatedCustomerEmail)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateRepresentativeProfile
                          current={currentCustomerEmail}
                          updated={updatedCustomerEmail}
                          handleClose={handleClose}
                          setCustomers={setCustomers}
                          customers={customers}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                </CardBody>
              </Card>
              {updateRep && (
                <Card>
                  <CardBody>
                    <Dialog
                      open={Boolean(updateRep)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateRepresentativeProfile
                          current={updateRep}
                          handleClose={handleRepClose}
                          setCustomers={setCRMCustomers}
                          customers={CRMCustomers}
                        />
                      </DialogContent>
                    </Dialog>
                  </CardBody>
                </Card>
              )}
              {currentProduct && (
                <Card>
                  <CardBody>
                    <Dialog
                      open={Boolean(currentProduct)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCRMProduct
                          current={currentProduct}
                          setCurrentProduct={setCurrentProduct}
                          handleClose={handleProductClose}
                          refetch={productrefetch}
                          setproduct_cost={setproduct_cost}
                          product_cost={product_cost}
                          product_margin={product_margin}
                          setproduct_margin={setproduct_margin}
                          product_price={product_price}
                          setproduct_price={setproduct_price}
                          product_discount={product_discount}
                          setproduct_discount={setproduct_discount}
                          product_sale_price={product_sale_price}
                          setproduct_sale_price={setproduct_sale_price}
                          product_tax={product_tax}
                          setproduct_tax={setproduct_tax}
                        />
                      </DialogContent>
                    </Dialog>
                  </CardBody>
                </Card>
              )}
              {currentService && (
                <Card>
                  <CardBody>
                    <Dialog
                      open={Boolean(currentService)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCRMService
                          current={currentService}
                          setCurrentService={setCurrentService}
                          handleClose={handleServiceClose}
                          refetch={servicesrefetch}
                          setservice_cost={setservice_cost}
                          service_cost={service_cost}
                          service_margin={service_margin}
                          setservice_margin={setservice_margin}
                          service_price={service_price}
                          setservice_price={setservice_price}
                          service_discount={service_discount}
                          setservice_discount={setservice_discount}
                          service_sale_price={service_sale_price}
                          setservice_sale_price={setservice_sale_price}
                          service_tax={service_tax}
                          setservice_tax={setservice_tax}
                        />
                      </DialogContent>
                    </Dialog>
                  </CardBody>
                </Card>
              )}
              {UpdatePhoto?.id && (
                <Card>
                  <CardBody>
                    <Dialog
                      open
                      onClose={() => handleSave()}
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogContent>
                        <div>
                          <img
                            src={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format`}
                            srcSet={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={UpdatePhoto?.photo_alt_text || ""}
                            loading="lazy"
                            style={{
                              maxHeight: "200px",
                              margin: "0 auto",
                              display: "block",
                            }}
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }}>
                          <label style={{ marginTop: "30px" }}>
                            Photo Name&nbsp;
                            <div style={{ color: "red", float: "right" }}>*</div>
                          </label>
                          <input
                            id="photo_name"
                            maxLength={45}
                            type="text"
                            defaultValue={UpdatePhoto?.photo_name || ""}
                            onChange={updatePhotoFieldHandler("photo_name")}
                            style={{
                              borderColor: "#b7b7b7",
                              borderBottom: "1px !",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }}>
                          <label style={{ marginTop: "30px" }}>Description</label>
                          <textarea
                            id="description"
                            type="text"
                            defaultValue={UpdatePhoto?.photo_description || ""}
                            onChange={updatePhotoFieldHandler("photo_description")}
                            style={{
                              borderColor: "#b7b7b7",
                              borderBottom: "1px !",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }}>
                          <label style={{ marginTop: "30px" }}>Alt Text</label>
                          <textarea
                            id="photo_alt_text"
                            type="text"
                            defaultValue={UpdatePhoto?.photo_alt_text || ""}
                            onChange={updatePhotoFieldHandler("photo_alt_text")}
                            style={{
                              borderColor: "#b7b7b7",
                              borderBottom: "1px !",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                          onClick={() => {
                            handleSave();
                          }}
                        >
                          Save
                        </Button>
                      </DialogContent>
                    </Dialog>
                  </CardBody>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

export default Representatives;
