import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

//import { API_URL } from "config";

import defaultAvatar from "assets/img/default-avatar.png";

const Avatar = ({ userId, url, loading, size }) => {
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (!userId) return;
    const generateGetUrl = `https://api.waterbearreports.com/generate-get-url`;
    const options = {
      params: {
        Key: `avatars/${userId}`,
        ContentType: "image/jpeg"
      }
    };
    axios.get(generateGetUrl, options).then(res => {
      const { data: getURL } = res;
      setGeneratedUrl(getURL);
      setStatus("loading");
    });
  }, [userId]);
  const style = { width: size, height: size, objectFit: "cover" };

  if (!url && (status === "failed" || !generatedUrl)) {
    return <img src={defaultAvatar} alt="..." style={style} key={1} />;
  }

  return (
    <img
      style={loading ? { opacity: 0.5, ...style } : style}
      src={url || generatedUrl}
      alt="..."
      onError={() => setStatus("failed")}
      onLoad={() => setStatus("loaded")}
      key={2}
    />
  );
};

Avatar.propTypes = {
  userId: PropTypes.number,
  url: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.number
};

Avatar.defaultProps = {
  size: 130
};

export default Avatar;
