import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, useLocation } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import { LIST_CUSTOMERS } from "queries/customers";

import { Context } from "../../redux/store";

const Links = ({
  classes,
  color,
  rtlActive,
  miniActive,
  routes,
  getCollapseInitialState,
  onSelect
}) => {
  const history = useLocation();
  const [state] = useContext(Context);
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const crm_user_level = state?.auth?.tokenInfo?.user?.crm_user_level;
  const [collapseState, setCollapseState] = useState({});
  const activeRoute = routeName => {
    return window.location.pathname.endsWith(routeName) ? "active" : "";
  };

  let B2B = null;

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  if (B2B === null)
    B2B = customer_data?.listCustomers[0];

  const createLinks = routes =>
    routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.permissions && prop.permissions.indexOf(user_level) === -1) {
        return null;
      }

      if (prop.ignore || ((prop.path === "/leads" || prop.path === "/crmcustomers") && B2B?.B2B === 0)) return null;
      if (prop.hidden && user_level !== "master") return null;
      if (prop.collapse) {
        const st = {
          ...collapseState
        };
        st[prop["state"]] =
          collapseState[prop.state] === undefined
            ? !getCollapseInitialState(prop.views)
            : !collapseState[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: !st[prop["state"]]
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: miniActive,
            [classes.itemTextMiniRTL]: rtlActive && miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: miniActive,
            [classes.collapseItemTextMiniRTL]: rtlActive && miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        return (
          <ListItem
            key={key}
            style={{ display: 'block', paddingTop: "5px", paddingBottom: "5px", width: "245px", paddingLeft: "10px" }}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                setCollapseState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret + " " + (!st[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse
              in={
                collapseState[prop.state] === undefined
                  ? getCollapseInitialState(prop.views)
                  : collapseState[prop.state]
              }
              unmountOnExit
            >
              <List className={classes.list + " " + classes.collapseList}>
                {createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [classes.collapseActive]: activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: miniActive,
          [classes.itemTextMiniRTL]: rtlActive && miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: miniActive,
          [classes.collapseItemTextMiniRTL]: rtlActive && miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });
      const bigItem = Boolean(prop.state);
      return (
        <ListItem
          style={{ display: 'block', paddingTop: "5px", paddingBottom: "5px", width: "245px", paddingLeft: "10px" }}
          key={key}
          className={cx(
            { [classes.item]: bigItem },
            { [classes.collapseItem]: !bigItem }
          )}
        >
          <NavLink
            to={prop.layout + prop.path + history.search}
            className={cx(
              { [navLinkClasses]: bigItem },
              { [innerNavLinkClasses]: !bigItem }
            )}
            onClick={onSelect}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: bigItem },
                { [collapseItemText]: !bigItem }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });

  const links = createLinks(routes);
  return <List className={classes.list}>{links}</List>;
};

Links.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape(),
  color: PropTypes.string,
  rtlActive: PropTypes.bool,
  miniActive: PropTypes.bool,
  getCollapseInitialState: PropTypes.func,
  onSelect: PropTypes.func
};

export default Links;
