import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
// core components
import Button from "components/CustomButtons/Button";
import CloseIcon from '@mui/icons-material/Close';
import DeleteButton from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import CSVReader from "components/CSVReader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  DELETE_PERSON,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_SERVICES,
  UPLOAD_CUSTOMER_EMAILS,
  COMMIT_CUSTOMER_EMAILS,
  UPLOAD_OVERRIDE_CUSTOMER_EMAILS,
  GET_STAGING_DUPLICATES,
  DELETE_STAGING_CUSTOMER_EMAILS,
  DELETE_STAGING_CRM_CUSTOMERS
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

const moment = require("moment");


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setPhone, setEmail, setOfficePhone, setAddress, setRepresentative, crm_user_level, setLeadStatus, setlist_name } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'center'}
                style={{ verticalAlign: "top" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Last Activity" && column.Header !== "Delete" ?
                  <Input 
                  placeholder={"Search"}
                  style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                    column.Header === "Name" ? setName(event.target.value) :
                      column.Header === "Cell" ? setPhone(event.target.value) : column.Header === "Email" ? setEmail(event.target.value) :
                        column.Header === "Office Phone Number" ? setOfficePhone(event.target.value) 
                        : column.Header === "List Name" ? setlist_name(event.target.value)
                        : column.Header === "Lead Status" ? setLeadStatus(event.target.value)
                          : setRepresentative(event.target.value)
                  }}></Input> : ""}
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

const People = () => {
  const [state] = useContext(Context);
  const [tabledata, setTabledata] = useState();
  const [DuplicatePeopledata, setDuplicatePeopledata] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('create_time');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [officephone, setOfficePhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [list_name, setlist_name] = React.useState('');  
  const [LeadStatus, setLeadStatus] = React.useState('');  
  const [representative, setRepresentative] = React.useState('');
  const [CRMServices, setCRMServices] = useState(null);
  const [Count, setCount] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [Hover, setHover] = useState(null);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [addedCount, setAddedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [CheckingForDuplicates, setCheckingForDuplicates] = useState(false);
  const [ShowDuplicateFile, setShowDuplicateFile] = useState(false);
  const [ShowOverrideLoading, setShowOverrideLoading] = useState(false);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.readMe?.crm_user_level;

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { loading, data, refetch } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      cell_phone_number: phone,
      name,
      email,
      address,
      ListName: list_name,
      office_phone_number: officephone,
      representative,
      lead_status_text: LeadStatus
    }
  });

  let { data: all_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1
    }
  });

  let { data: Activity_data } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data, refetch: CRM_Customers_refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const [uploadCustomerEmails] = useMutation(
    UPLOAD_CUSTOMER_EMAILS);

  const [uploadOverrideCustomerEmails] = useMutation(
    UPLOAD_OVERRIDE_CUSTOMER_EMAILS);

  const [getStagingDuplicates] = useLazyQuery(GET_STAGING_DUPLICATES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStagingDuplicates }) => {
      setDuplicatePeopledata(getStagingDuplicates);
      if (getStagingDuplicates?.length > 0) {
        setShowDuplicateFile(true);
      }

      setCheckingForDuplicates(false);
    }
  });

  const [commitCustomerEmails] = useMutation(
    COMMIT_CUSTOMER_EMAILS,
    {
      onCompleted: () => {
        setUploadedCount(0);
        setAddedCount(0);
        refetch();
        CRM_Customers_refetch();
      }
    }
  );

  const [deleteStagingCustomerEmails] = useMutation(
    DELETE_STAGING_CUSTOMER_EMAILS
  );

  const [deleteStagingCRMCustomers] = useMutation(
    DELETE_STAGING_CRM_CUSTOMERS
  );

  useEffect(() => {
    setTabledata(data?.listCustomerEmails || []);
    setCount(all_data?.listCustomerEmails[0]?.count || 0)
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMActivity(Activity_data?.ListActivity || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);

  }, [data, crm_data, Activity_data, Representative_data,
    Proposal_data, Contract_data, Job_data,
    Photo_Gallery_data, document_data, Photo_data]);

  const classes = useStyles();

  const listNames = [
    "Water Bear Marketing",
    "Employment",
    "From Website",
    "From CMS/ERP",
    "Purchased",
    "Tradeshows",
    "Database",
    "Lead Generation",
    "Walk-In",
    "Customize"
  ].map(v => ({
    label: v,
    value: v
  }));

  const onCSVLoaded = async (CustomerEmailsdata) => {
    CustomerEmailsdata = CustomerEmailsdata?.filter(CurrentCustomer =>
      CurrentCustomer?.first_name !== '')

    setTotalCount(CustomerEmailsdata.length);
    setCsvUploadOpen(true);
    const size = 100;
    const upload = async (start) => {
      if (start >= CustomerEmailsdata.length) {
        setCsvUploadOpen(false);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        return;
      }
      await uploadCustomerEmails({
        fetchPolicy: "no-cache",
        variables: {
          customer_id,
          customerEmails: CustomerEmailsdata.slice(start, start + size)
        }
      }).then(async ({ data: { uploadCustomerEmails: { count, inserted } } }) => {
        setUploadedCount(u => u + count);
        setAddedCount(a => a + inserted);
        await upload(start + size);
      });
    };

    // delete rows from the hydrate_staging_customer_list_1 table
    await deleteStagingCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    // delete rows from the hydrate_customer_list_ table
    await deleteStagingCRMCustomers({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });



    await upload(0);

    // now select staging data and compare to hydrate customer list
    // if record exists leave it in stage for duplicate csv
    // if the key is not found insert into hydrate customer list
    // and remove it from staging


    setCheckingForDuplicates(true);

    await getStagingDuplicates({
      variables: {
        customer_id
      }
    })

    await commitCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: () => {
        refetch();
        CRM_Customers_refetch();
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_PERSON,
    {
      onCompleted: ({ deleteCRMPerson }) => {
        const newCustomers = tabledata.filter(({ id }) => id !== deleteCRMPerson);
        setTabledata(newCustomers);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);

    refetch();
    CRM_Customers_refetch();
  };

  const columns = compact([
    {
      Header: "Name",
      accessor: "first_name"
    },
    {
      Header: "Cell",
      accessor: "cell_phone_number"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Office Phone Number",
      accessor: "office_phone_number"
    },
    {
      Header: "Lead Status",
      accessor: "lead_status_text"
    },
    {
      Header: "List Name",
      accessor: "list_name"
    },
    {
      Header: "Last Activity"
    },
    {
      Header: "Representative",
      accessor: "crm_representative_id"
    },
    {
      Header: "Delete",
    }
  ]);

  const csv_columns = compact([
    {
      Header: "First Name*",
      accessor: "first_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.first_name}`
      )
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.last_name}`
      )
    },
    {
      Header: "Email*",
      accessor: "email"
    },
    {
      Header: "Cell*",
      accessor: "cell_phone_number",
      optional: true,
      minWidth: 150
    },
    {
      Header: "Personal Address Line 1",
      accessor: "address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal Address Line 2",
      accessor: "address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal City",
      accessor: "city",
      optional: true
    },
    {
      Header: "Personal State",
      accessor: "state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Zip Code",
      accessor: "zip_code",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Country",
      accessor: "country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Email",
      accessor: "opt_int_email",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_int_email}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Text",
      accessor: "opt_in_text",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_text}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Voice",
      accessor: "opt_in_voice",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_voice}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Contact Business Only",
      accessor: "contact_business_only",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.contact_business_only}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "List Name",
      accessor: "list_name",
      optional: true,
    },
    {
      Header: "Quality",
      accessor: "lead_quality",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadQualities.find(
          ({ value }) => value === (original?.lead_quality || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Type",
      accessor: "lead_type",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadTypes.find(
          ({ value }) => value === (original?.lead_type || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Status",
      accessor: "lead_status",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadStatuses.find(
          ({ value }) => value === (original?.lead_status || "other")
        )?.label || "Other",
      optional: true
    },
    {
      Header: "Last Sale Value",
      accessor: "sale_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Lifetime Value",
      accessor: "lifetime_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Notes",
      accessor: "notes",
      minWidth: 150,
      optional: true
    },
    {
      Header: "First Contact",
      accessor: "first_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Last Contact",
      accessor: "last_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Email",
      accessor: "office_email",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        FormatPhone(original?.office_phone_number)
    },
    {
      Header: "Business Extension",
      accessor: "office_extension",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Name",
      accessor: "business_name",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Address Line 1",
      accessor: "office_address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business Address Line 2",
      accessor: "office_address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business City",
      accessor: "office_city",
      optional: true
    },
    {
      Header: "Business Zip Code",
      accessor: "office_zip",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business State",
      accessor: "office_state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Country",
      accessor: "office_country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Unsubscribe",
      accessor: "unsubscribe",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        original?.unsubscribe && Number(original?.unsubscribe) ? "✓" : "",
      width: 100
    },
    {
      Header: "Website",
      accessor: "website",
      optional: true
    },
    {
      Header: "Industry",
      accessor: "industry",
      optional: true
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
      optional: true
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
      optional: true
    },
    {
      Header: "Established",
      accessor: "established",
      optional: true
    },
    {
      Header: "CRM Customer ID",
      accessor: "crm_customers_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.crm_customers_id)
    },
    {
      Header: "Representative ID",
      accessor: "crm_representative_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.crm_representative_id)
    },
    {
      Header: "Assigned User ID",
      accessor: "users_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.users_id)
    }
  ]);

  const inputs = [
    {
      field: "first_name",
      label: "First Name*"
    },
    {
      field: "last_name",
      label: "Last Name",
      optional: true
    },
    {
      field: "email",
      label: "Email*",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "cell_phone_number",
      label: "Cell*",
      optional: true
    },
    {
      field: "address_line_one",
      label: "Personal Address Line 1",
      optional: true
    },
    {
      field: "address_line_two",
      label: "Personal Address Line 2",
      optional: true
    },
    {
      field: "city",
      label: "Personal City",
      optional: true
    },
    {
      field: "state",
      label: "Personal State",
      optional: true
    },
    {
      field: "zip_code",
      label: "Personal Zip Code",
      optional: true
    },
    {
      field: "country",
      label: "Personal Country",
      optional: true
    },
    {
      field: "opt_int_email",
      label: "Opted in Email",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_text",
      label: "Opted in Text",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_voice",
      label: "Opted in Voice",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "contact_business_only",
      label: "Contact Business Only",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "list_name",
      label: "List Name",
      type: "select",
      options: listNames,
      defaultValue: "Water Bear Marketing",
      customOption: "Customize",
      optional: true
    },
    {
      field: "lead_quality",
      label: "Quality",
      type: "select",
      options: leadQualities,
      defaultValue: "unknown",
      optional: true
    },
    {
      field: "lead_type",
      label: "Type",
      type: "select",
      options: leadTypes,
      defaultValue: "lead",
      optional: true
    },
    {
      field: "lead_status",
      label: "Status",
      type: "select",
      options: leadStatuses,
      optionsFilter: (options, { lead_type }) =>
        options.filter(({ types }) => types.indexOf(lead_type) !== -1),
      defaultValue: "other",
      optional: true
    },
    {
      field: "sale_value",
      label: "Last Sale Value",
      optional: true
    },
    {
      field: "lifetime_value",
      label: "Lifetime Value",
      optional: true
    },
    {
      field: "notes",
      label: "Notes",
      optional: true
    },
    {
      field: "first_contacted",
      label: "First Contact",
      type: "date",
      optional: true
    },
    {
      field: "last_contacted",
      label: "Last Contact",
      type: "date",
      optional: true
    },
    {
      field: "job_title",
      label: "Job Title",
      optional: true
    },
    {
      field: "office_email",
      label: "Business Email",
      defaultValue: "",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "office_phone_number",
      label: "Business Phone",
      optional: true
    },
    {
      field: "office_extension",
      label: "Business Extension",
      optional: true
    },
    {
      field: "business_name",
      label: "Business Name",
      optional: true
    },
    {
      field: "office_address_line_one",
      label: "Business Address Line 1",
      optional: true
    },
    {
      field: "office_address_line_two",
      label: "Business Address Line 2",
      optional: true
    },
    {
      field: "office_city",
      label: "Business City",
      optional: true
    },
    {
      field: "office_zip",
      label: "Business Zip Code",
      optional: true
    },
    {
      field: "office_state",
      label: "Business State",
      optional: true
    },
    {
      field: "office_country",
      label: "Business Country",
      optional: true
    },
    {
      label: "Website",
      field: "website",
      optional: true
    },
    {
      field: "unsubscribe",
      label: "Unsubscribe",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      label: "Industry",
      field: "industry",
      optional: true
    },
    {
      label: "Revenue",
      field: "revenue",
      optional: true
    },
    {
      label: "SIC Code",
      field: "sic_code",
      optional: true
    },
    {
      label: "NAICS Code",
      field: "naics_code",
      optional: true
    },
    {
      label: "Employees",
      field: "employees",
      optional: true
    },
    {
      label: "Established",
      field: "established",
      optional: true
    },
    {
      label: "CRM Customer ID",
      field: "crm_customers_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Representative ID",
      field: "crm_representative_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Assigned User ID",
      field: "users_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Business Facebook",
      field: "Business_Facebook",
      optional: true
    },
    {
      label: "Business LinkedIn",
      field: "Business_LinkedIn",
      optional: true
    },
    {
      label: "Business Twitter",
      field: "Business_Twitter",
      optional: true
    },
    {
      label: "Business Instagram",
      field: "Business_Instagram",
      optional: true
    },
    {
      label: "Business Pinterest",
      field: "Business_Pinterest",
      optional: true
    },
    {
      label: "Business Mastodon",
      field: "Business_Mastodon",
      optional: true
    },
    {
      label: "Business Telegram",
      field: "Business_Telegram",
      optional: true
    },
    {
      label: "Business Tumblr",
      field: "Business_Tumblr",
      optional: true
    },
    {
      label: "Business Subreddit",
      field: "Business_Subreddit",
      optional: true
    },
    {
      label: "Google Business Profile",
      field: "Google_Business_Profile",
      optional: true
    },
    {
      label: "Business YouTube",
      field: "Business_YouTube",
      optional: true
    },
    {
      label: "Business Tiktok",
      field: "Business_Tiktok",
      optional: true
    },
    {
      label: "Personal Facebook",
      field: "Personal_Facebook",
      optional: true
    },
    {
      label: "Personal LinkedIn",
      field: "Personal_LinkedIn",
      optional: true
    },
    {
      label: "Personal Twitter",
      field: "Personal_Twitter",
      optional: true
    },
    {
      label: "Personal Instagram",
      field: "Personal_Instagram",
      optional: true
    },
    {
      label: "Personal Pinterest",
      field: "Personal_Pinterest",
      optional: true
    },
    {
      label: "Personal Mastodon",
      field: "Personal_Mastodon",
      optional: true
    },
    {
      label: "Personal Telegram",
      field: "Personal_Telegram",
      optional: true
    },
    {
      label: "Personal Tumblr",
      field: "Personal_Tumblr",
      optional: true
    },
    {
      label: "Personal Subreddit",
      field: "Personal_Subreddit",
      optional: true
    },
    {
      label: "Personal YouTube",
      field: "Personal_YouTube",
      optional: true
    },
    {
      label: "Personal_Tiktok",
      field: "Personal_Tiktok",
      optional: true
    }
  ];

  function Row(props) {
    const { data } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length} style={{ width: "100%" }}>
              <TableCell style={{ padding: "0px", margin: "0px"}}>
                {(CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id)?.length ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand) ||
                  CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell style={{ padding: "0px", margin: "0px"}}>
                <>
                  <a
                    href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row?.first_name}&nbsp;{row?.last_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div style={{ width: "200px" }}>{FormatPhone(row?.cell_phone_number)}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div>{row?.email}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div>{FormatPhone(row?.office_phone_number)}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div>{row?.lead_status_text}</div>
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: "nowrap", padding: "0px", margin: "0px" }}>{row?.list_name}</TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div>{CRMActivity?.filter((CurrentActivity) => CurrentActivity?.people_id === row?.id)?.map((CurrentActivity, index) => { if (index === 0) return <div>{CurrentActivity?.activity_date !== null ? moment(new Date(parseInt(CurrentActivity?.activity_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : ""}</div> })}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px"}}><div>{CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === row?.crm_representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
              </TableCell>
              <TableCell style={{ padding: "0px", margin: "0px"}}>{crm_user_level === "crm_admin" && (
                <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                    refetch();
                    CRM_Customers_refetch();
                  }
                }}>
                  <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} />
                </DeleteButton>
              )}</TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <tbody>
                      {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                              <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ width: "100px" }}>
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                        {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell><div style={{ float: "left" }}>{CurrentCustomer?.status === 1 ? "Customer" : "Lead"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a target="_blank" href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                  </TableRow>
                                </Table>
                                <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                  <Table size="medium">
                                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10, width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10, width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ width: "300px", float: "left" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRep) => CurrentRep?.id === CurrentCustomer?.representative_id).map(CurrentRep => { return <>{CurrentRep?.first_name} {CurrentRep?.last_name}</> })}</div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>)
                      })}
                      {CheckForJobs(row, index, "people_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "people_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "people_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "people_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForDocuments(row, index, "people_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "people_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "people_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "people_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "people_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "people_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                    </tbody>
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = loading || updating || deleting || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <PermIdentityOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
                disabled={isLoading}
              >
                Add People
              </Button>
              <CSVDownloader
                data={tabledata || []}
                columns={csv_columns}
                filename={`LinearCRM People (${state.customers?.selected?.name})`}
              />
              {crm_user_level === "crm_admin" && (
                <CSVReader
                  onLoaded={onCSVLoaded}
                  disabled={isLoading}
                  inputs={inputs}
                />
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={Count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setPhone={setPhone}
                  setEmail={setEmail}
                  setAddress={setAddress}
                  setlist_name={setlist_name}
                  setRepresentative={setRepresentative}
                  setOfficePhone={setOfficePhone}
                  setLeadStatus={setLeadStatus}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={tabledata} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setCustomers={setTabledata}
                    customers={tabledata}
                    source={"Linear"}
                  />
                </DialogContent>
              </Dialog>
            )}
            {csvUploadOpen && (
              <Dialog
                open={Boolean(csvUploadOpen)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Uploading People
                </DialogTitle>
                <DialogContent>
                  {uploadedCount} / {totalCount} uploaded <br />
                  {addedCount} added <br />
                </DialogContent>
              </Dialog>
            )}
            {CheckingForDuplicates && (
              <Dialog
                open={Boolean(CheckingForDuplicates)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent align={'center'}>
                  <h4>We're Importing your data and checking for Duplicates.  Please stay on this page.</h4><br /><br />
                  <CircularProgress />
                </DialogContent>
              </Dialog>
            )}
            {ShowDuplicateFile && (
              <Dialog
                open={Boolean(ShowDuplicateFile)}
              >
                <DialogTitle>
                  See Duplicate People
                  <div style={{ float: "right" }}><Button onClick={() => setShowDuplicateFile(false)}><CloseIcon /></Button></div>
                </DialogTitle>
                <DialogContent align={'center'}>
                  Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
                  <br />
                  <br />
                  Click here to overrides the duplicates.  This will overwrite<br />your current data for People and Customers
                  <br />
                  <br />
                  <CSVDownloader
                    incomingstyle={{ float: "left" }}
                    data={DuplicatePeopledata || []}
                    columns={csv_columns}
                    filename={`LinearCRM People Duplicates (${state.customers?.selected?.name})`}
                    text={"Download"}
                  />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                    setShowOverrideLoading(true);

                    await uploadOverrideCustomerEmails({
                      fetchPolicy: "no-cache",
                      variables: {
                        customer_id
                      }
                    })

                    setShowDuplicateFile(false);
                    setShowOverrideLoading(true);
                    refetch();
                    CRM_Customers_refetch();

                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}>Override</Button>
                  {ShowOverrideLoading && (
                    <>
                      <br />
                      <br />
                      Overriding...
                      <br />
                      < CircularProgress />
                    </>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer >
  );
};

export default People;
